<template>
  <i-modal
    :title="$t(productprice.discount ? 'title_discount' : 'title_extra')"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0">
      <!-- Por porcentaje -->
      <v-col cols="12" sm="6">
        <v-skeleton-loader v-if="load" type="button" tile></v-skeleton-loader>
        <v-switch
          v-show="!load"
          class="v-switch mt-0"
          color="secondary"
          key="autoprice-input"
          name="autoprice"
          :disabled="readonly"
          :label="$t('bypercent')"
          v-model="productprice.autoprice"
        ></v-switch>
      </v-col>

      <!-- Precio -->
      <v-col cols="12" sm="6">
        <i-money
          mode="text"
          :class="{
            'font-weight-black': true,
            'grey--text': true,
            title: !$vuetify.breakpoint.xsOnly
          }"
          :value="price"
          :label="$tc('price', 1)"
        />
      </v-col>

      <!-- Input: porcentaje -->
      <v-col cols="12" sm="6">
        <v-skeleton-loader v-if="load" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="percent"
          :name="$tc('percent', 1)"
          :rules="{
            required: productprice.percent !== '',
            double: 2
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-show="!load"
            v-model="productprice.percent"
            :error-messages="errors[0]"
            :label="$t('percent')"
            :disabled="!productprice.autoprice || readonly"
            key="percent-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>

      <!-- Input: Cantidad -->
      <v-col cols="12" sm="6">
        <v-skeleton-loader v-if="load" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="price"
          :name="$tc('price', 1)"
          :rules="{
            required: productprice.percent === '',
            max_value: productprice.discount
              ? Number(this.price).toFixed(2)
              : Number.MAX_VALUE
          }"
          v-slot="{ errors }"
        >
          <i-money
            v-show="!load"
            v-model="productprice.price"
            :error-messages="errors[0]"
            :label="$tc('amount', 1)"
            :disabled="productprice.autoprice || readonly"
            key="price-input"
          ></i-money>
        </ValidationProvider>
      </v-col>

      <!-- Input: Observación -->
      <v-col cols="12">
        <ValidationProvider
          vid="observation"
          :name="$tc('observation', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-textarea
            name="observation"
            :disabled="readonly"
            :error-messages="errors[0]"
            v-model="productprice.observation"
            color="secondary"
            :label="$tc('observation', 1)"
            outlined
          ></v-textarea>
        </ValidationProvider>
      </v-col>

      <!-- Precio negativo -->
      <v-col cols="12">
        <v-alert
          v-show="price <= 0 && productprice.discount"
          type="warning"
          outlined
          :value="true"
        >
          <span class="font-weight-black title">
            {{ `${$tc('pricenegative')}` }}
          </span>
        </v-alert>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    price: {
      type: [Number, String],
      default: 0,
      required: true
    },
    order: {
      required: true,
      type: [Number, String]
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      productprice: {
        subOrder: '',
        discount: false,
        observation: '',
        price: 0,
        autoprice: false,
        percent: 0
      },
      edit: false,
      load: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
        }
      }
    },
    'productprice.price': {
      handler(val) {
        if (val < 0) {
          val *= -1
          this.productprice.price *= -1
        }
        if (!this.productprice.autoprice) {
          let divisor = this.price === '0.00' ? 1 : this.price
          this.productprice.percent = ((val * 100) / divisor).toFixed(2)
        }
      }
    },
    'productprice.percent': {
      handler(val) {
        if (this.productprice.autoprice) {
          let estimated = parseFloat(this.price * val) / 100
          this.productprice.price = parseFloat(estimated).toFixed(2)
        }
      }
    }
  },
  methods: {
    getMode(item, mode) {
      this.loading = true
      if (item === null || item == undefined) {
        this.productprice = {
          subOrder: this.order,
          discount: mode,
          observation: '',
          price: 0,
          autoprice: false,
          percent: 0
        }
      } else {
        this.$api.suborder.changePrice.show({ pk: item.pk }).then((res) => {
          this.productprice = res.data
        })
      }
      this.loading = false
    },
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const changePrice = !this.edit
            ? await this.$api.suborder.changePrice.create({
                form: this.productprice
              })
            : await this.$api.suborder.changePrice.edit({
                pk: this.productprice.pk,
                form: this.productprice
              })
          this.$emit('done', changePrice.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$t(this.productprice.discount ? 'discount' : 'increase')} ${
              this.productprice.pk === undefined
                ? this.$tc('created', 1)
                : this.$tc('edited', 1)
            }`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

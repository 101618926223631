<template>
  <i-modal
    :title="titleBrand"
    :value="value"
    :field-value.sync="brand.name"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
  </i-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: () => null
    },
    brandroot: {
      type: Object,
      default: () => null
    },
    list: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      brand: {
        name: '',
        brand: null
      },
      edit: false
    }
  },
  computed: {
    titleBrand() {
      return this.$t(this.edit ? 'editing' : 'add', {
        value: this.$tc(this.brandroot ? 'model' : 'brand', 1)
      })
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.load = false
          this.edit = this.toEdit !== null
          this.brand = this.edit
            ? { ...this.toEdit }
            : { name: '', brand: null }
          if (!this.list && this.edit && this.toEdit.brand === null) {
            this.brand.name = ''
            this.edit = false
          }
        }
      }
    },
    brandroot: {
      immediate: true,
      deep: true,
      handler(val) {
        this.brand.brand = val?.pk || null
      }
    }
  },
  methods: {
    /**
     * submit
     * Método que maneja la creación o edición de una marca o modelo.
     * Si está en modo edición, se actualiza la marca existente; de lo
     * contrario, se crea una nueva marca. Se manejan los estados de carga
     * y se emiten eventos para notificar el resultado de la operación.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      this.loading = true

      try {
        const response = this.edit
          ? await this.$api.equipment.brand.edit({
              pk: this.brand.pk,
              form: this.brand
            })
          : await this.$api.equipment.brand.create({
              form: { ...this.brand, brand: this.brandroot?.pk || null }
            })

        this.$emit('done', response.data)
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('brand', 1)} ${this.$tc(
            this.edit ? 'edited' : 'created',
            1
          )}`
        )
      } catch (error) {
        console.error('Error al guardar la marca:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

export default {
  namespaced: true,
  state: {
    attr_formula: [],
    formulas: []
  },
  getters: {
    showFormulas: (state, getters) => async (formula) => {
      let index = state.formulas.findIndex((m) => m.pk === formula)
      if (index == -1) {
        let response = await axios.get(`formula/${formula}`)
        index = state.formulas.length
        state.formulas.push(response.data)
      }
      return state.formulas[index]
    },
    show_price: (state) => (value) => {
      if (value.customer != null && value.customer != '') {
        let price = value.prod.prices.find(
          (m) => m.level_id == value.customer.level.id
        )
        if (price != undefined) return price.price
      }
      return value.prod.priceSuggest
    },
    show_attrs: (state, getters) => async (attributes) => {
      let attrs = []
      for (let i = 0; i < attributes.length; i++) {
        let index = state.attr_formula.findIndex((m) => m.pk === attributes[i])
        if (index == -1) {
          let response = await axios.get(`formulattributes/${attributes[i]}`)
          index = state.attr_formula.length
          state.attr_formula.push(response.data)
        }
        attrs.push(state.attr_formula[index])
      }
      return attrs
    },
    show_formula: (state, getters) => async (operations) => {
      let formula = []
      let attrs = await getters.show_attrs(operations.attributes)
      if (typeof operations.formula == 'object') {
        for (let i = 0; i < operations.formula.length; i++) {
          let element = operations.formula[i]
          if (element.indexOf('var_') != -1) {
            element = attrs.find((o) => 'var_' + o.pk == element)
          }
          formula.push(typeof element == 'object' ? `${element.name}` : element)
        }
        return {
          formula: formula.toString().replace(/,/g, ''),
          attributes: attrs
        }
      }
      return {
        formula: '',
        attributes: []
      }
    }
  },
  mutations: {
    updateAttrs(state, value) {
      state.attr_formula[value.index] = value.attr
    }
  },
  actions: {
    async calculator({ getters }, operations) {
      let formula = []
      if (typeof operations.formula == 'number') {
        let formula = await getters.showFormulas(operations.formula)
        operations.formula = formula.formula
      }
      if (operations.formula != undefined) {
        operations.formula.forEach((element) => {
          if (element == '^') {
            element = '**'
          } else if (element.indexOf('var_') != -1) {
            let ope =
              operations.vars != undefined
                ? operations.vars.find((o) => o.name == element)
                : 0
            element = typeof ope == 'object' ? Number(ope.value) : 0
          }
          formula.push(element)
        })
      }
      let operatored = formula.toString().replace(/,/g, '')
      if (operatored !== undefined && operatored !== '') {
        try {
          let total = eval(operatored)
          if (operatored.indexOf('/0') != -1) {
            return {
              total: Infinity,
              string: 'formulaDivisionZero'
            }
          } else {
            return {
              total: total === undefined ? 0 : total,
              string: operatored
            }
          }
        } catch (error) {
          return {
            total: 0,
            string: 'formulaNoValid'
          }
        }
      }
    },
    async calculatedVars({ getters }, operations) {
      let values = {}
      if (operations.formula == null || operations.formula == '') {
        return []
      }
      if (typeof operations.formula == 'number') {
        let formula = await getters.showFormulas(operations.formula)
        operations.formula = formula.formula
      }

      if (operations.vars != undefined && operations.vars != '') {
        if (typeof operations.vars == 'string') {
          let count = 0
          operations.vars = operations.vars.split(/[-+*/()^]/g)
          operations.formula.forEach((element) => {
            if (element.indexOf('var_') != -1) {
              if (!(element in values)) {
                values[element] = isNaN(operations.vars[count])
                  ? 0
                  : operations.vars[count]
              }
              count++
            }
          })
        }
        if (typeof operations.vars == 'object') {
          operations.formula.forEach((element) => {
            if (element.indexOf('var_') != -1) {
              let inx = operations.vars.findIndex((o) => o.name == element)
              if (inx > -1) {
                values[element] = operations.vars[inx].value
              }
            }
          })
        }
      }
      let vars = Array.from(
        new Set(operations.formula.filter((o) => o.indexOf('var_') !== -1))
      )
      let varAssign = []
      for (let i = 0; i < vars.length; i++) {
        let element = vars[i]
        let label_name = await getters.show_attrs([
          Number(element.split('_')[1])
        ])
        label_name =
          label_name != undefined
            ? label_name[0]
            : {
                name: '',
                attr_type: ''
              }
        varAssign.push({
          label: `${label_name.name}`,
          name: element,
          type: label_name.attr_type,
          value:
            element in values
              ? values[element] == ''
                ? 0
                : values[element]
              : 0
        })
      }

      return varAssign
    },
    updateAttrs({ commit, state }, attribute) {
      let index = state.attr_formula.findIndex((m) => m.pk === attribute.pk)
      if (index > -1) {
        commit('updateAttrs', {
          index: index,
          attr: attribute
        })
      }
    }
  }
}

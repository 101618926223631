export default {
  namespaced: true,
  state: {
    subDomain: localStorage.getItem('subdomain'),
    subDomainID: localStorage.getItem('subdomainid'),
    subDomainSchema: localStorage.getItem('subdomainschema')
  },
  getters: {
    getSubdomainFound: (state) => !!state.subDomain,
    getSubdomainId: (state) => state.subDomainID,
    getSubdomainSchema: (state) => state.subDomainSchema
  },
  mutations: {
    consultSubdomain(state, domain) {
      state.subDomain = domain
      localStorage.setItem('subdomain', domain)
    },
    consultSubdomainID(state, domainId) {
      state.subDomainID = domainId
      localStorage.setItem('subdomainid', domainId)
    },
    consultSubdomainSchema(state, name) {
      state.subDomainName = name
      localStorage.setItem('subdomainschema', name)
    }
  },
  actions: {
    async consultSubdomain({ commit }, domain) {
      let main_url = location.hostname.split('.')
      if (main_url.length === 3) {
        main_url = `${process.env.VUE_APP_SERVER_API_TYPE}${main_url[1]}.${main_url[2]}${process.env.VUE_APP_SERVER_API_PORT}${process.env.VUE_APP_SERVER_API_ROUTE}subdomainsGlobal/`
      } else {
        if (main_url.length === 2) {
          main_url = `${process.env.VUE_APP_SERVER_API_TYPE}${main_url[0]}.${main_url[1]}${process.env.VUE_APP_SERVER_API_PORT}${process.env.VUE_APP_SERVER_API_ROUTE}subdomainsGlobal/`
        }
        if (main_url.length === 1) {
          main_url = `${process.env.VUE_APP_API_URL}subdomainsGlobal/`
        }
      }
      try {
        let response = await axios.get(main_url, {
          params: {
            domain_url: domain,
            approved: true
          }
        })
        if (response.data.results.length > 0) {
          commit('consultSubdomain', true)
          commit('consultSubdomainID', response.data.results[0].id)
          commit('company/companyData', response.data.results[0].company, {
            root: true
          })
          commit('consultSubdomainSchema', response.data.results[0].schema_name)
        } else {
          commit('consultSubdomain', false)
        }
      } catch (err) {
        commit('consultSubdomain', false)
      }
    }
  }
}

const path = 'users/'

const list = ({ opt = {} }) => axios.get(path, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}`, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const me = ({ opt = {} }) => axios.get(`${path}me/`, opt)
const changePassword = ({ form, opt = {} }) =>
  axios.put(`${path}password-change/`, form, opt)
const setPermission = ({ form, opt = {} }) =>
  axios.post('users-permission/', form, opt)
// List group
const group = ({ opt = {} }) => axios.get(`group/`, opt)
const listGroup = ({ opt = {} }) => axios.get(`users-groups/list_group/`, opt)
const listPermissions = ({ opt = {} }) =>
  axios.get(`users-permission/list_permissions/`, opt)
// Extra values
const path2 = 'user-commission/'
const commission = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}`, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'user-salary/'
const salary = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}`, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  changePassword,
  commission,
  salary,
  me,
  group,
  listGroup,
  listPermissions,
  setPermission
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"secondary"}},[_vm._l((_vm.itemsComputed),function(item,i){return [(
          item.sub == undefined ||
          (item.sub !== undefined &&
            item.sub.filter((m) => m.show).length > 0)
        )?_c('v-list-item',{key:i,attrs:{"to":item.sub == undefined || item.url !== undefined
            ? item.url
            : {
                name: 'submenu-menu',
                params: {
                  menu: item.title
                }
              }}},[_c('v-list-item-icon',{staticClass:"justify-center"},[_c('v-img',{attrs:{"lazy-src":item.icon,"max-width":"28","src":item.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$tc(item.title, item.number))}})],1)],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'

/**
 * Component for all about chats, socket comunication
 **/

export default {
  state: {
    socket: {
      isConnected: false,
      reconnectError: false,
      message: ''
    }
  },
  getters: {
    getSocketStatus: (state) => {
      return state.socket
    }
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true
    },
    socketMessage(state, message) {
      if (state.socket.isConnected) {
        Vue.prototype.$socket.sendObj(message)
      }
    }
  }
}

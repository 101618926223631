var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"offset-x":"30","offset-y":"10","color":"secondary","bottom":"","content":_vm.notifications.length == ''
          ? '0'
          : _vm.notifications.length > 99
          ? '+99'
          : _vm.notifications.length}},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.mode == 'notifications'
              ? require('@/assets/color-icons/settings/notifications.png')
              : require('@/assets/color-icons/toolbar/notification.png'),"height":"32","width":"32"},on:{"click":_vm.getEventsNotification}})],1)],1)]}}])},[(_vm.notifications.length > 0)?_c('v-list',_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:index,staticClass:"mb-2 ma-0",style:({
        'background-color': item.color + ' !important',
        'font-weight': 700,
        width: '100%',
        color: item.statusDark || item.paid ? 'white' : 'black'
      }),attrs:{"text-color":"primary"},on:{"click":function($event){return _vm.open(item)}}},[(_vm.mode === 'events')?_c('v-list',{staticClass:"py-0",style:({
          'background-color': item.color + ' !important',
          'font-weight': 700,
          width: '100%',
          color: item.statusDark || item.paid ? 'white' : 'black'
        }),attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$tc('suborder', 1))+": "),_c('span',{staticClass:"item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._v(" "+_vm._s(_vm.$t('next_sub_state'))+": "),_c('span',{staticClass:"item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"},[_vm._v(" "+_vm._s(_vm.$tc(item.statusName?.split(' - ')[0], 1))+" - "+_vm._s(_vm.$tc(item.statusName?.split(' - ')[1], 1))+" ")]),_c('span',{staticClass:"item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"},[_vm._v(" "+_vm._s(item.updated_date)+" ")])]),_c('v-list-group',{on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"mb-2 ma-0",attrs:{"text-color":"primary"}},[_c('v-list-item-title')],1)]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"slot":"appendIcon","size":"12","color":item.statusDark || item.paid ? 'white' : 'black'},slot:"appendIcon"},[_vm._v("fas fa-arrow-down ")]),_c('v-col',{style:({
              'background-color': item.color + ' !important',
              'font-weight': 700,
              width: '100%',
              color: item.statusDark || item.paid ? 'white' : 'black'
            }),attrs:{"cols":"4"}},[_vm._l((item.type_dates),function(date){return _c('v-col',{key:`date-${date.name}`},[_c('span',[_vm._v(_vm._s(date.name)+":")]),_c('span',{staticClass:"item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"},[_vm._v(" "+_vm._s(_vm.dateFormat(date.date))+" ")])])}),_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('promise_date'))+": "),_c('span',{staticClass:"item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.promise_date))+" ")])]),_c('suborder',{staticClass:"primary--text subtitle-2 front-weight-primary",attrs:{"order":item.order,"subOrder":item.subOrder,"calendar":true,"miniSubOrder":_vm.subOrder},on:{"update:subOrder":function($event){return _vm.$set(item, "subOrder", $event)},"update:sub-order":function($event){return _vm.$set(item, "subOrder", $event)},"update:miniSubOrder":function($event){_vm.subOrder=$event},"update:mini-sub-order":function($event){_vm.subOrder=$event}}})],2)],1)],1):_vm._e(),(_vm.mode === 'notifications')?_c('v-list-item-title',[(item.content_type === 'message')?_c('div',[_vm._v(" "+_vm._s(_vm.$t(item.title))+" "+_vm._s(item.details)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.content_type)+" : "+_vm._s(item.title)+" "+_vm._s(item.date)+" ")])]):_vm._e()],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('no_notifications')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
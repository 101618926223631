const path = 'orderProduct/'

const view = ({ opt = {} }) => axios.get('subOrder/', opt)
const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)

// Extra values
const path2 = 'suborderDate/'
const date = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'orderChangePrice/'
const changePrice = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`)
}

const path4 = 'orderDetails/'
const production = {
  list: ({ opt = {} }) => axios.get(path4, opt),
  create: ({ form, opt = {} }) => axios.post(path4, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path4}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path4}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path4}${pk}/`)
}

const path5 = 'imageApproval/'
const imageApproval = {
  list: ({ opt = {} }) => axios.get(path5, opt),
  create: ({ form, opt = {} }) => axios.post(path5, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path5}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path5}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path5}${pk}/`)
}

const path6 = 'imageParameters/'
const imageParameters = {
  list: ({ opt = {} }) => axios.get(path6, opt),
  create: ({ form, opt = {} }) => axios.post(path6, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path6}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path6}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path6}${pk}/`)
}

const path7 = 'imageRepository/'
let base = process.env.VUE_APP_API_URL.replace('/api/', '')
const imageRepository = {
  list: ({ opt = {} }) => axios.get(path7, opt),
  create: ({ form, opt = {} }) => axios.post(path7, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path7}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path7}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path7}${pk}/`),
  image: ({ pk, params, opt = {} }) =>
    axios.get(`${base}/sources/${path7}${pk}/${params}`, {
      ...opt,
      responseType: 'blob'
    })
}

const path8 = 'repositoryLabels/'
const labels = {
  list: ({ opt = {} }) => axios.get(path8, opt),
  create: ({ form, opt = {} }) => axios.post(path8, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path8}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path8}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path8}${pk}/`)
}

const path9 = 'assignedUser/'
const assigneduser = {
  list: ({ opt = {} }) => axios.get(path9, opt),
  create: ({ form, opt = {} }) => axios.post(path9, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path9}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path9}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path9}${pk}/`)
}

const path10 = ['orderProduct/', 'logs/']
const logs = {
  show: ({ pk, opt = {} }) => axios.get(`${path10[0]}${pk}/${path10[1]}`, opt)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  view,
  date,
  changePrice,
  production,
  imageApproval,
  imageParameters,
  imageRepository,
  labels,
  assigneduser,
  logs
}

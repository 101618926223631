const path = 'events/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)

const path2 = 'eventLabels/'
const labels = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

export default { list, create, edit, show, remove, labels }

<template>
  <div>
    <v-app-bar app color="primary" class="size-screen">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        class="text-color"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer />
      <v-btn
        text
        :title="` ${$t('create', { model: $tc('sale_order', 1) })}`"
        @click="createOrder()"
      >
        <v-img
          :src="require('@/assets/color-icons/sale/order.png')"
          height="32"
          width="32"
        ></v-img>
      </v-btn>
      <notification v-if="me != ''" />
      <userInfo v-if="me.username != ''" />
      <i-locale></i-locale>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      :permanent="$vuetify.breakpoint.mdAndUp"
      class="iptint-navigation"
    >
      <template v-slot:prepend>
        <router-link to="/">
          <v-list-item class="secondary">
            <v-list-item-avatar size="48">
              <img src="@/assets/logo1.png" alt="logo" />
            </v-list-item-avatar>

            <v-list-item-title
              class="d-flex flex-column-reverse justify-center"
            >
              <span
                class="title font-weight-black text-color"
                v-if="company !== undefined"
              >
                {{ company.name }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </router-link>
      </template>
      <menus />
      <template v-slot:append v-if="isAuth">
        <v-list-item color="secondary" @click="darkMode">
          <v-list-item-icon>
            <v-icon
              v-html="'fas fa-' + (!$vuetify.theme.dark ? 'moon' : 'sun')"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t('dark_mode') }} </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
              v-html="'fas fa-toggle-' + ($vuetify.theme.dark ? 'on' : 'off')"
            ></v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item color="secondary" @click="logout">
          <v-list-item-icon>
            <v-icon v-text="'fa-sign-in-alt'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$tc('logout', 1)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import menus from './Navigation.vue'
import userInfo from './UserInfo.vue'
import notification from './Notification.vue'

export default {
  components: {
    menus: menus,
    userInfo: userInfo,
    notification
  },
  data() {
    return {
      drawer: false,
      img: ''
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      isAuth: 'session/isAuth',
      logo: 'company/getLogo',
      company: 'company/getCompanyData'
    })
  },
  watch: {
    async logo(val) {
      if (val !== undefined && val !== null) {
        this.img = await this.showImage(val)
      } else {
        this.img = require('@/assets/logo.png')
      }
    }
  },
  methods: {
    async darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      let data = {
        profile: {
          phone: this.me.profile.phone,
          address1: this.me.profile.address1,
          city: this.me.profile.city,
          state: this.me.profile.state,
          zip_code: this.me.profile.zip_code,
          darkMode: this.$vuetify.theme.dark
        }
      }
      try {
        this.$api.user.edit({ pk: this.me.id, form: data })
        let me = await this.$api.user.me({})
        me = me.data
        this.$store.commit('session/setMe', me)
      } catch (error) {
        console.error(error)
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      }
    },
    logout() {
      const refresh = localStorage.getItem('refresh_token')
      this.$api.auth.logout({ refresh_token: refresh })
      this.$store.dispatch('session/logout')
      this.$vuetify.theme.dark = false
      this.$router.push({ name: 'auth-login' })
    },
    /**
     * createOrder
     * Método para redirigir a crear orden de venta
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    createOrder() {
      this.$router.push({
        name: 'sale-pk',
        params: { pk: this.codification({ code: 'create' }) }
      })
    }
  },
  async mounted() {
    if (this.logo !== undefined && this.logo !== null) {
      this.img = await this.showImage(this.logo)
    } else {
      this.img = require('@/assets/logo.png')
    }
  }
}
</script>

<style>
@media (min-width: 960px) {
  .size-screen {
    left: 56px !important;
  }
}
/*
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
  height: 30px !important;
  width: 10px !important;
}
*/
</style>

<template>
  <v-row class="my-0">
    <v-col
      v-for="item in suborders"
      :key="`suborder-${order}-${item.pk}`"
      class="pt-2"
      cols="12"
      @click.stop="
        item.quantity > 0 &&
        (getPermissions([`sales.view_orderproduct`]) || isAdmin)
          ? $refs.dialprod.open(item.pk)
          : ''
      "
    >
      <v-card
        class="elevation-0 suborderStatus"
        v-if="subOrder !== undefined ? item.pk === subOrder : true"
      >
        <v-row no-gutters class="py-2">
          <v-col :cols="2" :sm="3" class="pl-2" align-self="center">
            <i-image
              class="product-image"
              :style="{
                width: '5em !important',
                height: '4em !important'
              }"
              :value="item.thumbnail"
              :preview="true"
              :readonly="true"
              icon="false"
            />
            <span
              v-if="!calendar"
              :class="{
                caption: true,
                'font-weight-black': true,
                'secondary--text': true
              }"
            >
              {{ item.code }}
            </span>
            <br v-if="item.code !== ''" />
          </v-col>
          <v-col cols="1" v-if="calendar"></v-col>
          <v-col cols="3" v-if="!calendar">
            <span
              :class="{
                'body-1': true,
                'font-weight-black': true,
                'secondary--text': true
              }"
            >
              {{ item.name }}
            </span>
            <br />
            <span
              :class="{
                caption: true,
                'font-weight-black': true,
                'secondary--text': true
              }"
            >
              {{ item.own_prod }}
            </span>
            <br />
            <span
              :class="{
                caption: true,
                'font-weight-black': true,
                'secondary--text': true
              }"
            >
            </span>
          </v-col>
          <v-col
            class="d-flex pr-2"
            :cols="!calendar ? 5 : 8"
            :align="'end'"
            style=""
          >
            <div
              style="
                width: 70vw !important;
                overflow-x: auto;
                overflow-y: hidden;
                display: flex;
              "
            >
              <div
                class="mx-1"
                style="
                  width: 210px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                "
                v-for="(progress, key) in status[item.pk]"
                :key="`progress-${item.pk}-${key}`"
              >
                <div style="">
                  <v-progress-circular
                    :size="'42'"
                    :rotate="-90"
                    :value="progress.value"
                    :width="'3'"
                    :color="
                      progress.color === '#FFFFFF' || progress.color === '#FFF'
                        ? 'black'
                        : progress.color
                    "
                  >
                    {{ `${progress.value}%` }}
                  </v-progress-circular>
                </div>
                <div style="text-align: center">
                  <span
                    class="caption"
                    :style="{
                      color:
                        progress.color === '#FFFFFF' ||
                        progress.color === '#FFF'
                          ? 'black'
                          : progress.color
                    }"
                  >
                    {{ $tc(progress.name, 1) }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="1">
            <span
              :class="{
                'body-1': true,
                'font-weight-black': true,
                'secondary--text': true
              }"
            >
              {{ $t(item.leader_status['delivery']) }}
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <dialprod ref="dialprod" @prod="getSuborder($event)"></dialprod>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import dialprod from './DialogProduct.vue'
export default {
  props: {
    order: {
      type: Number
    },
    subOrder: {
      type: Number
    },
    calendar: {
      type: Boolean,
      default: false
    },
    miniSubOrder: {
      type: Object
    }
  },
  components: {
    dialprod
  },
  data() {
    return {
      suborders: [],
      status: {},
      loading: {},
      postStatus: {}
    }
  },
  watch: {
    order: {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.getSuborder()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    async getSuborder(evt) {
      this.loading = true
      try {
        const response = await this.$api.sale.product.show({
          pk: this.order,
          opt: {
            params: {
              order: true
            }
          }
        })
        this.suborders = response.data
        for (let key in this.suborders) {
          if (this.postStatus[this.suborders[key].template] === undefined) {
            const stages = await this.$api.sale.status.list({
              opt: {
                params: {
                  template: this.suborders[key].template
                }
              }
            })
            this.postStatus[this.suborders[key].template] = stages.data
          }
          let stages = this.postStatus[this.suborders[key].template]
          let statuses = []
          let positioned = false
          for (let inx in stages) {
            let value = this.suborders[key].mainStatus === 'finished' ? 100 : 0
            if (
              this.suborders[key].mainStatus !== 'finished' &&
              this.suborders[key].status !== null &&
              !positioned
            ) {
              if (stages[inx]['pk'] === this.suborders[key].status) {
                positioned = true
              } else {
                if (stages[inx]['substatusVals'].length === 0) {
                  value = 100
                } else {
                  let counter = 1
                  for (let m in stages[inx]['substatusVals']) {
                    if (
                      stages[inx]['substatusVals'][m].pk ===
                      this.suborders[key].status
                    ) {
                      positioned = true
                      break
                    }
                    counter += 1
                  }
                  value = Math.round(
                    ((counter - 2) * 100) /
                      (stages[inx]['substatusVals'].length - 2)
                  )
                }
              }
            }
            if (stages[inx]['name'] != 'preprocess') {
              statuses.push({
                pk: stages[inx]['pk'],
                name: stages[inx]['name'],
                color: stages[inx]['color'],
                value: value > 100 ? 100 : value < 0 ? 0 : value
              })
            }
          }
          this.$set(this.status, this.suborders[key].pk, statuses)
          this.$emit('update:miniSubOrder', this.suborders[key])
          this.$emit('done', this.suborders[key])
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

const login = ({ form = {}, opt = {} }) => axios.post('users/login/', form, opt)
const logout = ({ refresh_token = {} }) =>
  axios.post('users/logout/', { refresh_token })
const forgot = ({ form = {}, opt = {} }) =>
  axios.post('users/reset/password-reset/', form, opt)
const forgotConfirmation = ({ form = {}, opt = {} }) =>
  axios.post('set-password/', form, opt)
const forgotPassword = ({ form = {}, opt = {} }) =>
  axios.post('users/reset/', form, opt)

export default { login, logout, forgot, forgotConfirmation, forgotPassword }

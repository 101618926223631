const list = ({ opt = {} }) => axios.get('mailList', opt)
const send = ({ form, opt = {} }) => axios.post('send-email/', form, opt)

const path2 = 'templateConfig/'
const templateConfig = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'emails/credentials/'
const config = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`)
}

const path4 = 'emails-templates/'
const template = {
  list: ({ opt = {} }) => axios.get(path4, opt),
  create: ({ form, opt = {} }) => axios.post(path4, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path4}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path4}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path4}${pk}/`)
}

export default { list, send, template, templateConfig, config }

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :text="text"
        :small="small"
        :type="type"
        :color="color"
        :outlined="outlined"
        :loading="loading"
        :class="classes"
        :absolute="absolute"
        :right="right"
        :left="left"
        v-bind="attrs"
        v-on="on"
        @click="$emit('click', $event)"
      >
        <template v-slot:loader>
          <v-progress-circular color="white" indeterminate width="3">
            <v-icon size="20" v-text="'icon-letrai32'"></v-icon>
          </v-progress-circular>
        </template>
        <slot>
          <v-icon v-if="!noIcon">{{ icon }}</v-icon>
        </slot>
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'BtnActionTable',
  components: {},
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'text-color'
    },
    title: {
      type: [String],
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String],
      default: ''
    },
    type: {
      type: [String],
      default: 'button'
    },
    event: {
      type: [String],
      default: ''
    },
    classes: {
      type: [String, Object],
      default: ''
    },
    text: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({})
}
</script>

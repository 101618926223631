<template>
  <dialChangeStatus
    :orderId="orderId"
    ref="dialChangeStatus"
  ></dialChangeStatus>
</template>
<script>
import { mapGetters } from 'vuex'
import dialChangeStatus from '../email/changeStatus.vue'
export default {
  components: {
    dialChangeStatus
  },
  props: {
    app_label: {
      type: [String]
    },
    model: {
      type: [String]
    },
    condition: {
      type: [Boolean]
    },
    custumerNotification: {
      type: [Boolean]
    },
    stateMain: {
      type: [String]
    },
    stateID: {
      type: [Number],
      default: null
    },
    template: {
      type: [Number],
      default: null
    },
    email_notification: {
      type: Boolean,
      default: false
    },
    subject: {
      type: [String]
    },
    recipient_list: {
      type: [String]
    },
    orderId: {
      type: Number
    }
  },
  data() {
    return {
      email: {
        id: '',
        user: '',
        company_id: '',
        subject: '',
        body: '',
        recipient_list: ''
      }
    }
  },
  methods: {
    /**
     * getBodyEmail
     * Método que consulta y filtra la plantilla de correo
     * según los siguientes parámetros:
     * stateMain: nombre de estado de orden,
     * stateID: id de subOrden,
     * template: plantilla de proceso de producción
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getBodyEmail() {
      let content_type = await this.$api.role.areas.list({
        opt: {
          params: {
            app_label: this.app_label,
            model: this.model
          }
        }
      })
      let notification = await this.$api.notifications.list({
        opt: {
          params: {
            content_type: content_type.data[0].pk
          }
        }
      })
      if (
        notification.data[0] !== undefined &&
        notification.data[0].email == true &&
        this.send_email
      ) {
        await this.$api.email.template
          .show({
            pk: 0,
            opt: {
              params: {
                stateMain: this.stateMain,
                stateID: this.stateID,
                template: this.template
              }
            }
          })
          .then((response) => {
            if (response.data.length > 0 && response.data[0].body !== '')
              this.email['body'] = response.data[0].body
          })
          .catch((error) => {
            console.log(error)
          })
        let dataEmail = {
          active: true,
          stateMain: this.stateMain,
          stateID: this.stateID,
          template: this.template,
          subject: this.subject,
          recipient_list: this.recipient_list,
          body: this.email['body']
        }
        this.$swal({
          title: this.$tc('send_notification', 1),
          text: this.$tc('send_notification', 2),
          icon: 'info',
          background: 'var(--v-auxbg-base)',
          color: 'var(--v-auxtext-base)',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonColor: 'var(--v-primary-base)',
          confirmButtonText: this.$t('yes')
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              this.$store.dispatch(
                'utils/openDialogEmailNotification',
                dataEmail
              )
              this.$toast.success(
                `${this.$tc('email', 1)} ${this.$tc('send', 1)}`
              )
            } catch (error) {
              console.error(error)
            }
          }
          this.load = false
        })
      }
    }
    /*
    getPdf(down) {
      this.downloadFile({
        api: 'quotation',
        method: 'pdf',
        filename: 'Quotation',
        action: down ? 'download' : 'window',
        ext: 'pdf',
        opt: { pk: this.quote.order }
      })
    }
    */
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      company: 'company/getCompanyData'
    }),
    /**
     * send_email
     * Computado para validar el envío de correo
     * El correo no se envía los siguientes casos:
     * - Si el cliente no tiene activo recibir notificaciones
     * - Si el subEstado de subOrden no tiene activa la notificación de correo
     * - Si la orden está en proceso y root es nulo
     * - Si 'condition' es falso
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    send_email: function () {
      if (!this.custumerNotification) return false
      if (!this.condition) return false
      if (!this.email_notification) return false
      if (this.stateMain === 'in_process' && this.stateID === null) return false
      return true
    }
  }
}
</script>

<template>
  <v-card class="mt-4" v-if="permissions.view">
    <v-toolbar v-if="!dontHeader" color="secondary">
      <slot name="headerToolbar">
        <v-toolbar-title v-if="!noTitle" class="text-dark font-weight-bold">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-divider v-if="!dontCreate" class="mx-4" inset vertical></v-divider>
        <i-search v-if="!dontSearch" v-model="search" @search="research()" />
        <i-btn
          v-if="
            !dontCreate &&
            permissions.create &&
            otherPermission &&
            (total < limit || limit === undefined)
          "
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-plus"
          :title="$t('add', { value: title })"
          color="text-dark"
          @click="$emit('click:create')"
        >
        </i-btn>
        <slot name="extraToolbar" />
      </slot>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :loading="loadingInternal"
      :items="itemsInternal"
      fixed-header
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 40, 60]
      }"
      :page.sync="pageIn"
      :items-per-page.sync="perPageIn"
      v-bind="dinamicTotal"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>

      <!-- Customization actions for default -->
      <template v-slot:[`item.actions`]="{ item, value }">
        <v-menu offset-y class="mx-0">
          <template v-slot:activator="{ on }">
            <v-btn
              style="margin-top: -0.1rem"
              v-if="$vuetify.breakpoint.mdAndUp"
              color="primary"
              text
              small
              dark
              v-on="on"
              @click.stop
            >
              <v-icon style="margin-left: 5px" size="20">
                fas fa-ellipsis-v
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              style="margin-top: -0.1rem"
              color="primary"
              small
              text
              dark
              v-on="on"
              @click.stop
            >
              <v-icon size="20">fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list align="center">
            <v-list-item
              v-if="
                !dontEdit &&
                permissions.edit &&
                otherPermission &&
                (param == 'permissions' ? item.pk > 14 : true)
              "
            >
              <slot name="editBtn" :item="item" :value="value">
                <i-btn
                  :title="$t('edit')"
                  classes="mx-1"
                  outlined
                  color="info"
                  icon="mdi-pencil"
                  @click="$emit('click:edit', item)"
                />
              </slot>
            </v-list-item>
            <v-list-item
              v-if="
                !dontRemove &&
                permissions.delete &&
                (item.canDelete !== false ||
                  (param == 'permissions' ? item[param].length == 0 : false))
              "
            >
              <slot
                name="deleteBtn"
                :item="item"
                :remove="remove"
                :value="value"
              >
                <i-btn
                  :title="$tc('delete', 1)"
                  outlined
                  classes="mx-1"
                  icon="fa-trash"
                  color="error"
                  @click="remove(item.pk || item.id)"
                />
              </slot>
            </v-list-item>
            <v-list-item v-if="otherBtn">
              <slot name="otherBtn" :item="item" :value="value" />
            </v-list-item>
            <v-list-item v-if="otherBtn2">
              <slot name="otherBtn2" :item="item" :value="value" />
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Table',
  props: {
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    api: {
      type: String,
      default: ''
    },
    app: {
      type: String,
      default: ''
    },
    otherPermission: {
      type: Boolean,
      default: true
    },
    method: {
      type: String,
      default: 'list'
    },
    removeMethod: {
      type: String,
      default: 'remove'
    },
    loading: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: [Array],
      default: () => []
    },
    dontRemove: {
      type: Boolean,
      required: false
    },
    otherBtn: {
      type: Boolean,
      required: false
    },
    otherBtn2: {
      type: Boolean,
      required: false
    },
    dontEdit: {
      type: Boolean,
      required: false
    },
    dontCreate: {
      type: Boolean,
      default: false
    },
    dontSearch: {
      type: Boolean,
      default: false
    },
    dontHeader: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    headers: {
      type: [Array],
      default: () => []
    },
    opt: {
      type: [Object],
      default: () => {}
    },
    requestOpts: {
      type: [Object],
      default: () => {}
    },
    reload: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number
    },
    param: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pageIn: 1,
      perPageIn: 10,
      total: null,
      search: '',
      itemsInternal: [],
      loadingInternal: false,
      isDelete: false,
      itemSelected: null,
      confirmation: {
        title: '',
        text: '',
        color: 'red'
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      (val) => {
        if (val === this.title) {
          if (this.api !== '') {
            this.getDefaultValues()
          }
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    permissions() {
      if (typeof this.app === 'object') {
        return this.app
      }
      const app = this.app.split('.')
      return {
        create:
          this.getPermissions([`${app[0]}.add_${app[1] ? app[1] : app[0]}`]) ||
          this.isAdmin,
        view:
          this.getPermissions([`${app[0]}.view_${app[1] ? app[1] : app[0]}`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([
            `${app[0]}.change_${app[1] ? app[1] : app[0]}`
          ]) || this.isAdmin,
        delete:
          this.getPermissions([
            `${app[0]}.delete_${app[1] ? app[1] : app[0]}`
          ]) || this.isAdmin
      }
    },
    dinamicTotal() {
      if (this.api === '' || this.total == null) {
        return {}
      }
      return { serverItemsLength: this.total }
    },
    query() {
      let query = this.$api
      this.api.split('.').forEach((element) => (query = query[element]))
      return query
    }
  },
  watch: {
    reload(val) {
      if (val) {
        if (this.api !== '') {
          this.getDefaultValues()
        }
        this.$emit('update:reload', false)
      }
    },
    loading(val) {
      this.loadingInternal = val
    },
    page(val, old) {
      if (old !== val) {
        this.pageIn = val
      }
    },
    perPage(val, old) {
      if (old !== val) {
        this.perPageIn = val
      }
    },
    pageIn: {
      immediate: true,
      deeep: true,
      handler(val) {
        if (this.api !== '') {
          this.getDefaultValues()
        }
        this.$emit('update:page', val)
      }
    },
    perPageIn: {
      immediate: true,
      deeep: true,
      handler(val) {
        if (this.api !== '') {
          this.getDefaultValues()
        }
        this.$emit('update:perPage', val)
      }
    },
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.api === '') {
          this.itemsInternal = val
        }
      }
    },
    api: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== '') {
          this.getDefaultValues()
        }
      }
    },
    opt: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== null) {
          this.$emit('update:page', 1)
          if (this.api !== '') {
            this.getDefaultValues()
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    research() {
      this.pageIn = 1
      this.getDefaultValues()
    },
    async getDefaultValues() {
      try {
        this.loadingInternal = true
        const response = await this.query[this.method]({
          opt: {
            params: {
              page: this.pageIn,
              size: this.perPageIn,
              search: this.search,
              ...this.opt
            }
          },
          ...this.requestOpts
        })
        this.$set(
          this,
          'itemsInternal',
          response.data.results !== undefined
            ? response.data.results
            : response.data
        )
        this.$set(
          this,
          'total',
          response.data.results !== undefined ? response.data.count : null
        )
      } finally {
        this.loadingInternal = false
      }
    },
    remove(pk) {
      this.showConfirmation({
        show: true,
        model: this.title,
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.title,
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.query[this.removeMethod]({ pk })
      })
    }
  }
}
</script>

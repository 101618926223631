import Vue from 'vue'
// Toast for apllication
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast, {
  position: 'top-right'
})
//  Moment
import moment from 'vue-moment'
Vue.use(moment)
// Api urls importation
import { api } from '../services/index'
Vue.prototype.$api = api
// Component for encrypt ids
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)
// Dragula
import { Vue2Dragula } from 'vue2-dragula'
Vue.use(Vue2Dragula)
// Mixins importation
import './mixins'
// validations
import './validations'
// Locale component
import Locale from '@/components/globals/Locale'
Vue.component('i-locale', Locale)
// Image component
import Image from '@/components/globals/Image'
Vue.component('i-image', Image)
// Button component
import Btn from '@/components/globals/Btn'
Vue.component('i-btn', Btn)
// table
import Table from '@/components/globals/Table'
Vue.component('i-table', Table)
// Modal
import Modal from '@/components/globals/Modal.vue'
Vue.component('i-modal', Modal)
// Money
import Money from '@/components/globals/Money.vue'
Vue.component('i-money', Money)
// Toolbar
import Toolbar from '@/components/globals/Toolbar.vue'
Vue.component('i-toolbar', Toolbar)
// Card
import Card from '@/components/globals/Card.vue'
Vue.component('i-card', Card)
// Brand
import Brand from '@/components/globals/Brand.vue'
Vue.component('i-brand', Brand)
// Formula
import Formula from '@/components/globals/Formula.vue'
Vue.component('i-formula', Formula)
// Croppa
import Croppa from 'vue-croppa'
Vue.use(Croppa)
// Container
import Container from '@/components/globals/ContainerDynamic.vue'
Vue.component('i-container', Container)
// CardList
import CardList from '@/components/globals/CardList.vue'
Vue.component('i-card-list', CardList)
// CardDimensions
import CardDimensions from '@/components/globals/CardDimensions.vue'
Vue.component('i-card-dimensions', CardDimensions)
// Search
import Search from '@/components/globals/Search.vue'
Vue.component('i-search', Search)
// Datepicker
import Datepicker from '@/components/globals/Datepicker.vue'
Vue.component('i-date-picker', Datepicker)
// Matrix
import Matrix from '@/components/globals/Matrix.vue'
Vue.component('i-matrix', Matrix)
// CardImages
import CardImages from '@/components/globals/CardImages.vue'
Vue.component('i-card-images', CardImages)
// CardImage
import CardImage from '@/components/globals/CardImage.vue'
Vue.component('i-card-image', CardImage)
// RolUsers
import RolUsers from '@/components/globals/RolUsers.vue'
Vue.component('i-rol-users', RolUsers)

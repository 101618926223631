const path = 'chat/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)

// Extra values
const path2 = 'chat_message/'
const product = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`),
  markRead: ({ pk }) => axios.post(`${path2}${pk}/mark_read/`),
  unreadMessages: ({ pk }) => axios.post(`${path2}${pk}/get_unread_messages/`),
  chatDetail: ({ pk }) => axios.get(`${path2}${pk}/get_chat_details/`)
}

export default { list, create, edit, show, remove, product }

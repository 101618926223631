<template>
  <v-skeleton-loader v-if="loading" type="image" title></v-skeleton-loader>
  <div
    v-else
    class="image-print drop"
    alt="avatar"
    :id="name !== undefined ? name : 'image'"
    :class="getClasses"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
    :style="styleDinamic"
  >
    <v-btn
      v-if="!repository"
      v-show="!preview"
      class="mb-10"
      color="primary"
      dark
      fab
      icon
      small
      :disabled="readonly"
      :title="$t('pick_a_image')"
      :style="{
        position: 'absolute',
        right: 0,
        border: this.readonly ? '' : '2px solid var(--secundary)'
      }"
      @click.native="pickFile"
    >
      <v-icon>fa-camera</v-icon>
    </v-btn>
    <v-btn
      v-if="!repository"
      v-show="!preview"
      class="mt-10"
      color="primary"
      dark
      fab
      icon
      small
      :disabled="readonly"
      :title="$t('clean_image')"
      :style="{
        position: 'absolute',
        right: 0,
        border: this.readonly ? '' : '2px solid var(--secundary)'
      }"
      @click.native="resetImage"
    >
      <v-icon>fa-trash</v-icon>
    </v-btn>
    <input
      type="file"
      style="display: none"
      ref="image"
      accept="image/*"
      @change="onFilePicked"
    />
    <h3
      v-if="loadImage"
      :class="$store.getters.darkMode ? 'dark loadImage' : 'light loadImage'"
    >
      {{ $t('loading_image') }}
    </h3>
    <h3 v-if="wrongFile" :class="$store.getters.darkMode ? 'dark' : 'light'">
      {{ $t('wrong_file_type') }}
    </h3>
    <h5
      v-if="!file && !isDragging && !wrongFile && !readonly && !loadImage"
      class="user-select-none"
      :class="$store.getters.darkMode ? 'dark' : 'light'"
    >
      {{ $t('drag_drop_image') }}
    </h5>
  </div>
</template>
<script>
export default {
  name: 'imageDrop',
  props: ['value', 'file', 'readonly', 'preview', 'name', 'repository'],
  watch: {
    value: {
      immediate: true,
      async handler(val) {
        if (typeof val === 'string') {
          this.loading = true
          val = await this.showImage(val)
          this.loading = false
        }
        this.imageSource = val
      }
    }
  },
  data() {
    return {
      loadImage: '',
      isDragging: false,
      wrongFile: false,
      imageSource: null,
      loading: false,
      icon: false,
      imageDefault: require('@/assets/default_image.png')
    }
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging }
    },
    styleDinamic() {
      let styl = {
        background: `url(${
          this.file === null || this.imageSource === null
            ? this.imageDefault
            : this.imageSource
        }) center center no-repeat var(--v-auxbg-base)`
      }
      if (this.preview === undefined) {
        styl = {
          ...styl,
          border: '5px solid var(--secundary)',
          '-moz-border-radius': '9px',
          'margin-bottom': '4px',
          '-webkit-border-radius': '9px'
        }
      } else {
        styl = {
          ...styl,
          'border-width': '0px'
        }
      }
      return styl
    }
  },
  methods: {
    dragOver() {
      if (!this.readonly) {
        this.isDragging = true
      }
    },
    dragLeave() {
      if (!this.readonly) {
        this.isDragging = false
      }
    },
    drop(e) {
      this.loadImage = true
      if (!this.readonly) {
        let files = e.dataTransfer.files
        this.wrongFile = false
        // allows only 1 file
        if (files.length === 1) {
          let file = files[0]
          // allows image only
          if (file.type.indexOf('image/') >= 0) {
            var reader = new FileReader()
            reader.onload = (f) => {
              this.$emit('input', f.target.result)
              this.$emit('update:file', file)
              this.isDragging = false
              this.loadImage = false
            }
            reader.readAsDataURL(file)
          } else {
            this.wrongFile = true
            this.$emit('input', null)
            this.isDragging = false
          }
        }
      }
    },
    pickFile() {
      this.$refs.image.click()
    },
    /*
     * Method that allows you to clean the image that has been dropped.
     */
    resetImage() {
      this.$emit('input', '')
      this.$emit('update:file', '')
      this.wrongFile = false
    },
    onFilePicked(e) {
      this.loadImage = true
      const files = e.target.files
      if (files[0] !== undefined) {
        if (files[0].type.indexOf('image/') >= 0) {
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.$emit('input', fr.result)
            this.$emit('update:file', files[0]) // this is an image file that can be sent to server...
            this.wrongFile = false
            this.loadImage = false
          })
        } else {
          this.wrongFile = true
          this.$emit('input', null)
          this.isDragging = false
        }
      } else {
        this.$emit('input', null)
        this.$emit('update:file', null)
        this.wrongFile = false
      }
    }
  }
}
</script>
<style scoped>
.image-print {
  display: block;
  background-size: contain !important;
  height: 17rem !important;
  width: 100% !important;
  position: relative;
}

.drop {
  width: 100%;
  height: 100%;
  background-color: #eee;
  border: 10px solid #eee;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: background-color 0.2s ease-in-out;
  font-family: sans-serif;
}
.isDragging {
  background-color: #999 !important;
  border-color: #fff;
}

.image-print h3.light {
  background-color: white;
  padding: 8px;
  opacity: 0.9;
}

.image-print h3.dark {
  background-color: #474b46;
  padding: 8px;
  opacity: 0.8;
}

#image > button {
  margin-right: 5px;
}
#image > button:nth-child(1) {
  top: calc(50% - 45px);
}
</style>

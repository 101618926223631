<template>
  <v-skeleton-loader v-if="loading" type="card" class="mx-auto" />
  <v-card
    v-else
    @click="$emit('click')"
    :style="{
      'border-left': color
        ? '10px solid #FF5252 !important'
        : '10px solid var(--primary)',
      width: '100%'
    }"
    :elevation="elevation"
    :class="{
      'pa-2': true,
      unableProd: !active,
      listcard: true,
      'cart-list': list
    }"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <slot></slot>
        <i-btn
          v-if="!dontClose && remove"
          outlined
          :title="$tc('delete', 1)"
          icon="fa-trash"
          classes="mx-1"
          color="red"
          small
          @click.stop="$emit('remove')"
        ></i-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: true
    },
    list: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: [String, Number],
      default: 2
    },
    dontClose: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-row class="my-0 py-0">
    <!-- Loaders -->
    <v-col
      v-show="loading"
      cols="12"
      md="6"
      v-for="(field, inx) in [1, 2, 3, 4, 5, 6]"
      :key="`loading-${inx}`"
    >
      <v-skeleton-loader type="text" tile></v-skeleton-loader>
    </v-col>

    <!-- Campos -->
    <v-col
      cols="12"
      md="6"
      v-for="(field, inx) in showFormula"
      :key="`${field.name}-${inx}`"
    >
      <!-- Prop isExtraCost -->
      <div v-if="isExtraCost == true">
        <i-money
          v-if="field.type === 'Price' || field.type === 'Cost'"
          color="secondary"
          rules="min_value: 0"
          :label="field.label"
          :name="'number-' + inx"
          @input="changeFormula()"
          v-model="field.value"
        />
      </div>
      <div v-else>
        <div
          v-if="field.type === 'Price' || field.type === 'Cost'"
          color="primary"
          outlined
        >
          <i-money
            color="secondary"
            rules="min_value: 0"
            :label="field.label"
            :name="'number-' + inx"
            @input="changeFormula()"
            v-model="field.value"
          />
        </div>
      </div>

      <!-- Campo de medida -->
      <metrics
        v-if="field.type === 'Measure' && measure !== undefined"
        color="secondary"
        :default-measure="defaultMeasure"
        :dimensions="dimensions"
        :disabled="readonly"
        :inx="inx"
        :label="field.label"
        :measure="measure"
        :name="field.name"
        :readonly="readonly"
        :rounded="rounded"
        @changedimensions="$emit('changedimensions', $event)"
        @input="changeFormula()"
        v-model="field.value"
      />

      <!-- ???? -->
      <ValidationProvider
        v-if="
          field.type !== 'Price' &&
          field.type !== 'Cost' &&
          (field.type !== 'Measure' || measure === undefined)
        "
        vid="price"
        :name="$tc('price', 1)"
        rules="required|double:2|min_value:0"
        v-slot="{ errors }"
      >
        <v-text-field
          color="secondary"
          outlined
          type="tel"
          :disabled="readonly"
          :error-messages="errors[0]"
          :label="field.label"
          :name="'number-' + inx"
          :suffix="measure"
          @keyup="changeFormula()"
          v-model="field.value"
        />
      </ValidationProvider>
    </v-col>

    <!-- Subtotal -->
    <v-col
      v-if="showResult"
      class="global-formula--alert-col subtitle-2"
      cols="12"
      sm="6"
    >
      <v-alert
        color="primary"
        outlined
        style="margin-bottom: 22px"
        type="info"
        @click="showTotalDialog = !showTotalDialog"
      >
        <i-money :value="total * qty" mode="text" :label="$t('subtotal')" />
      </v-alert>
      <v-alert
        class="global-formula--alert"
        colored-border
        dense
        dismissible
        elevation="3"
        type="info"
        v-model="showTotalDialog"
      >
        <v-row
          v-for="(field, inx) in showFormula"
          :key="`${field.name}-${inx}`"
          class="my-0"
        >
          <v-col v-if="field.type === 'Price'" class="pt-0">
            <span class="font-weight-bold body-2 mb-0">
              <i-money
                mode="text"
                :label="`(${$tc('price', 0)} =`"
                :value="field.value"
              />
              ) * (
              {{ `${$tc('measure', 2)}` }} =
              {{ Number(total / field.value).toFixed(2) }}
              {{ defaultMeasure }}) * ({{ `${$tc('amount', 2)}` }} = {{ qty }})
            </span>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>

    <!-- Sandbox para probar la fórmula -->
    <v-col
      v-if="showFormulaString"
      cols="12"
      class="font-weight-black primary--text subtitle-2"
    >
      <v-alert type="success" outlined color="primary">
        <p class="mb-2">{{ `${$t('formula')}: ${totalString}` }}</p>
        <p class="mb-0">
          <i-money :value="total" mode="text" :label="`${$t('total')}:`" />
        </p>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
import americanSystem from './americanSystem.vue'

export default {
  components: {
    metrics: americanSystem
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    formula: [Number, Object, String],
    readonly: {
      type: Boolean,
      default: false
    },
    showResult: {
      type: Boolean,
      default: false
    },
    showFormulaString: {
      type: Boolean,
      default: false
    },
    price: {
      type: [Number, String]
    },
    cost: {
      type: [Number, String]
    },
    measure: String,
    defaultMeasure: String,
    dimensions: Object,
    rounded: Boolean,
    rootDimensions: {
      type: Object,
      default: () => ({})
    },
    qty: {
      type: [Number, String],
      default: 0
    },
    subOrder: {
      type: Object,
      default: () => ({})
    },
    isExtraCost: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showFormula: [],
      total: 0,
      totalString: '',
      showTotalDialog: false,
      dialogChangePrice: false
    }
  },
  methods: {
    async changeFormula() {
      let show_formula = await this.$store.dispatch('formula/calculator', {
        formula:
          typeof this.formula == 'number' ? this.formula : this.formula.formula,
        vars: this.showFormula
      })
      if (show_formula != undefined) {
        this.$emit('input', show_formula.string)
        this.$emit('change', show_formula.total.toFixed(2))
        this.totalString = show_formula.string
        this.total = show_formula.total.toFixed(2)
      }
    }
  },
  watch: {
    total: {
      handler(val) {
        this.subOrder.total = val
      }
    },
    price: {
      immediate: true,
      handler(val) {
        if (val !== undefined && val !== null && typeof val === 'number') {
          let prices = this.showFormula.forEach((m, inx) => {
            if (m.type === 'Price') {
              this.$set(this.showFormula, inx, {
                ...this.showFormula[inx],
                ...{ value: val }
              })
              this.changeFormula()
            }
          })
        }
      }
    },
    cost: {
      immediate: true,
      handler(val) {
        if (val !== undefined && val !== null && typeof val === 'number') {
          let prices = this.showFormula.forEach((m, inx) => {
            if (m.type === 'Cost') {
              this.$set(this.showFormula, inx, {
                ...this.showFormula[inx],
                ...{ value: this.cost }
              })
              this.changeFormula()
            }
          })
        }
      }
    },
    rootDimensions: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Object.keys(this.showFormula).length > 0 && val !== undefined) {
          let values = Object.keys(val)
          values.forEach((element) => {
            let inx = this.showFormula.findIndex(
              (item) => item.name === element
            )
            if (inx !== -1) {
              this.$set(this.showFormula, inx, {
                ...this.showFormula[inx],
                ...{ value: this.rootDimensions[element]['value'] }
              })
            }
          })
        }
      }
    },
    formula: {
      immediate: true,
      deep: true,
      async handler(val) {
        this.loading = true
        this.showFormula = await this.$store.dispatch(
          'formula/calculatedVars',
          {
            formula: typeof val == 'number' ? val : val.formula,
            vars: this.value
          }
        )
        let values = Object.keys(this.rootDimensions)
        let root = true
        if (values.length === 0 && this.dimensions !== undefined) {
          values = Object.keys(this.dimensions)
          root = false
        }
        if (
          this.price !== undefined ||
          this.cost !== undefined ||
          values.length > 0
        ) {
          let prices = this.showFormula.forEach((m, inx) => {
            if (values.indexOf(m.name) !== -1) {
              let valueFormula = 0
              if (root) {
                valueFormula =
                  this.rootDimensions[m.name]['defaultValue'] !== undefined
                    ? this.rootDimensions[m.name]['defaultValue']
                    : this.rootDimensions[m.name]['value']
              } else {
                valueFormula =
                  this.dimensions[m.name]['defaultValue'] !== undefined
                    ? this.dimensions[m.name]['defaultValue']
                    : this.dimensions[m.name]['value']
              }
              this.$set(this.showFormula, inx, {
                ...this.showFormula[inx],
                ...{
                  value: valueFormula
                }
              })
              values = values.filter((element) => element !== m.name)
            }
            if (m.type === 'Price') {
              this.$set(this.showFormula, inx, {
                ...this.showFormula[inx],
                ...{ value: this.price }
              })
            } else if (m.type === 'Cost') {
              this.$set(this.showFormula, inx, {
                ...this.showFormula[inx],
                ...{ value: this.cost }
              })
            }
          })
        }
        this.changeFormula()
        if (this.dimensions !== undefined) {
          let different = JSON.parse(JSON.stringify(this.dimensions))
          values.forEach((element) => {
            delete different[element]
          })
          this.$emit('changedimensions', different)
        }
        this.loading = false
      }
    }
  }
}
</script>
<style lang="sass">
.global-formula
  &--alert-col
    color: var(--v-primary-base)
    font-weight: 900
    position: relative
    font-size: 14px
  &--alert
    margin-bottom: 0
    .v-alert__wrapper
      padding-top: 10px
      padding-bottom: 13px
      .v-icon
        align-self: center
</style>

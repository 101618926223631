<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('date', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col sm="6" cols="12">
        <dialtype
          ref="dialtype"
          @done="getTypeDate"
          v-model="dialog2"
          :to-edit="toEdit2"
        />
        <v-skeleton-loader
          v-if="load"
          type="list-item-avatar"
          tile
        ></v-skeleton-loader>
        <ValidationProvider
          vid="type"
          :name="$tc('type', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            v-model="date.type"
            name="typecontact"
            autocomplete="off"
            :error-messages="errors[0]"
            :items="types"
            :loading="isLoading"
            clearable
            outlined
            class="secondary--text"
            item-text="name"
            item-value="pk"
            key="type-input"
            append-icon="fa-search"
            :label="$t('typedate')"
            :prepend-inner-icon="
              getPermissions([`sales.add_typedate`]) || isAdmin ? 'fa-plus' : ''
            "
            @click:prepend-inner="open()"
          ></v-autocomplete>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6">
        <i-date-picker
          :loading="loading"
          v-model="date.date"
          :label="$tc('date', 1)"
          color="primary"
        />
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="observation"
          :name="$tc('observation', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-textarea
            :error-messages="errors[0]"
            v-model="date.observation"
            color="secondary"
            :label="$tc('observation', 1)"
            outlined
          ></v-textarea>
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import dialtype from '../typeDate/modal.vue'

export default {
  props: {
    template: {
      type: Number
    },
    order: {
      required: true,
      type: Number
    },
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  components: {
    dialtype
  },
  data() {
    return {
      date: {
        suborder: this.order,
        type: null,
        observation: '',
        date: '',
        changeDate: false
      },
      dialog: false,
      load: false,
      menu: false,
      types: [],
      loading: false,
      isLoading: false,
      edit: false,
      valid: false,
      dialog2: false,
      reload2: false,
      toEdit2: null
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    datemain: {
      set(newValue) {
        if (this.date.date.length > newValue.length) {
          this.date.date = null
        }
      },
      get() {
        return this.dateFormat(this.date.date)
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit2 = item === undefined ? null : item
      this.dialog2 = true
    },
    addDate(evt) {
      this.types.push(evt)
      this.date.type = evt.pk
    },
    async getTypeDate() {
      this.isLoading = true
      try {
        let response = await this.$api.dashboard.typedate.show({
          pk: this.template,
          opt: {
            params: {
              subOrder: this.order
            }
          }
        })
        this.types = response.data.filter((item) => item.promise === false)
      } finally {
        this.isLoading = false
      }
    },
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const date = !this.edit
            ? await this.$api.suborder.date.create({ form: this.date })
            : await this.$api.suborder.date.edit({
                pk: this.date.pk,
                form: this.date
              })
          this.$emit('done', date.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('terms', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  },
  watch: {
    template: {
      immediate: true,
      handler(val) {
        if (typeof val === 'number') {
          this.getTypeDate()
        }
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTypeDate()
          this.edit = this.toEdit !== null
          this.date =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  suborder: this.order,
                  type: null,
                  observation: '',
                  date: '',
                  changeDate: false
                }
        }
      }
    }
  }
}
</script>

<template>
  <component
    :is="vcomponent"
    v-bind="opts"
    @submit="$emit('submit', $event)"
    @input="$emit('input', $event)"
  >
    <slot></slot>
  </component>
</template>
<script>
import { VContainer } from 'vuetify/lib'

export default {
  components: {
    VContainer
  },
  props: {
    mode: {
      type: String,
      default: 'container'
    },
    opts: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    vcomponent() {
      switch (this.mode) {
        case 'modal':
          return 'i-modal'
        default:
          return 'v-container'
      }
    }
  }
}
</script>

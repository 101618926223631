export default {
  namespaced: true,
  state: {
    currencies:
      localStorage.getItem('currencies') == undefined
        ? []
        : JSON.parse(localStorage.getItem('currencies')),
    default_currency:
      localStorage.getItem('currency') == undefined
        ? {
            style: 'currency',
            locale: 'en-US',
            currency: 'USD'
          }
        : JSON.parse(localStorage.getItem('currency')),
    companyData: localStorage.getItem('company'),
    dateFormatDefault: localStorage.getItem('dateFormat')
  },
  getters: {
    getCurrencies: (state) => (currency) => {
      return state.currencies.filter((m) => {
        if (currency == undefined || currency == '') {
          return m
        } else if (
          m.name.toUpperCase().indexOf(currency.toUpperCase()) != -1 ||
          m.code.toUpperCase().indexOf(currency.toUpperCase()) != -1
        ) {
          return m
        }
      })
    },
    getDefaultCurrency: (state) => {
      return state.default_currency
    },
    getdateFormatDefault: (state) => state.dateFormatDefault,
    getLogo: (state, getters) =>
      getters.getCompanyData ? getters.getCompanyData.company_photo : undefined,
    getCompanyData: (state) =>
      typeof state.companyData === 'string'
        ? JSON.parse(atob(state.companyData))
        : undefined,
    getColors: (state, getters) => ({
      primary:
        getters.getCompanyData === undefined
          ? '#34495e'
          : getters.getCompanyData.primary_color,
      secondary:
        getters.getCompanyData === undefined
          ? '#345d48'
          : getters.getCompanyData.secondary_color
    })
  },
  mutations: {
    setCurrencies(state, newCurrencies) {
      state.currencies = newCurrencies
      localStorage.setItem('currencies', JSON.stringify(newCurrencies))
    },
    setDefaultCurrency(state, currency) {
      state.default_currency = currency
      localStorage.setItem('currency', JSON.stringify(currency))
    },
    companyData(state, company) {
      let coded = btoa(JSON.stringify(company))
      let default_currency = {
        locale: company.country.lang,
        style: 'currency',
        currency: company.currency.code
      }
      state.companyData = coded
      state.dateFormatDefault = company.format_date
      state.default_currency = default_currency
      localStorage.setItem('company', coded)
      localStorage.setItem('dateFormat', company.format_date)
      localStorage.setItem('currency', JSON.stringify(default_currency))
    }
  },
  actions: {
    setDefaultCurrency({ commit }, currency) {
      commit('setDefaultCurrency', currency)
    },
    setCurrencies({ commit }, currencies) {
      commit('setCurrencies', currencies)
    }
  }
}

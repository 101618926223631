const path = 'invoices/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const excel = ({ pk, params, opt = {} }) =>
  axios.get(`getInvoicesExcel/${pk}/${params}`, {
    ...opt,
    responseType: 'blob'
  })

// Extra values
const path2 = 'settingsinvoice/'
const setting = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'receivables/'
const receivable = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`)
}

const path4 = 'paymentsReceivable/'
const paymentReceivable = {
  list: ({ opt = {} }) => axios.get(path4, opt),
  create: ({ pk, form, opt = {} }) => axios.post(`${path4}${pk}/`, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path4}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path4}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path4}${pk}/`)
}

const path5 = 'payments/'
const payment = {
  list: ({ opt = {} }) => axios.get(path5, opt),
  create: ({ form, opt = {} }) => axios.post(path5, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path5}${pk}/`, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path5}${pk}/`, form, opt),
  remove: ({ pk }) => axios.delete(`${path5}/${pk}/`)
}

const path6 = 'paymenttypes/'
const type = {
  list: ({ opt = {} }) => axios.get(path6, opt),
  create: ({ form, opt = {} }) => axios.post(path6, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path6}${pk}/`, form, opt),
  remove: ({ pk }) => axios.delete(`${path6}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  excel,
  setting,
  receivable,
  paymentReceivable,
  payment,
  type
}

export const en = {
  abbr: 'Abbreviation',
  approvalStatus: 'Approval status',
  account: 'Account',
  active: 'Active',
  activate: 'Activate',
  add: 'Add {value}',
  add2: 'Add to {value}',
  email_subdomain: 'Subdomain approval of {value}',
  added: 'Added',
  add_cost: 'Add cost',
  add_discount: 'add discount',
  add_extra: 'add extra',
  add_fields: 'The following fields were added: ',
  add_price: 'Add price',
  add_quantity: 'Add quantity',
  add_user_group: 'Add users to groups',
  add_user_permissions: 'Add permissions to users',
  address: 'Address',
  address2: 'Address 2',
  addressee: 'Addressee',
  additional_data: 'Additional Data',
  additional_side: 'Additional side',
  administrator: 'Company Manager',
  action: 'Actions',
  actor: 'Actor',
  all: 'All',
  amount: 'Amount|Qty',
  and: 'and',
  approve: 'Approve',
  approved: 'Approved',
  aproved_quote: 'Approve quote',
  assign: 'Assign',
  assignament_date: 'Assignment date',
  assignDesigner: 'Assign Designer',
  assignDriver: 'Assign Driver',
  assignProduction: 'Members of the production team in charge',
  assignProduction_no:
    'Upon completion of the design, you will be able to assign the production team members for this sub-Order',
  assignSubOrder: 'Assign suborders',
  assignedDesign: 'Assigned to designer',
  assignedDesigns: 'Assign designs',
  assignedProducts: 'Assigned products',
  assignDesigners: 'You must assign designers to the project',
  assignedDesigners: 'Designers assigned on project',
  assignedDrivers: 'Drivers assigned to the project',
  assignedOneDriver: 'You can only assign one driver',
  assignProductions: 'You must assign producers to the project',
  assignedProductions: 'Producers assigned to the project',
  assignedSubOrders: 'Sub-orders assigned to the driver',
  areSure: 'Are you sure {action} {model}?',
  are_sure: 'Are you sure?',
  attachments: 'Attachments',
  attach_images: 'Click here to add images manually',
  attributes: 'Attributes',
  available: 'Available',
  avalaibleDesigners: 'Designers available for project',
  audit: 'Audit',
  audit_system: 'Shredded by the system',
  back: 'Back',
  background: 'Background',
  background_color: 'Background Color',
  background_url: 'URL',
  background_image: 'Background image',
  backup_schemas: 'Back up database schemas',
  balance: 'Balance',
  base: 'Base',
  base_salary: 'Base salary',
  basic: 'Basic information',
  before: 'Before',
  billing_address: 'Billing Address',
  payment_address: 'Payment Address',
  company_address: 'Company Address',
  body: 'Body',
  boolean: 'Boolean',
  both: 'Both',
  bought_date: 'Bought Date',
  bought_date_and_rent_date: 'Bought or Rent Date',
  rent_date: 'Rent date',
  buy: 'Buy',
  bussiness_info: 'Business Info',
  blocked: 'Blocked',
  brand: 'Brand',
  bypercent: 'By percent',
  by_range: 'By Range',
  calendar: 'Calendar',
  cancel: 'Cancel',
  canceled: 'Canceled',
  cancel_specific: '{value} cancel',
  cancelorder: 'Do you want to cancel the order {order}?',
  can_be: 'Can be',
  cash: 'Cash',
  carrouselTips: 'Tips carousel',
  product_catalog: 'Product catalog',
  category: 'Category | Categories',
  cellphone: 'Cellphone',
  cc: 'Credit Card',
  change: 'Changed | Changed',
  change_status: {
    mail_delivery_notification: 'Mail delivery verification',
    shipment_preview: 'Shipment preview',
    ask: 'Do you want to send the notification by mail directly to the customer?',
    notification: 'Change of status of a suborder'
  },
  changeprice: 'Change price|Change prices',
  change_to: 'Change to',
  city: 'City',
  chat: 'Chat',
  check: 'Check',
  code: 'Code',
  click_repository: 'Click the square to send to repository',
  order_code: 'Order code',
  order_name: 'Order name',
  order_status: 'Order status',
  color: 'Color|Colors',
  color_nav: 'Color of navbar',
  color_toolbar: 'Color of toolbar',
  cols_tab_be_unique: 'Cols tabs must be unique',
  column: 'Column',
  column_compare:
    'The quantity column of the detail matrix must be the same as that of the product matrix',
  row_compare:
    'The number of columns in the detail matrix must be the same as in the product matrix',
  correction: 'Correction',
  commission: 'Commission|Commissions',
  commissionPermission:
    'The condition is the number of sales to be made, \
      the value will always be greater than or equal to {val}',
  commission_assigned: 'Commission Assigned',
  companies: 'Companies',
  company: 'Company',
  company_settings: 'Company settings',
  company_info: 'Company information',
  company_name: 'Company Name',
  connected: 'Connected | Connected',
  confirm: 'Confirm',
  confirm_delete:
    'Do you want to delete this record? This action cannot be undone',
  contact: 'Contact | Contacts',
  copyright: 'All rights reserved.',
  country: 'Country',
  cost: 'Cost | Costs',
  costalert: 'The product cost must be greater than 0',
  cost_by_dimensions: 'Cost calculated by dimensions',
  cost_prom: 'Promotion Cost',
  cost_lat: 'Cost (Latin America)',
  cost_lat_prom: 'Promotion Cost (Latin America)',
  cost_suggest: 'Total cost',
  close: 'Close',
  clone: 'Clone',
  cloned: 'cloned',
  create: 'Create {model}',
  created: 'Created | Created',
  create_email: 'Create email',
  create_matrix: 'Create Matrix',
  create_matrix_template: 'Create matrix template',
  create_value: 'Create Value',
  credit_limit: 'Credit Limit',
  condition: 'Condition',
  currency: 'Currency|Currencies',
  currency_info: 'Currency Info',
  customer_manager: 'Customer Management',
  customer: 'Customer|Customers',
  customerAddress: 'Customer Address',
  supplierAddress: 'Supplier Address',
  customerCompany: 'Customer Name or Company',
  supplierCompany: 'Supplier Name or Company',
  supplier_type: 'Supplier Type',
  dark: 'Dark color',
  dark_mode: 'Dark Mode',
  dashboard: 'Dashboard',
  decimal: 'Decimal',
  default: 'Default',
  deploy: 'Deploy',
  default_field: 'Please you select a selling formula for default!',
  date: 'Date',
  dates: 'Dates',
  dateOrder: 'Order Date',
  delivery: 'Delivery',
  delivery_date: 'Delivery Date',
  delivery_status: 'On delivery | Rejected | Delivered',
  dateDelivery: 'Date of delivery',
  dateDesign: 'Design date',
  dateDone: 'Order Completion Date',
  dateProduction: 'Production Date',
  date_from: 'From',
  date_pay: 'Payment Date',
  date_to: 'To',
  day_terms: 'Days to pay credit',
  days: 'Days',
  days_list: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday'
  },
  daily: 'Daily',
  drag_and_drop_the_image_to_this_box: 'Drag and drop the image to this box',
  delete: 'Remove|Removed',
  deleted: 'It was deleted',
  delete_matrix: 'Delete Matrix',
  delete_no:
    'This state cannot be deleted because it contains some suborders. First change the suborders to a current state',
  delete_record_question: 'You want to delete this record?',
  department: 'Production Department',
  deselect_image: 'Deselect image',
  description: 'Description',
  descriptions: 'Descriptions',
  description_lat: 'Description (Latin America)',
  design: 'Design | Designs',
  designer: 'Designer',
  designers: 'Designers',
  design_status: 'Design status',
  detail: 'Detail',
  details_production: 'Production Details',
  deliverytime: 'Delivery time',
  dimension: 'Dimensions',
  dinamic_cost: 'Dynamic cost',
  discard: 'Discard',
  discount: 'Discount',
  dontAvalaible: "Don't available",
  done: 'Done',
  download: 'Download',
  draft: 'Draft',
  driver: 'Driver | Drivers',
  edit: 'Edit',
  editing: '{value} edit',
  editable: 'Editable',
  edited: 'Edited | Edited',
  editable_active:
    'To activate this button, you need administrator credentials',
  email: 'Email | Emails',
  email_edit:
    'If you want to confirm or edit the email information, press the edit button',
  emails_list: 'Emails List',
  email_sent: 'Email sent',
  email_setting: {
    default_iprint_email_service: 'Default Iprint email service',
    default_setting: 'Default setting',
    email_SMTP_setting: 'Email SMTP settings',
    gmail: {
      google: 'Google',
      gmail: 'Gmail',
      setting_gmail: 'Settings Gmail',
      msg1: 'Prerequisite: be logged into the browser with your Google account',
      msg2: 'Enter the following link:',
      msg3: 'To generate the password, register the application: ',
      msg4: 'Please enter your account credentials (Email and password)'
    },
    host: 'Host',
    host_port: 'Host port',
    smtp_type: 'SMTP type',
    outlook: {
      outlook: 'Outlook',
      setting_outlook: 'Settings Outlook',
      msg1: 'Prerequisite: be logged into the browser with your Outlook account',
      msg2: 'Enter the following link:',
      msg3: 'Allow devices and applications to use POP settings: Yes',
      msg4: 'Please enter your account credentials (Email and password)'
    },
    use_tls: 'Use TLS',
    other: {
      setting_other: 'Setting other',
      msg1: 'Prerequisite: Have access to the SMTP configuration of the mail service of your choice',
      msg2: 'Fill all the information requested in the form in this section'
    }
  },
  empty_data: 'No data found',
  empty_message: 'You must specify message content',
  end_date: 'End date',
  equipment: 'Equipment',
  equipments: 'Equipments',
  equipments_manage: 'Equipment Management',
  equipment_maintenance: 'Equipment Maintenance',
  error_send_email: 'Error send email',
  example: 'Example',
  expense: 'Expense|Expenses',
  expenses_regular: 'Regular expenses',
  expenses_recurring: 'Recurring expenses',
  expense_validation:
    'The start date can not be greater than or equal to the final date | There are no months in that range of dates | There are no weeks in that range of dates',
  expenseCategory: 'Expense Category',
  expenseAccount: 'Expense Account',
  expiration_days: 'Expiration Days',
  expired: 'Session expired',
  extra: 'Extra',
  extra_cost: 'Extra Cost',
  extra_discount: 'Extras/Discounts',
  f2key: 'Press the f2 key to show the cost price',
  favorite: 'Favorite',
  fax: 'Fax',
  fields_changed: 'The following fields were modified: ',
  file: 'File',
  file_recomended:
    'Size: 300px High x 300px Width, Resolution: 120Dpi, File types: PNG-24 High Resolution',
  file_type: 'File type',
  filter: 'Filters',
  finalize: 'Finalize',
  finished: 'Finished',
  finishedAndRemove:
    'At the end of the order, your images will be deleted from the system',
  first_name: 'First name',
  forgot: 'Forgot your password?',
  form: 'Form',
  format: 'Format|Formats',
  format_date: 'Format date',
  formula: 'Pricing Formula',
  formula_simple: 'Formula',
  formulattributes: 'Pricing attributes',
  formulaDivisionZero: 'Invalid division by zero',
  formulaNoValid: 'Invalid formula',
  fraction: 'Fraction',
  from: 'From',
  general: 'General|General Product List',
  go_home: 'Go Home',
  globaltax: 'Tax|Taxes',
  hasCondition: 'Has condition',
  has_credit: 'Credit',
  height: 'Height',
  max_height: 'Max height',
  given_to: 'Given to',
  hide: 'Hide',
  hidden: 'Hidden',
  historical: 'Historical',
  history: '{model} history',
  history_customer: 'Customer history',
  history_supplier: 'Supplier history',
  history_product: 'History Products',
  hours: 'Hours',
  image_design_and_approval: 'Image design and approval',
  inactive: 'Inactive',
  increase: 'Increase price',
  identification: 'Identification',
  in_process: 'On process',
  in_wait: 'On wait',
  in_design: 'On design',
  in_production: 'On production',
  invalid_type_and_file_please_select_one_allowed:
    'Invalid type and file, please select one allowed.',
  invoice: 'Invoice',
  invoices: 'Invoices',
  invoiceSetting: 'Invoicing configuration',
  is_multipliable: 'Multipliable by quantity',
  ip_addr: 'IP address',
  matrix_prices: 'Matrix Prices',
  no_matrix: 'You must add a matrix to the template',
  item: 'Item',
  keep_off: 'Keep off',
  label: 'Label | Labels',
  last_name: 'Last name',
  leave: 'Leave|forgotten',
  level: 'Customer type | Customer types',
  length: 'Length',
  registerLevel: 'Only four types of customer can be registered',
  supplierlevel: 'Supplier type | Supplier types',
  levelRequired: 'Customer type is required for prices',
  lineal_meter: 'Lineal meter',
  list: 'List',
  list_schemas: 'List of stored schemas',
  login: 'Sign in',
  loginlogs: 'Login logs',
  login_failed: 'Login failed',
  logout: 'Logout',
  matrix: 'Matrix',
  matrix_table: {
    add_cols: 'Add Column',
    add_rows: 'Add Row',
    remove_cols: 'Remove Column',
    remove_rows: 'Remove Row',
    initial_range: 'Initial Range',
    final_range: 'Final Range'
  },
  matrix_loaded:
    'Matrices that do not correspond to the product matrix will not be loaded',
  main_status: 'Main Status',
  main_email: 'Main Email',
  main_matrix: 'Main Matrix',
  member: 'Members | Member',
  message: 'Message',
  image: 'Image',
  measure: 'Unit measure | Unit measure',
  measureRequired:
    'Selling formula is required, products without this value will be inactive until they are assigned.',
  minutes: 'Minutes',
  mode: 'Mode',
  model: 'Model',
  models: 'Models',
  model_product: 'Model Product',
  more_info: 'More Information',
  month: 'Month',
  month_list: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
  },
  monthly: 'Monthly',
  msg_color: 'Message color',
  msg: 'Message',
  multi_choice: 'Multi-Choice',
  must_be_numeric: 'must be numeric',
  my_profile: 'My profile',
  name: 'Name',
  product_name: 'Product Name',
  new_chat: 'New Chat',
  new_package: 'New Package',
  new_password: 'New password',
  new: 'New',
  newMessage: 'You have a new message, check the order: ',
  next: 'Next',
  next_date: 'Next date',
  next_number: 'Next number',
  next_order_status: 'New order status',
  next_sub_state: 'New substate',
  nextStatus: 'Next status {state}',
  no_changes: 'There are no changes',
  no_addi_data: 'No additional data',
  no_notifications: 'No new notifications',
  no_producers: 'There are no registered producers',
  notifications: 'Notifications',
  notifications_events: 'Notifications and events',
  number: 'Number',
  number_control: 'Control number',
  number_increment: 'Number increment',
  object_repr: 'Object representation',
  observation: 'Observation',
  of: 'of',
  old_password: 'Old password',
  one_row: 'One row',
  on_bytes: 'On Bytes',
  on_pixels: 'On Pixels',
  operator: 'Operator',
  operators: 'Operators',
  optional: '{value} (optional)',
  order: 'Order | Orders',
  ordercanceled: 'Order {order} canceled',
  orderdelayed: 'Delayed order',
  ordercreated: 'Order {order} created',
  orderend: 'Order {order} finished',
  salesorderlist: 'Sales order list',
  purchasesorderlist: 'Purchases order list',
  push: 'Push',
  orderquote: 'Order {order} in Quotation process',
  orderstatus: 'Order Status',
  orderupdate: 'Change of product status {product}, from {old} to {new}',
  other: 'Other',
  other_settings: 'Other settings',
  outsource: 'Outsource',
  overview: 'Overview',
  order_name: 'Project Name',
  purchase_order_name: 'Purchase order name ',
  package: 'Package',
  padding: 'Padding',
  page: 'Page',
  pagenotfound: 'Error 404: Page Not Found',
  partial: 'Partial Delivery',
  participant: 'Participants',
  password: 'Password | Password confirm',
  password_recovery: 'Password recovery',
  pay: 'Pay',
  paid: 'Paid',
  payment: 'Payment | Payments',
  payment_number: 'Payment Number',
  payment_ref: 'Referential payment',
  payment_type: 'Payment Type',
  paymenttype: 'Payment Method',
  percent: 'Percent',
  perpage: 'Per page',
  permissions: 'Permissions',
  permissions_select: 'Select permissions',
  permissionnotfound: 'You do not have enough permissions to enter this page',
  personal_info: 'Personal Information',
  supplier_personal_info: `Provider's Personal Information`,
  phone: 'Phone | Phones',
  phone_number: 'Phone Number',
  photo: 'Photo',
  prefix: 'Prefix',
  prev: 'Prev',
  previus_date: 'Previous date',
  price: 'Price | Prices | Price type',
  pricespecial: 'Special price',
  pricenegative: 'The price cannot be negative',
  pricepurchase: 'Purchased Price',
  pricepurchaseplus: 'Purchased price + extra cost',
  pricesale: 'Sales Prices',
  pricesuggest: 'Suggested Price',
  priceZero:
    'Prices that are 0 will be ignored and the amount of the suggested price will be assumed',
  primary_color: 'Primary color',
  print: '{value} print',
  printquality: 'Print Quality',
  prodcategory: 'Product Category',
  prodlist: 'Product List',
  prodstatus: 'Products status',
  product: 'Product | Products',
  product_frequency: 'Quantity Sold',
  product_manage: 'Product management',
  product_code: 'Product Code',
  product_sold: 'Products Sold',
  productions: 'Producer | Producers',
  preprocess: 'Pre-Process',
  preproduction: 'Pre-Production',
  production: 'Production',
  production_ready: 'Production ready',
  production_status: 'Production status',
  postproduction: 'Post-Production',
  production_process: 'Production process',
  production_process_start: 'Start production process',
  productive_process: 'Productive process',
  profile: 'Profile | profile',
  profit: 'Profit',
  profit_percent: 'Profit percent',
  promise_date: 'Promise date',
  expected_date: 'Expected date',
  purchase: 'Purchase | Purchases',
  purchase_prices: 'Purchase prices',
  quote: 'Quote',
  sale_order: 'Sale order',
  purchase_order: 'Purchase order',
  range_start: 'Initial range',
  range_end: 'End range',
  ranges: 'Ranges',
  copy: 'Copy',
  copy_ranges: 'Copy ranges | Copied ranges | Edited ranges',
  rejected: 'Rejected',
  recent_chats: 'Recent Chats',
  receivable: 'Accounts Receivable',
  billToPay: 'Bills to pay',
  received: 'Received',
  recurrent: 'Recurrence | Recurrent',
  register: 'Register',
  register_subdomain: 'Register Subdomain',
  register_user: 'Register User',
  registered: "You don't have an account?",
  registered2: 'Registered',
  reference: 'Reference',
  reports: 'Reports',
  report_type: 'Report type',
  repository: 'Repository',
  customersReport: 'Customer report',
  expensesReport: 'Expenses report',
  suppliersReport: 'Suppliers report',
  salesReport: 'Sales report',
  purchasesReport: 'Purchases report',
  invoicesReport: 'Invoices report',
  equipmentReport: 'Equipment report',
  movementsReport: 'Movements report',
  productReport: 'Product report',
  removed: 'Removed',
  rent: 'Rent',
  rent_start_date: 'Rent start date',
  required: 'Saving error, fields required in blank',
  restart: 'Restart | Restored',
  rent_end_date: 'Rent end date',
  roles: 'Role | Roles',
  roles_areas: 'Areas',
  role_select: 'Select role',
  rotate_left: 'Rotate left',
  rotate_right: 'Rotate right',
  rounded: 'Rounded | Rounded enable',
  rounded2: 'Rounded',
  row_title: 'Row title',
  row_per_page: 'Rows per page',
  cols_must_be_unique: 'Cols title must be unique',
  rows_must_be_unique: 'Rows title must be unique',
  tabs_must_be_unique: 'Tabs title must be unique',
  rush: 'Rush',
  salary: 'Salary | Salaries',
  sale: 'Sale | Sales',
  salesman: 'Salesman | Salesman',
  purchasesReceiver: 'Purchases Receiver',
  sales_percent: 'Sales percent',
  sales_prices: 'Sales prices',
  same_billing: 'Same as Billing',
  same_payments: 'Same as Payment',
  same_company_address: 'Same as Company Address',
  fill_company_address: 'Complete the company address fields first',
  schema: 'Schema',
  save: 'Save | Save',
  save_send_backup: 'The schematic was downloaded successfully',
  save_backup: 'Schema backup was saved successfully',
  save_template:
    'You must save the template name before creating a production detail',
  search: 'Search',
  second_value: 'Second Value',
  secondary_color: 'Secondary color',
  section: '{value} Section',
  section_only: 'Section',
  section_sub: 'Subsection',
  send: 'Send | Sent | Sent',
  sended_quote: 'Quote sent',
  send_purchase_order: 'Send purchase order',
  aproved_purchase_order: 'Purchase order approved',
  sended_document: 'Order sent',
  send_notification:
    'You want to send a notification to the client | You can notify the customer that your order has been changed, so that the customer can have an idea of what their order is like. Yes',
  send_quote: 'Send quote',
  sendonly: 'Send',
  send_email_list: 'Send email list',
  send_email_schema: 'An email was sent successfully indicating the route',
  see: 'See',
  see_matrix: 'See Matrix',
  see_more: 'See more...',
  select: 'Select',
  selectClone: 'Select categories',
  selectClone2: 'Select category(ies) to clone',
  select_schema: 'Select the scheme',
  selectDetail: 'Select a production detail',
  selection: '{value} selection',
  select_prod: 'No products selected',
  sequence: 'Sequence | Sequences',
  settings: 'Settings',
  settingTemplate: {
    dark_icons: 'Dark mode links',
    setting: 'Setting',
    settings_template: 'Settings of email template',
    image: 'Image | Images',
    icons: 'Social media links',
    text: 'Text',
    test_text: 'Test text',
    align: {
      align: 'Align',
      center: 'Center',
      left: 'Left',
      right: 'Right',
      justify: 'Justify'
    },
    size: {
      size: 'Size',
      small: 'Small',
      medium: 'Medium',
      large: 'Large'
    },
    text_color: 'Text color',
    font_size: 'Font size',
    font_style: {
      both: 'Both',
      font_style: 'Font style',
      italic: 'Italic',
      bold: 'Bold',
      normal: 'Normal'
    },
    header: 'Header',
    background_color: 'Background color',
    body: 'Body',
    footer: 'Footer'
  },
  serial: 'Serial',
  service: 'Service',
  services: 'Services',
  settings_for_image_upload: 'Settings for image upload',
  settingImage: 'Image setting',
  max_size: 'Max size',
  sku_code: 'SKU Code',
  sms: 'SMS',
  soldby: 'Selling formula',
  shippingAddress: 'Ship to',
  dispatchAddress: 'Dispatch address',
  shop_list: 'Shop List',
  side: 'Side',
  specific: 'Specific information',
  subdomain: 'Subdomain | Subdomains',
  subname: 'Sub-project name',
  suborderstatus: 'Sub-order Status',
  subordersubstatus: 'Suborder Substatus',
  subcategory: 'Sub-Category',
  subitem: 'Sub-Item | Sub-Items',
  subitems: 'Subitems {value}',
  subitems_not: 'Create without subitems',
  suborder: 'Sub-order | Sub-orders',
  suborder_action: {
    created: 'created',
    updated: 'updated',
    deleted: 'deleted',
    access: 'access'
  },
  subtotal: 'Subtotal',
  subject: 'Subject',
  success: '{model} {action} successfully',
  suffix: 'Suffix',
  supplier: 'Supplier|Suppliers',
  supplier_manager: 'Supplier management',
  supplier_type: 'Supplier Type',
  sustrate: 'Substrate',
  shipping_address: 'Ship to',
  state: 'State',
  status: 'Status',
  start_user_chat: 'Start chat with user',
  start_date: 'Start date',
  show: 'show',
  tax: 'Tax',
  tax_id: 'Tax Pay Number|Tax Pay Number (Optional)',
  taxes_info: 'Taxes info',
  telegram: 'Telegram',
  templates_email: 'Email Templates',
  test_formula: 'Test this formula',
  dont_templates_email:
    'This state does not have an email template, please edit email manually',
  template: 'Template',
  terms: 'Term Condition|Terms Conditions',
  text: 'Text',
  time: 'Time',
  title: 'Title',
  title_color: 'Title color',
  title_discount: 'Discount',
  title_extra: 'Extra',
  the_file_you_supply_exceeds_the_weight_limit_please_enter_a_lighter_one:
    'The file you supply exceeds the weight limit, please enter a lighter one.',
  there_are_no_images_yet: 'There are no images yet',
  to: 'To',
  total: 'Total',
  turnaround: 'Turnaround days',
  tip: 'Tip|Tips',
  type: 'Type',
  typecontact: 'Contact type|Contact types',
  typedate: 'Date Type',
  typedatealert:
    "If you don't select a category, this type of date will be general",
  write_a_message: 'Write a message',
  type_recurrent: 'Type of Recurrent',
  unitprice: 'Unit price',
  update: 'Update | Updated | updated | Updated',
  updated: 'Updated',
  upload_logo: 'Upload logo',
  upload_image: 'Upload image',
  user: 'User | Users | user | users',
  user_panel: 'User Panel',
  users_select: 'Select users',
  users_set_groups: 'Users assigned to groups',
  users_set_permissions: 'Users are assigned permissions',
  username: 'Username | Username / Email',
  value: 'Value | Values',
  value_must_be_greater: '{value} must be greater',
  what_groups: 'What are the groups to add?',
  what_permissions: 'What are the permissions to add?',
  width: 'Width',
  max_width: 'Max width',
  what_users: 'What are the users to add?',
  web_url: 'Web URL',
  welcome: 'Welcome',
  welcome_company: 'Welcome to {value}',
  week: 'Week',
  weekly: 'Weekly',
  were_sorry_but_something_went_wrong_in_the_process_please_try_again_or_contact_us:
    "We're sorry, but something went wrong in the process. Please try again or contact us",
  with_autoprice:
    'The prices by percentage when saving will be adjusted the corresponding amounts according to the cost',
  whatsapp: 'WhatsApp',
  yearly: 'Yearly',
  yes: 'Yes',
  zip_code: 'Zip Code',
  zoom_in: 'Zoom in',
  discrepancynote: 'Discrepancy Note',
  discrepancy: 'Discrepancy | Discrepancies',
  distance: 'Distance',
  incorporation: 'Incorporation',
  deincorporation: 'Deincorporation',
  inventory: 'Inventory',
  stock: 'On Hand',
  minReorder: 'Reorder Minimum | Minimum Inventory Report',
  minStock: 'Min Stock',
  created_date: 'Created date',
  creation_user: 'Creation user',
  updated_date: 'Update date',
  updated_user: 'Updated user',
  purchaseproduct: 'Purchase product',
  movement_type: 'Movement type',
  increaseStock: 'Increase Stock',
  quantity: 'Quantity',
  quantity_0: 'The quantity must be greater than zero',
  quick_sale: 'Quick sale',
  movement: 'Movement | Movements',
  alert: 'Alert',
  purchased_product: 'Purchased product',
  pdocument: 'Purchase document',
  reception: 'Reception',
  reception_date: 'Reception date',
  partialReception: 'Partial reception',
  order_quantity: 'Ordered quantity',
  pending_quantity: 'Pending quantity',
  received_quantity: 'Received quantity',
  received_purchase_order: 'Products reception',
  received_date: 'Reception date',
  received_greater: 'The amount received cannot be greater than the slope',
  special_customer: 'Special price for customers',
  accumulated_quantity: 'Accum. quantity',
  drag_drop_image: 'Drag and drop an image',
  wrong_file_type: 'Wrong file type',
  pick_a_image: 'Pick an image',
  clean_image: 'Clean image',
  loading_image: 'Loading...',
  banners: 'Banners',
  send_artwork: 'Send Artwork',
  notes_chat: 'Notes/Chat',
  dashboard_default: 'Choose default screen',
  add_item_value: 'Add Values',
  less_price: 'Price is less than cost',
  create_btn: 'Create',
  edit_btn: 'Create',
  add_product: 'Create product',
  add_product_partial:
    'The product will be added as inactive until the required data is completely filled',
  edit_product_partial:
    'The product will be inactive until the required data is completely filled',
  edit_product: 'Edit product',
  cant_active:
    'You must fill in the information for all steps of the form first in order to modify this option.',
  matrix_template: 'Matrix template',
  price_calculation: 'Price calculation',
  inventoriable: 'Inventoriable',
  concurrence: 'Customer concurrence',
  permanent: 'Permanent',
  sporadic: 'Sporadic',
  required_field: 'Required field',
  optional_field: 'Optional',
  rating: 'Rating',
  review: 'Review',
  fill_fields: 'Fill out all required fields first',
  with_subitems: 'With sub-items',
  without_price: 'Without price',
  without_subitems: 'Without sub-items'
}

<template>
  <div>
    <bell mode="events"></bell>
    <bell mode="notifications"></bell>
  </div>
</template>
<script>
import bell from './NotificationBell.vue'
export default {
  name: 'notification',
  components: {
    bell
  }
}
</script>

<template>
  <v-btn
    :color="login ? 'primary' : primaryDark ? 'white' : 'black'"
    text
    @click="changeLang"
  >
    <!-- <v-icon class="ma-0 mr-2">fas fa-globe</v-icon> -->
    <v-img
      class="mx-1"
      :lazy-src="require('@/assets/color-icons/toolbar/language.png')"
      max-width="32"
      :src="require('@/assets/color-icons/toolbar/language.png')"
    ></v-img>
    <span v-if="$i18n.locale === 'es'" dark>ES</span>
    <span v-else dark>EN</span>
  </v-btn>
</template>

<script>
import { localize } from 'vee-validate'
import { mapGetters } from 'vuex'
export default {
  props: {
    login: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      langs: [
        { value: 'en', text: 'en' },
        { value: 'es', text: 'es' }
      ],
      language: this.$i18n.locale,
      menu: false,
      primaryDark: false
    }
  },
  computed: {
    ...mapGetters({
      colors: 'company/getColors'
    })
  },
  mounted() {
    this.primaryDark = this.calculateColor(this.colors.primary)
  },
  methods: {
    changeLang: function () {
      let nextLang = this.$i18n.locale === 'es' ? 'en' : 'es'
      this.$i18n.locale = nextLang
      this.$vuetify.lang.current = nextLang
      window.axios.defaults.headers['Accept-Language'] = nextLang
      localize(nextLang)
      localStorage.setItem('locale', nextLang)
    }
  }
}
</script>

import Vue from 'vue'
import { mapGetters } from 'vuex'
var tinycolor = require('tinycolor2')
import { baseURL } from '../services/index'
// Object mixin for functions globals in the system
export let globals = {
  created() {
    if (this.$store !== undefined) {
      this.$store.subscribe(async (mutation) => {
        if (mutation.type === 'company/companyData') {
          let colors = mutation.payload
          if (colors.primary_color === undefined) {
            colors.primary_color = '#34495e'
          }
          if (colors.secondary_color === undefined) {
            colors.secondary_color = '#345d48'
          }
          const darkText = tinycolor(colors.primary_color).isDark()
          const root = document.documentElement
          //  Set new colors
          root.style.setProperty('--primary', colors.primary_color)
          root.style.setProperty('--secundary', colors.secondary_color)
          root.style.setProperty('--text-color', darkText ? '#fff' : '#000')
          this.$vuetify.theme.themes.light.primary = colors.primary_color
          this.$vuetify.theme.themes.dark.primary = tinycolor(
            colors.primary_color
          )
            .lighten(25)
            .toString()
          this.$vuetify.theme.themes.light.secondary = colors.secondary_color
          this.$vuetify.theme.themes.dark.secondary = colors.secondary_color
        }
      })
    }
  },
  data() {
    return {
      logoCompany: null
    }
  },
  computed: {
    ...mapGetters({
      dateFormatDefault: 'company/getdateFormatDefault'
    })
  },
  methods: {
    calculateColor(color) {
      return tinycolor(color).isDark()
    },
    codification({ mode = 'encode', code }) {
      switch (mode) {
        case 'decode':
          return this.$CryptoJS.AES.decrypt(
            String(code),
            'Iprintpos Graphic v2 stand@rd'
          ).toString(this.$CryptoJS.enc.Utf8)
        default:
          return this.$CryptoJS.AES.encrypt(
            String(code),
            'Iprintpos Graphic v2 stand@rd'
          ).toString()
      }
    },
    dateFormat(date) {
      if (date != null && date != '') {
        var new_date =
          date.indexOf('-') !== -1 ? date.split('-') : date.split('/')
        if (
          this.getdateFormatDefault === undefined ||
          this.getdateFormatDefault === null ||
          this.getdateFormatDefault === 'mm-dd-yyyy'
        ) {
          return `${new_date[1]}-${new_date[2]}-${new_date[0]}`
        } else if (this.getdateFormatDefault === 'dd-mm-yyyy') {
          return `${new_date[2]}-${new_date[1]}-${new_date[0]}`
        } else {
          return date
        }
      } else {
        return ''
      }
    },
    timeFormat(datetime) {
      var dt = new Date(`${datetime.date} ${datetime.time.split('.')[0]}`)
      dt.setMinutes(dt.getMinutes() - new Date().getTimezoneOffset())
      return `${dt.getHours()}:${
        dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes()
      }`
    },
    showImage(url = null) {
      if (url === null || url === '') {
        return require('@/assets/default_image.png')
      }

      let newUrl = url
      if (url.indexOf('http') == -1 && url.indexOf('/sources/') != -1) {
        newUrl = baseURL.split('/api/')[0].concat(newUrl)
      }
      if (
        url.indexOf('http') === -1 &&
        url.indexOf('/sources/') === -1 &&
        url.indexOf(';base64') === -1
      ) {
        newUrl = baseURL.split('/api/')[0].concat(`/sources/${url}`)
      }
      return newUrl
    },
    async downloadFile({
      api = '',
      method = 'pdf',
      filename = '',
      action = 'download',
      ext = 'pdf',
      opt = {}
    }) {
      let query = this.$api
      api.split('.').forEach((element) => (query = query[element]))
      try {
        let response = await query[method](opt)
        const url = window.URL.createObjectURL(response.data)
        if (action === undefined || action === 'window') {
          if (ext === 'pdf') {
            window.open(url, `${filename}.pdf`)
          } else {
            window.open(url, `${filename}`)
          }
        } else {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}.${ext}`)
          document.body.appendChild(link)
          link.click()
        }
      } catch (error) {
        return false
      }
    }
  }
}
Vue.mixin(globals)

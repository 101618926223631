export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
    me: JSON.parse(localStorage.getItem('me')) || ''
  },
  getters: {
    isAuth: (state) => !!state.token,
    me: (state) => {
      return state.me
    },
    myCompanies: (state) => {
      return state.me.companies
    },
    isAdmin: (state) =>
      state.me.is_staff === undefined ? false : state.me.is_staff,
    getPermissions: (state) => (permission) =>
      !Array.isArray(permission)
        ? false
        : state.me.get_all_permissions != undefined
        ? state.me.get_all_permissions.filter((m) => permission.includes(m))
            .length > 0
        : '',
    darkMode: (state) => {
      if (state.me.profile === undefined || state.me.profile === null)
        return false
      return state.me.profile.darkMode
    }
  },
  mutations: {
    login: (state, token, refresh) => {
      state.token = token
      state.refresh_token = refresh
    },
    logout: (state) => {
      state.token = ''
      state.refresh_token = ''
      state.me = ''
    },
    setMe(state, me) {
      localStorage.setItem('me', JSON.stringify(me))
      state.me = me
    }
  },
  actions: {
    logout: ({ commit }) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('me')
      localStorage.removeItem('company')
      delete window.axios.defaults.headers['Authorization']
    },
    login: ({ commit }, data) => {
      localStorage.setItem('token', data.token)
      localStorage.setItem('refresh_token', data.refresh)
      window.axios.defaults.headers['Authorization'] = `Bearer ${data.token}`
      commit('login', data.token, data.refresh)
    }
  }
}

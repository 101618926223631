<template>
  <v-row>
    <v-col>
      <v-row
        :class="{
          infocard: readonly,
          listcard: !readonly,
          'mx-0': true
        }"
        :style="{
          'border-left': '10px solid #254B61 !important',
          width: '100%'
        }"
        @click.stop="$emit('click')"
      >
        <v-row>
          <v-col
            cols="10"
            :class="{
              'pt-1': true,
              'pb-0': true,
              'my-0': true,
              'text-center': true,
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true,
              'subtitle-1': !$vuetify.breakpoint.xsOnly,
              title: $vuetify.breakpoint.xsOnly
            }"
          >
            {{
              `${name != undefined ? name : ''}
                  ${parseInt(name) > 0 ? $t('days') : ''}`
            }}
          </v-col>
          <v-col
            cols="2"
            align="end"
            :class="{
              'pt-1': true,
              'pb-0': true,
              'my-0': true
            }"
          >
            <i-btn
              v-if="remove"
              outlined
              :title="$tc('delete', 1)"
              icon="fa-trash"
              classes="mx-1"
              color="red"
              small
              @click.stop="$emit('remove')"
            ></i-btn>
          </v-col>
          <v-col cols="3" v-if="detail != null && remove">
            <v-switch
              disabled
              v-model="detail.hidden"
              :label="$tc('hidden')"
              name="hidden"
              color="primary"
              key="hidden-input"
            ></v-switch>
          </v-col>
          <v-col cols="4" v-if="detail != null && remove">
            <v-switch
              disabled
              v-model="detail.have_dimensions"
              :label="$tc('dimension')"
              name="have_dimensions"
              color="primary"
              key="have_dimensions-input"
            ></v-switch>
          </v-col>
          <v-col cols="5" v-if="detail != null && remove">
            <v-switch
              disabled
              v-model="detail.multi_choice"
              :label="$tc('multi_choice')"
              name="multi_choice"
              color="primary"
              key="multi_choice-input"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            :class="{
              'primary--text': true,
              'font-weight-bold': true,
              'mx-3': true
            }"
          >
            {{ detail != undefined ? detail.calc : '' }}
          </v-col>
          <v-col
            class="pt-1"
            cols="6"
            sm="6"
            v-for="(attr, keydetail) in value"
            :key="`${'dimension'}-${keydetail}`"
          >
            <div
              :class="{
                'primary--text': true,
                'font-weight-bold': true,
                'mx-3': true
              }"
            >
              {{ `${attr['name']}` }}
            </div>
            <div class="caption px-3">
              Decimal:
              <span class="font-weight-bold">
                {{ attr['value'] }} {{ attr['measure'] }}
              </span>
            </div>
            <div class="caption px-3" v-if="attr['measure'] === 'in'">
              <span>{{ $t('fraction') }}</span>
              <span class="font-weight-bold">
                {{ attr['main'] }}
                {{ attr['decimalText'] }}
                {{ attr['measure'] }}
              </span>
            </div>
            <div class="caption px-3" v-if="attr['measure'] === 'ft'">
              <span>{{ $t('fraction') }}</span
              >:
              <span class="font-weight-bold">
                {{ attr['main'] }} ft {{ attr['second'] }}
                {{ attr['decimalText'] }} in
              </span>
            </div>
            <div
              class="caption px-3"
              v-if="
                attr['rounded'] !== undefined &&
                attr['value'] !== undefined &&
                attr['value'] !== ''
              "
            >
              <span>{{ $t('rounded2') }}</span
              >:
              <span class="font-weight-bold">
                {{ attr.rounded }}
                {{
                  attr['defaultMeasure'] !== undefined
                    ? attr['defaultMeasure']
                    : attr['measure']
                }}
              </span>
            </div>
          </v-col>
        </v-row>
        <br />
        <v-col
          cols="12"
          class="my-0 py-0"
          v-if="
            (price !== '' && price !== null && price !== undefined) ||
            (qty !== '' && qty !== null && qty !== undefined)
          "
        >
          <br />
          <v-divider />
          <div
            v-if="
              (detail != null &&
                detail.priceString === '' &&
                detail.price_type === 'matrix') ||
              (detail.price_type === 'formula' &&
                detail.priceString === '' &&
                JSON.parse(JSON.stringify(detail.dimensions) === '{}'))
            "
          >
            <v-alert type="info" outlined color="error">
              <span
                :class="{
                  'primary--text': true,
                  'text-uppercase': true,
                  'font-weight-black': true
                }"
              >
                {{ $tc('edit', 2) }} {{ $tc('value', 2) }}
              </span>
            </v-alert>
          </div>
          <div
            v-if="
              (detail != null && detail.price_type === 'base') ||
              detail.priceString !== '' ||
              (detail.priceString === '' &&
                JSON.parse(JSON.stringify(detail.dimensions) !== '{}'))
            "
            class="px-4 justify-space-between d-flex"
          >
            <i-money
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true
              }"
              v-if="price !== '' && price !== null && price !== undefined"
              :value="price"
              :label="`${$tc('price', 1)}:`"
              mode="text"
            >
            </i-money>
            <span
              v-if="qty !== '' && qty !== null && qty !== undefined"
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true
              }"
            >
              {{ $tc('amount', 2) }} : {{ qty }}
            </span>
            <i-money
              v-if="
                price !== '' &&
                price !== null &&
                price !== undefined &&
                qty !== '' &&
                qty !== null &&
                qty !== undefined
              "
              :class="{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true
              }"
              :value="Number(price) * Number(qty)"
              :label="`${$tc('subtotal', 1)}:`"
              mode="text"
            >
            </i-money>
          </div>
        </v-col>
      </v-row>
      <br /><br />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: [Object, String],
    remove: Boolean,
    price: [Number, String],
    qty: [Number, String],
    name: [String],
    readonly: {
      type: Boolean,
      default: false
    },
    detail: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      val: 1,
      save: false,
      detailForm: this.detail
    }
  },
  methods: {
    async submit() {
      if (this.save) {
        this.detailForm.price = this.price
        await this.$api.suborder.production.create({
          form: this.detailForm
        })
        this.$toast.success(
          `${this.$tc('details_production', 1)} ${this.$tc(
            this.detailForm.pk !== undefined ? 'edited' : 'created',
            2
          )}`
        )
        this.$emit('reload', false)
      }
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"american-system--row my-0"},[_c('v-col',{staticClass:"py-0 pl-0 pr-2",attrs:{"cols":12}},[_c('ValidationProvider',{attrs:{"vid":"label","name":'main-' + String(_vm.inx),"rules":{
        required: true,
        double: _vm.measureConvert !== 'ft' && _vm.measureConvert !== 'in' ? 6 : 0,
        min_value: 0
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"color":"secondary","outlined":"","type":"tel","disabled":_vm.readonly,"error-messages":errors[0],"label":_vm.label,"suffix":_vm.measureConvert === 'ft'
            ? 'ft'
            : _vm.measureConvert === 'in'
            ? ''
            : _vm.measureConvert},on:{"keyup":_vm.unformat},model:{value:(_vm.main),callback:function ($$v) {_vm.main=$$v},expression:"main"}},[_c('div',{attrs:{"slot":"prepend"},slot:"prepend"},[_c('v-icon',{attrs:{"color":"info","small":""},domProps:{"textContent":_vm._s('fa-info-circle')},on:{"click":function($event){_vm.show = !_vm.show}}})],1)])]}}])})],1),_c('v-col',{staticClass:"american-system--col",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"american-system--alert",attrs:{"border":"top","colored-border":"","type":"info","dense":"","elevation":"2","dismissible":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(
          _vm.dimensions !== undefined &&
          _vm.dimensions[_vm.name] !== undefined &&
          _vm.dimensions[_vm.name]['value'] !== undefined &&
          _vm.dimensions[_vm.name]['value'] !== ''
        )?_c('span',[_vm._v(" Decimal: "+_vm._s(_vm.dimensions[_vm.name]['value'])+" "+_vm._s(_vm.measureConvert)+" ")]):_c('span',[_vm._v(" Decimal: "+_vm._s(_vm.value)+" "+_vm._s(_vm.measureConvert)+" ")]),(_vm.measureConvert === 'in')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('fraction'))+" "+_vm._s(_vm.main)+" "+_vm._s(_vm.decimalText)+" "+_vm._s(_vm.measureConvert)+" ")]):_vm._e(),(_vm.measureConvert === 'ft')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('fraction'))+": "+_vm._s(_vm.main)+" ft "+_vm._s(_vm.second)+" "+_vm._s(_vm.decimalText)+" in ")]):_vm._e(),(
          _vm.rounded !== undefined &&
          _vm.rounded &&
          _vm.dimensions !== undefined &&
          _vm.dimensions[_vm.name] !== undefined &&
          _vm.dimensions[_vm.name]['value'] !== undefined &&
          _vm.dimensions[_vm.name]['value'] !== ''
        )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('rounded2'))+": "+_vm._s(_vm.dimensions[_vm.name].rounded)+" "+_vm._s(_vm.defaultMeasure !== undefined ? _vm.defaultMeasure : _vm.measureConvert)+" ")]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import store from '@/store'
import vuetify from '@/plugins/vuetify'

const interceptorLoad = async (app) => {
  if (store.getters['company/getCurrencies']().length === 0) {
    const currency = await app.$api.currency.list({})
    store.dispatch('company/setCurrencies', currency.data)
  }

  let lang =
    localStorage.getItem('locale') != undefined
      ? localStorage.getItem('locale')
      : navigator.language.split('-')[0]

  window.axios.defaults.headers['Accept-Language'] = lang
  // add interceptor in axios for capture errors and emit notifications
  window.axios.interceptors.response.use(
    (response) => {
      if (store.getters['utils/getIsloading']) {
        store.dispatch('utils/actionLoading')
      }
      return response
    },
    (error) => {
      if (store.getters['utils/getIsloading']) {
        store.dispatch('utils/actionLoading')
      }
      // Validation for the token in all system
      if (error.response != undefined && 401 === error.response.status) {
        if (localStorage.getItem('token') != null) {
          const refresh = localStorage.getItem('refresh_token')
          app.$toast.error(app.$t('expired'))
          app.$api.auth.logout({ refresh_token: refresh })
          store.dispatch('session/logout')
          app.$router.push({ name: 'auth-login' })
        }
      } else {
        let errors = error.response.data
        var msg = ''
        // Load messages in a text for show messages
        if (typeof errors === 'string') {
          msg += `<li>${errors}</li>`
          app.$toast.error(msg)
        } else {
          for (let err in errors) {
            msg = `<p style="font-weight: 900;">${err}:</p>`
            if (typeof errors[err] === 'string') {
              msg += `<li>${errors[err]}</li>`
            } else {
              for (let msgs in errors[err]) {
                if (typeof errors[err][msgs] === 'string') {
                  msg += `<li>${errors[err][msgs]}</li>`
                } else {
                  for (let i in errors[err][msgs]) {
                    msg += `<li>${errors[err][msgs][i]}</li>`
                  }
                }
              }
            }
            app.$toast.error(msg)
          }
        }
      }
      return Promise.reject(error)
    }
  )
}

const updateColors = (getters) => {
  let colors = getters['company/getCompanyData']
  if (colors === undefined) {
    colors = {}
  }
  if (colors.primary_color === undefined) {
    colors.primary_color = '#34495e'
  }

  if (colors.secondary_color === undefined) {
    colors.secondary_color = '#345d48'
  }

  const darkMode = getters['session/darkMode']
  var tinycolor = require('tinycolor2')
  const darkText = tinycolor(colors.primary_color).isDark()
  const root = document.documentElement
  //  Set new colors
  root.style.setProperty('--primary', colors.primary_color)
  root.style.setProperty('--secundary', colors.secondary_color)
  root.style.setProperty('--text-color', darkText ? '#fff' : '#000')
  vuetify.theme.darkMode = darkMode
  vuetify.theme.themes.light.primary = colors.primary_color
  vuetify.theme.themes.dark.primary = colors.primary_color
  vuetify.theme.themes.light.secondary = colors.secondary_color
  vuetify.theme.themes.dark.secondary = colors.secondary_color
}

export { interceptorLoad, updateColors }

<template>
  <div>
    <i-money
      v-if="!loading"
      class="primary--text font-weight-bold headline"
      :value="price"
      mode="text"
    />
    <v-skeleton-loader v-else type="text" tile></v-skeleton-loader>
  </div>
</template>
<script>
export default {
  props: ['customer', 'type_customer', 'pkProduct', 'priceSuggest', 'subOrder'],
  data() {
    return {
      loading: false,
      price: 0,
      searching: false,
      found: false
    }
  },
  watch: {
    pkProduct: {
      immediate: true,
      handler() {
        if (!this.searching) {
          this.searching = true
          this.getPrice()
        }
      }
    },
    type_customer: {
      immediate: true,
      handler() {
        if (!this.searching) {
          this.searching = true
          this.getPrice()
        }
      }
    }
  },
  methods: {
    async getPrice() {
      if (
        typeof this.type_customer === 'number' &&
        typeof this.pkProduct === 'number'
      ) {
        try {
          this.loading = true
          const response = await this.$api.product.prices.list({
            opt: {
              params: {
                mode: 'all',
                product: this.pkProduct,
                detail: null
              }
            }
          })
          this.prices = response.data
          let pricesLevel = this.prices.filter(
            (item) => item.level == this.type_customer
          )
          if (pricesLevel.length > 1 && this.customer != null) {
            let specialPrice = pricesLevel.filter(
              (item) => item.customer == this.customer
            )
            if (specialPrice.length === 0) {
              this.found = pricesLevel.filter((item) => item.customer == null)
            } else {
              this.found = specialPrice
            }
          } else {
            this.found = pricesLevel
          }
          this.price =
            this.found[0].price > 0 ? this.found[0].price : this.priceSuggest
          this.$emit('change', this.price)
        } catch (error) {
          this.$emit(
            'input',
            typeof this.priceSuggest === 'number' ? this.priceSuggest : 0
          )
        } finally {
          this.loading = false
          this.searching = false
        }
      } else {
        this.$emit('input', 0)
        this.searching = false
      }
    }
  }
}
</script>

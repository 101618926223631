<template>
  <v-list dense class="py-0">
    <v-list-item-group color="secondary">
      <template v-for="(item, i) in itemsComputed">
        <v-list-item
          v-if="
            item.sub == undefined ||
            (item.sub !== undefined &&
              item.sub.filter((m) => m.show).length > 0)
          "
          :to="
            item.sub == undefined || item.url !== undefined
              ? item.url
              : {
                  name: 'submenu-menu',
                  params: {
                    menu: item.title
                  }
                }
          "
          :key="i"
        >
          <v-list-item-icon class="justify-center">
            <!-- <v-icon v-text="item.icon"></v-icon> -->
            <v-img
              :lazy-src="item.icon"
              max-width="28"
              :src="item.icon"
            ></v-img>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              v-text="$tc(item.title, item.number)"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  computed: {
    itemsComputed() {
      return this.$store.getters['navigation/getMenu'].filter(
        (o) => o.show === true
      )
    }
  }
}
</script>

<template>
  <v-toolbar dark color="secondary">
    <slot name="close"></slot>
    <v-spacer />
    <v-toolbar-title class="text-dark">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <slot name="arrowLeft"></slot>
    <slot name="arrowRight"></slot>
    <slot name="submit"></slot>
    <v-toolbar-items v-if="!dontSave">
      <i-btn
        :title="$tc('save', 1)"
        text
        :small="$vuetify.breakpoint.smAndDown"
        :loading="loading"
        type="submit"
      >
        <v-icon>fa-save</v-icon>
      </i-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
export default {
  props: {
    title: {
      type: [String],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    dontSave: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<route>
{
  "meta": {
    "permission": [
      "emails.add_maillist", "emails.change_maillist"
    ]
  }
}
</route>

<template>
  <i-container
    :opts="opts"
    :mode="container"
    @submit="container === 'container' ? send : ''"
    @input="$emit('input', $event)"
  >
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              email.id != undefined && email.id != ''
                ? `${$t('edit')} ${$tc('email', 1)}`
                : `${$t('add', { value: $tc('email', 1) })}`
            "
            :loading="load"
            dontSave
          >
            <template v-slot:submit>
              <v-divider class="mx-4" inset vertical></v-divider>
              <i-btn
                dark
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fab fa-telegram-plane"
                color="text-dark"
                :title="$tc('send', 1)"
                @click="send()"
                :loading="loading"
              >
              </i-btn>
            </template>
          </i-toolbar>
          <v-card-text>
            <v-row class="py-5">
              <v-col cols="12" lg="12">
                <ValidationProvider
                  vid="addressee"
                  :name="$tc('addressee', 1)"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="email.recipient_list"
                    :error-messages="errors[0]"
                    color="secondary"
                    :label="$t('addressee')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="12">
                <ValidationProvider
                  vid="subject"
                  :name="$tc('subject', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="email.subject"
                    :error-messages="errors[0]"
                    color="secondary"
                    :label="$t('subject')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  vid="body"
                  :name="$tc('body', 1)"
                  rules=""
                  v-slot="{ errors }"
                >
                  <label for="body">{{ $t('body') }}</label>
                  <vue-editor
                    id="body"
                    v-model="email.body"
                    :error-messages="errors[0]"
                    :editorToolbar="customToolbar"
                  >
                  </vue-editor>
                  <span
                    class="areaErrors"
                    v-if="errors[0] != '' && errors[0] != null"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </i-container>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'

export default {
  components: {
    VueEditor
  },
  props: {
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    },
    dataEmail: {
      type: [Object],
      default: () => {}
    },
    orderId: {
      type: Number
    }
  },
  data() {
    return {
      dialog: false,
      load: false,
      loading: false,
      email: {
        id: '',
        user: '',
        company_id: '',
        subject: '',
        body: '',
        recipient_list: ''
      },
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        // [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      company: 'company/getCompanyData'
    }),
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: '',
            value: this.value,
            load: this.loading,
            maxWidth: '80%',
            dontSave: true
          }
        default:
          return { fluid: true }
      }
    }
  },
  methods: {
    setOpen() {
      this.clear()
      this.dialog = true
      this.email.user = this.me.id
      this.email.company_id = this.company.pk
    },
    getEdit(obj) {
      this.dialog = true
      this.email = JSON.parse(JSON.stringify(obj))
    },
    async send(emit) {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true
          if (this.email.id == '') {
            this.$api.email
              .send({
                form: this.email,
                opt: {
                  params: {
                    template: false,
                    orderId: this.orderId
                  }
                }
              })
              .then((response) => {
                if (response.status == 422) {
                  this.$toast.error(response.data.message)
                  console.log(response.status)
                } else {
                  this.$toast.success(`${this.$tc('email_sent', 1)}`)
                  this.$router.push({ name: 'sendEmail' })
                  this.$emit('createEmail', response.data)
                  this.$emit('clearStoreEmail')
                  this.$emit('done')
                  var audio = new Audio(
                    require('@/../public/sounds/sound2.wav')
                  )
                  audio.play()
                }
              })
              .catch((err) => {
                this.$toast.error(`${this.$t('error_send_email')}`)
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    getEmails() {
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (this.$store.getters['utils/getIsloading']) {
        this.$store.dispatch('utils/actionLoading')
        this.$route.meta.title = this.$t('create', { model: '' })
      }
      this.email = {
        id: '',
        user: this.me.id,
        company_id: this.company.pk,
        subject: this.dataEmail != undefined ? this.dataEmail.subject : '',
        body: this.dataEmail != undefined ? this.dataEmail.body : '',
        recipient_list:
          this.dataEmail != undefined ? this.dataEmail.recipient_list : ''
      }
    }
  },
  mounted() {
    this.getEmails()
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}}):_c('v-card',{class:{
    'pa-2': true,
    unableProd: !_vm.active,
    listcard: true,
    'cart-list': _vm.list
  },style:({
    'border-left': _vm.color
      ? '10px solid #FF5252 !important'
      : '10px solid var(--primary)',
    width: '100%'
  }),attrs:{"elevation":_vm.elevation},on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._t("default"),(!_vm.dontClose && _vm.remove)?_c('i-btn',{attrs:{"outlined":"","title":_vm.$tc('delete', 1),"icon":"fa-trash","classes":"mx-1","color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  namespaced: true,
  state: {
    show_confirmation: false,
    confirmation_title: '',
    confirmation_model: '',
    confirmation_type: '',
    confirmation_content: '',
    // Confirmation acceptance
    confirmation_acceptance: {},
    confirmation_loading: false,
    confirmation_finalized: false,
    confirmation_finishedText: ''
  },
  getters: {
    getConfirmationAcceptance: (state) => state.confirmation_acceptance,
    getConfirmationDisplay: (state) => state.show_confirmation,
    getConfirmationTitle: (state) => state.confirmation_title,
    getConfirmationModel: (state) => state.confirmation_model,
    getConfirmationContent: (state) => state.confirmation_content,
    getConfirmationType: (state) => state.confirmation_type,
    getConfirmationLoading: (state) => state.confirmation_loading,
    getConfirmationText: (state) => state.confirmation_finishedText,
    getConfirmationFinished: (state) => state.confirmation_finalized
  },
  mutations: {
    confirmationValue(state, value) {
      state.show_confirmation = value.show === undefined ? false : value.show
      if (typeof value === 'boolean') {
        setTimeout(() => {
          // Delays the new snakbar a few milliseconds
          state.confirmation_title = ''
          state.confirmation_model = ''
          state.confirmation_type = ''
          state.confirmation_content = ''
          state.confirmation_acceptance = {}
          state.confirmation_loading = false
          state.confirmation_finishedText = ''
        }, 400)
      } else {
        state.confirmation_title = value.title
        state.confirmation_model = value.model
        state.confirmation_type = value.type
        state.confirmation_content = value.content
        state.confirmation_acceptance = value.confirm
        state.confirmation_finishedText = value.alert
      }
    },
    confirmationLoading(state, value) {
      state.confirmation_loading = value
    },
    confirmationFinished(state, value) {
      state.confirmation_finalized = value
    }
  },
  actions: {
    confirmationFinished({ commit }, value) {
      commit('confirmationFinished', value)
    },
    confirmationLoading({ commit }, value) {
      commit('confirmationLoading', value)
    },
    confirmationValue({ commit }, value) {
      commit('confirmationLoading', false)
      commit('confirmationValue', value)
    }
  }
}

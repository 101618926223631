<template>
  <v-skeleton-loader v-if="loading" type="text" tile />
  <v-menu
    v-else
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <ValidationProvider
        vid="value"
        :name="$tc('date', 1)"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          autocomplete="off"
          outlined
          :color="color"
          v-model="datemain"
          :label="label"
          :disabled="disabled"
          prepend-icon="fa-calendar"
          v-on="on"
          :error-messages="errors[0]"
        ></v-text-field>
      </ValidationProvider>
    </template>
    <v-date-picker
      :value="value"
      @input="$emit('input', $event), (menu = false)"
      :color="color"
      :max="max"
      :min="min"
      no-title
      scrollable
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    max: {
      type: String,
      default: undefined
    },
    min: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menu: false
    }
  },
  watch: {
    date: {
      handler(val) {
        if (
          this.value !== null &&
          this.value !== null &&
          new Date(val) > new Date(this.value)
        ) {
          this.$emit('input', null)
        }
      }
    }
  },
  computed: {
    datemain: {
      set(newValue) {
        if (this.value.length > newValue.length) {
          this.value = null
        }
      },
      get() {
        return this.dateFormat(this.value)
      }
    }
  }
}
</script>

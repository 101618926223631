import Vue from 'vue'
import Vuex from 'vuex'
import chat from './chat'
import confirmation from './confirmation'
import company from './company'
import formula from './formula'
import navigation from './navigation'
import notifications from './notifications'
import sales from './sales'
import session from './session'
import subdomain from './subdomain'
import utils from './utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    chat,
    confirmation,
    company,
    formula,
    navigation,
    notifications,
    sales,
    session,
    subdomain,
    utils
  }
})

<template>
  <div>
    <!-- Modal para crear/editar extras/descuentos -->
    <dialprodprice
      ref="dialprodprice"
      :order="order"
      :price="price"
      :readonly="readonly"
      :to-edit="toEdit"
      @done="getOrderChangeProduct()"
      v-model="dialog"
    />

    <!-- Datos de extras/descuentos -->
    <v-row class="my-0">
      <v-col cols="12" class="pt-0">
        <p class="mb-0" @click="dialogChangePrice = !dialogChangePrice">
          debug: listar
        </p>
      </v-col>
      <v-col cols="12" class="pb-2">
        <div class="change-price--container-buttons">
          <!-- Extra -->
          <i-btn
            v-if="!readonly && changePrice.add"
            classes="w-100"
            color="success"
            :title="$t('add_extra')"
            @click="open(undefined, false)"
          >
            <v-icon small>fas fa-long-arrow-alt-up</v-icon>
            <span class="ml-1">{{ $t('title_extra') }}</span>
          </i-btn>

          <!-- Descuento -->
          <i-btn
            v-if="!readonly && changePrice.add"
            classes="w-100"
            color="error"
            :title="$t('add_discount')"
            @click="open(undefined, true)"
          >
            <v-icon small>fas fa-long-arrow-alt-down</v-icon>
            <span class="ml-1">{{ $t('title_discount') }}</span>
          </i-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 d-none">
        <span
          :class="{
            title: !$vuetify.breakpoint.xsOnly,
            'subtitle-1': $vuetify.breakpoint.xsOnly,
            'font-weight-black': true,
            'grey--text': true
          }"
        >
          {{ $tc('changeprice', 2) }}
        </span>
      </v-col>
    </v-row>

    <!-- Modal de consulta extras/descuentos -->
    <i-modal
      closed
      dontClose
      persistent
      :max-width="'1200px'"
      @submit="getOrderChangeProduct()"
      v-model="dialogChangePrice"
    >
      <v-col cols="12">
        <i-card-list
          api="suborder.changePrice"
          app="sales.orderchangeprice"
          dontCreate
          dontHeader
          :color="'discount'"
          :method="'show'"
          :opt="{ order: true }"
          :readonly="readonly"
          :reload.sync="reload"
          :requestOpts="{ pk: order }"
          :title="$tc('extra_discount')"
          @click:edit="open($event)"
          @wasRemove="wasRemove = $event"
        >
          <template v-slot:[`item`]="{ item }">
            <h3 class="font-weight-black primary--text">
              {{ item.discount ? $t('title_discount') : $t('title_extra') }}
            </h3>
            <p class="title font-weight-black primary--text mb-2">
              {{ `$${item.price}` }}
            </p>
            <p class="grey--text font-weight-black mb-2">
              {{ `${$t('percent')}: ` }}
              <span class="primary--text">
                {{ `${item.percent}%` }}
              </span>
            </p>
            <v-list v-show="item.observation !== ''" class="py-0" dense>
              <v-list-group @click.stop="">
                <v-icon slot="appendIcon" size="12">fas fa-arrow-down</v-icon>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="primary--text subtitle-2 font-weight-black">
                        {{ $tc('observation', 2) }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <span class="primary--text">
                  {{ item.observation }}
                </span>
              </v-list-group>
            </v-list>
          </template>
        </i-card-list>
      </v-col>
    </i-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialprodprice from './dialChangePrice.vue'

export default {
  components: {
    dialprodprice
  },
  props: {
    order: {
      required: true,
      type: [Number, String]
    },
    subtotal: {
      type: [Number, String],
      required: false
    },
    readonly: {
      default: false,
      type: Boolean
    },
    value: [Number, String],
    price: {
      type: [Number, String]
    }
  },
  data() {
    return {
      prices: [],
      total: 0,
      loading: false,
      dialog: false,
      toEdit: null,
      reload: false,
      discount: 0,
      sum: 0,
      dialogChangePrice: false,
      wasRemove: false
    }
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        this.getOrderChangeProduct()
      }
    },
    wasRemove: {
      handler(d) {
        if (d === true) {
          this.getOrderChangeProduct()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    changePrice() {
      return {
        add: this.getPermissions([`sales.add_orderchangeprice`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item, mode) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
      this.$refs.dialprodprice.getMode(item, mode)
    },
    async getOrderChangeProduct() {
      this.loading = true
      this.discount = 0
      this.sum = 0
      try {
        let response = await this.$api.suborder.changePrice.show({
          pk: this.order,
          opt: {
            params: {
              order: true
            }
          }
        })
        this.prices = response.data
        this.discount = this.prices
          .filter((element) => element.discount)
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(-1 * currentValue.price),
            0
          )
          .toFixed(2)
        this.sum = this.prices
          .filter((element) => !element.discount)
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.price),
            0
          )
          .toFixed(2)
      } finally {
        this.$emit('changeTotal', this.total)
        this.$emit('input', this.discount)
        this.loading = false
        this.wasRemove = false
      }
    }
  }
}
</script>
<style lang="sass">
.change-price
  &--container-buttons
    align-items: flex-start
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 10px
    justify-content: space-between
    .v-btn:not(.v-btn--round).v-size--small
      height: auto
      padding: 5px 24px
    @media (width > 600px)
      margin-bottom: 0
      align-items: center
      .v-btn:not(.v-btn--round).v-size--small
        height: 28px
        padding: 0 12px
  &--button
    width: 47%
</style>

const path = 'purchaseorders/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const counter = ({ opt = {} }) => axios.get('purchaseOrderCounter/', opt)
const excel = ({ pk, opt = {} }) =>
  axios.get(`getPurchaseOrdersExcel/${pk}`, { ...opt, responseType: 'blob' })

// Extra values
const path2 = 'purchaseorderstatus/'
const status = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`),
  clone: ({ form, opt = {} }) => axios.post('cloneWorkflow/', form, opt),
  updatePosition: ({ form, opt = {} }) => axios.post('updatestatus/', form, opt)
}

const path3 = 'purchaseOrderProduct/'
const product = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`),
  excel: ({ pk, params, opt = {} }) =>
    axios.get(`getPurchaseOrdersExcel/${pk}/${params}`, {
      ...opt,
      responseType: 'blob'
    })
}

const path4 = 'reception/'
const reception = {
  list: ({ opt = {} }) => axios.get(path4, opt),
  create: ({ form, opt = {} }) => axios.post(path4, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path4}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path4}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path4}${pk}/`)
}

const path5 = 'purchasePending/'
const pending = {
  list: ({ opt = {} }) => axios.get(path5, opt),
  create: ({ form, opt = {} }) => axios.post(path5, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path5}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path5}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path5}${pk}/`)
}

const path6 = 'purchaseDiscrepancy/'
const discrepancy = {
  list: ({ opt = {} }) => axios.get(path6, opt),
  create: ({ form, opt = {} }) => axios.post(path6, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path6}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path6}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path6}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  status,
  counter,
  excel,
  product,
  reception,
  pending,
  discrepancy
}

const findRoutes = (ride_list, menu, route) => {
  for (let i = 0; i < menu.length; i++) {
    let subsAvalaible =
      menu[i].sub !== undefined ? menu[i].sub.filter((m) => m.show) : []
    if (menu[i].url !== undefined && menu[i].url.name === route.name) {
      ride_list.push({
        text: menu[i].title,
        icon: menu[i].icon,
        number: menu[i].number === undefined ? 1 : menu[i].number,
        disabled: true,
        to: menu[i].url
      })
      return ride_list
    } else if (subsAvalaible.length > 0) {
      let haveSubmenu = ride_list.findIndex((m) => m.to.name === 'submenu-menu')
      let subs = findRoutes(
        ride_list.concat({
          text: menu[i].title,
          icon: menu[i].icon,
          number: menu[i].number === undefined ? 1 : menu[i].number,
          disabled: false,
          to:
            haveSubmenu === -1
              ? { name: 'submenu-menu', params: { menu: menu[i].title } }
              : menu[i].url
        }),
        menu[i].sub,
        route
      )
      if (subs.length > 0) {
        return subs
      }
    }
  }
  return []
}

export default {
  namespaced: true,
  getters: {
    getBreadcrumbs: (state, getters) => (route) => {
      let breadcrumb = findRoutes([], getters.getMenu, route)
      return breadcrumb
    },
    getMenu: (state, getters, rootState, rootGetters) => {
      return [
        {
          title: 'login',
          icon: 'fa-users',
          url: { name: 'auth-login' },
          show: !rootGetters['session/isAuth'],
          size: ''
        },
        {
          title: 'dashboard',
          //icon: 'fa-tachometer-alt',
          icon: require('@/assets/color-icons/menu/kanban.png'),
          url: { name: 'index' },
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'product.view_producttemplate'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          number: 2
        },
        {
          title: 'overview',
          //icon: 'fas fa-chart-bar',
          icon: require('@/assets/color-icons/menu/overview.png'),
          url: { name: 'overview' },
          show: rootGetters['session/isAuth'] && rootGetters['session/isAdmin'],
          size: '',
          number: 2
        },
        {
          title: 'main_email',
          icon: require('@/assets/color-icons/menu/email.png'),
          show: rootGetters['session/isAuth'],
          size: '',
          sub: [
            {
              title: 'email',
              img: require('@/assets/color-icons/email/letter.png'),
              url: { name: 'mainEmail' },
              show: rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'email_setting.email_SMTP_setting',
              img: require('@/assets/color-icons/email/configuration.png'),
              url: { name: 'mainEmail-settings' },
              show:
                rootGetters['session/getPermissions']([
                  'emails.view_credentials'
                ]) || rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'administrator',
          //icon: 'fa fa-server',
          icon: require('@/assets/color-icons/menu/asistencia-remota.png'),
          show: rootGetters['session/isAuth'],
          size: '',
          sub: [
            {
              title: 'package',
              //icon: 'fa-archive',
              img: require('@/assets/color-icons/subdomain/cloud.png'),
              url: { name: 'package' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'packages.view_package'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'company',
              img: require('@/assets/color-icons/subdomain/domain.png'),
              url: { name: 'company' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'settings.add_company'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'create',
                  img: require('@/assets/color-icons/subdomain/domain.png'),
                  url: { name: 'company-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'settings.add_company',
                        'settings.change_company'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'payment',
              //icon: 'fa-cash-register',
              img: require('@/assets/color-icons/subdomain/pay.png'),
              url: { name: 'payment' },
              show:
                rootGetters['session/isAuth'] && rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'customer_manager',
          //icon: 'fa-address-book',
          icon: require('@/assets/color-icons/menu/aplicacion-de-seguimiento.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'customer.view_customer',
                'base_config.view_level'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'customer',
              img: require('@/assets/color-icons/customers/buscar.png'),
              url: { name: 'customer' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'customer.view_customer'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'create',
                  url: { name: 'customer-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'customer.add_customer'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                },
                {
                  title: 'edit',
                  url: { name: 'customer-pkEdit' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'customer.change_customer'
                      ]) &&
                      rootGetters['session/getPermissions']([
                        'base_config.view_term'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'level',
              img: require('@/assets/color-icons/customers/grupo.png'),
              url: { name: 'level' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'base_config.view_level'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'supplier_manager',
          //icon: 'fa-address-book',
          icon: require('@/assets/color-icons/menu/mejores-practicas.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'supplier.view_supplier'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'supplier',
              //icon: 'fa-address-book',
              img: require('@/assets/color-icons/suppliers/supplier.png'),
              url: { name: 'supplier' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'supplier.view_supplier'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'supplier',
                  icon: 'fa-address-book',
                  url: { name: 'supplier-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'supplier.add_supplier'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                },
                {
                  title: 'supplier',
                  icon: 'fa-address-book',
                  url: { name: 'supplier-pkEdit' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'supplier.change_supplier'
                      ]) &&
                      rootGetters['session/getPermissions']([
                        'base_config.view_term'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            }
          ]
        },
        {
          title: 'sale',
          //icon: 'fa fa-file-invoice-dollar',
          icon: require('@/assets/color-icons/menu/productivo.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'sales.view_order',
                'sales.view_orderstatus',
                'sales.view_suborderdate',
                'Invoices.view_paymenttype',
                'Invoices.view_receivable',
                'Invoices.view_invoices'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'order',
              img: require('@/assets/color-icons/sale/order.png'),
              url: { name: 'sale' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'sales.view_order'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'order',
                  img: require('@/assets/new-icons/task-list.svg'),
                  url: { name: 'sale-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'sales.add_order',
                        'sales.change_order'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'orderstatus',
              img: require('@/assets/color-icons/sale/order-status.png'),
              url: { name: 'orderstatus' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'sales.view_orderstatus'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'delivery',
              icon: 'fa fa-shipping-fast',
              url: { name: 'delivery' },
              show: true,
              size: ''
            },
            {
              title: 'paymenttype',
              img: require('@/assets/color-icons/sale/payment-method.png'),
              url: { name: 'paymenttype' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'Invoices.view_paymenttype'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'receivable',
              //icon: 'icon-monitor',
              img: require('@/assets/color-icons/sale/accounts.png'),
              url: { name: 'invoice' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'Invoices.view_receivable'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'receivable',
                  icon: 'fa-file-invoice',
                  url: { name: 'invoice-pk' },
                  number: 1,
                  withData: true,
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'Invoices.view_receivable'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: '',
                  sub: [
                    {
                      title: 'invoice',
                      icon: 'fa-file-invoice',
                      url: { name: 'invoice-Invoice' },
                      number: 1,
                      withData: true,
                      show:
                        (rootGetters['session/isAuth'] &&
                          rootGetters['session/getPermissions']([
                            'Invoices.view_invoices'
                          ]) &&
                          rootGetters['session/getPermissions']([
                            'Invoices.view_payments'
                          ])) ||
                        rootGetters['session/isAdmin'],
                      size: ''
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          title: 'purchase',
          //icon: 'fa fa-file-invoice',
          icon: require('@/assets/color-icons/menu/consejos-y-trucos.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'purchase.view_purchaseorder'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'order',
              img: require('@/assets/color-icons/purchase/order.png'),
              url: { name: 'purchase' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'purchase.view_purchaseorder'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'order',
                  icon: 'icon-request',
                  url: { name: 'purchase-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            }
          ]
        },
        {
          title: 'inventory',
          //icon: 'fa fa-box',
          icon: require('@/assets/color-icons/menu/lista-de-quehaceres.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'inventory.view_movement'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'movement',
              //icon: 'fa-file-invoice',
              img: require('@/assets/color-icons/inventory/transactions.png'),
              url: { name: 'movement' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'inventory.view_movement'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'movement',
                  icon: 'fa-file-invoice',
                  url: { name: 'movement-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            },
            {
              title: 'discrepancy',
              //icon: 'fa fa-file',
              img: require('@/assets/color-icons/inventory/discrepancy.png'),
              url: { name: 'discrepancies' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'purchase.view_purchaseorderproduct'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'product_manage',
          icon: require('@/assets/color-icons/menu/escritorio.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'product.view_product',
                'product.view_priceformula',
                'matrix.view_matrix',
                'matrix.view_matrixtemplate',
                'product.view_productiondetails',
                'base_config.view_productiondepartment'
              ]) &&
              rootGetters['session/getPermissions']([
                'product.view_producttemplate'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          number: 2,
          sub: [
            {
              title: 'product',
              img: require('@/assets/color-icons/product/products.png'),
              url: { name: 'product' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'product.view_product'
                  ]) &&
                  rootGetters['session/getPermissions']([
                    'product.view_producttemplate'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'product',
                  icon: 'fa-box',
                  url: { name: 'product-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'product.view_product'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'template',
              img: require('@/assets/color-icons/product/templates.png'),
              url: { name: 'templatesProduct' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'product.view_producttemplate'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'form',
                  img: require('@/assets/new-icons/template.svg'),
                  url: { name: 'templatesProduct-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'product.view_producttemplate'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'formula',
              img: require('@/assets/color-icons/product/pricingformula.png'),
              url: { name: 'formula' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'product.view_priceformula'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'formula',
                  icon: 'icon-calculating',
                  url: { name: 'formula-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            },
            {
              title: 'matrix',
              img: require('@/assets/color-icons/product/matrix.png'),
              url: { name: 'matrix' },
              show:
                rootGetters['session/isAuth'] &&
                rootGetters['session/getPermissions']([
                  'matrix.view_matrixtemplate'
                ]),
              size: '',
              sub: [
                {
                  title: 'form',
                  icon: 'fa-th',
                  url: { name: 'matrix-pk' },
                  show:
                    rootGetters['session/isAuth'] &&
                    rootGetters['session/getPermissions']([
                      'matrix.view_matrixtemplate'
                    ]) &&
                    rootGetters['session/getPermissions']([
                      'matrix.change_matrixtemplate'
                    ]) &&
                    rootGetters['session/getPermissions']([
                      'matrix.delete_matrixtemplate'
                    ]),
                  size: ''
                }
              ]
            },
            {
              title: 'department',
              img: require('@/assets/color-icons/product/productiondepartmet.png'),
              url: { name: 'department' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'base_config.view_productiondepartment'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'expense',
          //icon: 'fa-dollar-sign',
          icon: require('@/assets/color-icons/menu/trabajar-desde-casa.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'expenses.view_category',
                'expenses.view_account',
                'expenses.view_expense'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          number: 2,
          sub: [
            {
              title: 'expenses_regular',
              //img: require('@/assets/new-icons/expenses.svg'),
              img: require('@/assets/color-icons/expenses/expense.png'),
              url: { name: 'expensesRegular' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'expenses.view_expense'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: '',
                  icon: 'fa fa-file',
                  url: { name: 'expensesRegular-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            },
            {
              title: 'expenses_recurring',
              //img: require('@/assets/new-icons/expenses.svg'),
              img: require('@/assets/color-icons/expenses/expense.png'),
              url: { name: 'expensesRecurring' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'expenses.view_expense'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: '',
                  icon: 'fa fa-file',
                  url: { name: 'expensesRecurring-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            },
            {
              title: 'expenseCategory',
              //img: require('@/assets/new-icons/expensesCategory.svg'),
              img: require('@/assets/color-icons/expenses/expense_category.png'),
              url: { name: 'expenseCategory' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'expenses.view_category'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'expenseAccount',
              //img: require('@/assets/new-icons/expenseAccount.svg'),
              img: require('@/assets/color-icons/expenses/expense_account.png'),
              url: { name: 'expenseAccount' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'expenses.view_account'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'equipments_manage',
          //icon: 'fa-wrench',
          icon: require('@/assets/color-icons/menu/comunicacion-en-linea.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'equipements.view_equipement',
                'equipements.view_brandmodel'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          number: 2,
          sub: [
            {
              title: 'equipments',
              img: require('@/assets/color-icons/equipment/equipments.png'),
              url: { name: 'equipment' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'equipements.view_equipement'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'equipments',
                  url: { name: 'equipment-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'equipements.add_equipement'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                },
                {
                  title: 'equipment_maintenance',
                  url: { name: 'equipment-maintenance' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'equipements.change_equipement'
                      ]) &&
                      rootGetters['session/getPermissions']([
                        'equipements.view_maintenance'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'brand',
              img: require('@/assets/color-icons/equipment/brand.png'),
              url: { name: 'brand' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'equipements.view_brandmodel'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'chat',
          //icon: 'fa-comment',
          icon: require('@/assets/color-icons/menu/comunicaciones.png'),
          url: { name: 'chat' },
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions'](['chat.view_chat'])) ||
            rootGetters['session/isAdmin'],
          size: ''
        },
        {
          title: 'reports',
          //icon: 'fa-file-alt',
          icon: require('@/assets/color-icons/menu/trituradora-de-papel.png'),
          show: rootGetters['session/isAuth'],
          size: '',
          sub: [
            {
              title: 'customersReport',
              img: require('@/assets/color-icons/reports/customer_report.png'),
              url: { name: 'customersReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'customer.view_customer'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'suppliersReport',
              img: require('@/assets/color-icons/reports/suppliers_report.png'),
              url: { name: 'suppliersReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'supplier.view_supplier'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'equipmentReport',
              img: require('@/assets/color-icons/reports/equipment_report.png'),
              url: { name: 'equipmentsReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'equipements.view_equipement'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'expensesReport',
              img: require('@/assets/color-icons/reports/expenses_report.png'),
              url: { name: 'expensesReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'expenses.view_expense'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'invoicesReport',
              img: require('@/assets/color-icons/reports/invoices_report.png'),
              url: { name: 'invoicesReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'invoices.view_invoices'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'salesReport',
              img: require('@/assets/color-icons/reports/sales_report.png'),
              url: { name: 'salesReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'sales.view_order'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'purchasesReport',
              img: require('@/assets/color-icons/reports/purchases_report.png'),
              url: { name: 'purchasesReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'purchase.view_purchaseorder'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'productReport',
              img: require('@/assets/color-icons/reports/inventory_report.png'),
              url: { name: 'inventory' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'inventory.view_movement'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'movementsReport',
              img: require('@/assets/color-icons/reports/movements_report.png'),
              url: { name: 'movementsReport' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'inventory.view_movement'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'notifications_events',
          icon: require('@/assets/color-icons/settings/notifications.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'notifications.view_notification'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'notifications_events',
              img: require('@/assets/color-icons/settings/notifications.png'),
              url: { name: 'notifications' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'events.view_event'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'settings',
              img: require('@/assets/color-icons/settings/notificationssettings.png'),
              url: { name: 'notifications-settings' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'notifications.view_notification'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        },
        {
          title: 'settings',
          icon: require('@/assets/color-icons/menu/gestion-del-tiempo.png'),
          show:
            (rootGetters['session/isAuth'] &&
              rootGetters['session/getPermissions']([
                'settings.view_dumpdatadomain',
                'Invoices.view_settings',
                'sequences.view_sequence',
                'base_config.view_term',
                'base_config.view_soldby',
                'emails.view_template',
                'emails.view_credentials',
                'sales.view_typedate',
                'auth.view_user',
                'auth.view_group',
                'user_profile.view_profile',
                'auditlog.view_logentry',
                'product.view_producttemplate'
              ])) ||
            rootGetters['session/isAdmin'],
          size: '',
          sub: [
            {
              title: 'company_info',
              img: require('@/assets/color-icons/settings/company_information.png'),
              url: { name: 'settings' },
              show:
                rootGetters['session/isAuth'] && rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'carrouselTips',
              img: require('@/assets/color-icons/settings/tips_carrousel.png'),
              url: { name: 'carrouselTips' },
              show:
                rootGetters['session/isAuth'] && rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'carrouselTips',
                  icon: 'icon-prioritize',
                  url: { name: 'carrouselTips-pk' },
                  show:
                    rootGetters['session/isAuth'] &&
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'invoiceSetting',
              img: require('@/assets/color-icons/settings/invoicing_configuration.png'),
              url: { name: 'invoice-setting' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'Invoices.view_settings'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'sequence',
              img: require('@/assets/color-icons/settings/sequence.png'),
              url: { name: 'sequence' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'sequences.view_sequence'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'sequence',
                  icon: 'icon-secuencias',
                  url: { name: 'sequence-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            },
            {
              title: 'currency',
              img: require('@/assets/color-icons/settings/currency.png'),
              url: { name: 'currency' },
              show: rootGetters['session/isAuth'],
              size: '',
              sub: [
                {
                  title: 'currency',
                  icon: 'icon-Currency',
                  url: { name: 'currency-pk' },
                  show: rootGetters['session/isAuth'],
                  size: ''
                }
              ]
            },
            {
              title: 'terms',
              img: require('@/assets/color-icons/settings/term_condition.png'),
              url: { name: 'terms' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'base_config.view_term'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'measure',
              img: require('@/assets/color-icons/settings/unit_measure.png'),
              url: { name: 'soldBy' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'base_config.view_soldby'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'templates_email',
              img: require('@/assets/color-icons/settings/email_templates.png'),
              url: { name: 'templatesEmail' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'emails.view_template'
                  ]) &&
                  rootGetters['session/getPermissions']([
                    'emails.view_configtemplate'
                  ]) &&
                  rootGetters['session/getPermissions']([
                    'product.view_producttemplate'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: '',
              sub: [
                {
                  title: 'email',
                  url: { name: 'sendEmail' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'emails.view_maillist'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: '',
                  sub: [
                    {
                      title: 'email',
                      url: { name: 'sendEmail-pk' },
                      show: rootGetters['session/isAuth'],
                      size: ''
                    }
                  ]
                }
              ]
            },
            {
              title: 'email_setting.email_SMTP_setting',
              img: require('@/assets/color-icons/settings/smtpsetting.png'),
              url: { name: 'emailSettings' },
              show:
                rootGetters['session/isAuth'] &&
                rootGetters['session/getPermissions']([
                  'emails.view_credentials'
                ]),
              size: ''
            },
            {
              title: 'typedate',
              img: require('@/assets/color-icons/settings/date_type.png'),
              url: { name: 'typeDate' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'sales.view_typedate'
                  ]) &&
                  rootGetters['session/getPermissions']([
                    'product.view_producttemplate'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'settingImage',
              icon: 'fa-upload',
              url: '/settingImage',
              show: false,
              size: ''
            },
            {
              title: 'backup_schemas',
              img: require('@/assets/color-icons/settings/backup.png'),
              url: { name: 'backupSchemas' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'setting.view_dumpdatadomain'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            },
            {
              title: 'user',
              img: require('@/assets/color-icons/settings/users.png'),
              url: { name: 'users' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions'](['auth.view_user'])) ||
                rootGetters['session/isAdmin'],
              size: '',
              number: 1,
              sub: [
                {
                  title: 'user',
                  icon: 'icon-users',
                  url: { name: 'users-pk' },
                  show: rootGetters['session/isAuth'],
                  size: '',
                  number: 1
                }
              ]
            },
            {
              title: 'roles',
              img: require('@/assets/color-icons/settings/roles.png'),
              url: { name: 'roles' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions'](['auth.view_group'])) ||
                rootGetters['session/isAdmin'],
              size: '',
              number: 2,
              sub: [
                {
                  title: 'roles',
                  icon: 'fa-users-slash',
                  url: { name: 'roles-pk' },
                  show:
                    (rootGetters['session/isAuth'] &&
                      rootGetters['session/getPermissions']([
                        'auth.view_group'
                      ])) ||
                    rootGetters['session/isAdmin'],
                  size: ''
                }
              ]
            },
            {
              title: 'audit',
              img: require('@/assets/color-icons/settings/audit.png'),
              url: { name: 'auditlog' },
              show:
                (rootGetters['session/isAuth'] &&
                  rootGetters['session/getPermissions']([
                    'auditlog.view_logentry'
                  ])) ||
                rootGetters['session/isAdmin'],
              size: ''
            }
          ]
        }
      ]
    }
  }
}

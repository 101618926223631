const path = 'products/'

// Anterior:
//const salecart = ({ opt = {} }) => axios.get('salecart/', opt)
// cambio a:
const salecart = ({ opt = {} }) => axios.get('products/', opt)
const purchasecart = ({ opt = {} }) => axios.get('purchasecart/', opt)
const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const pricescreate = ({ opt = {} }) => axios.get('pricesCreate/', opt)
const favorite = ({ pk, form, opt = {} }) =>
  axios.patch(`${path}${pk}/update-favorite/`, form, opt)

// Extra values
const path2 = 'template/'
const template = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'sustrate/'
const sustrate = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`)
}

const path4 = 'productMeasure/'
const measure = {
  list: ({ opt = {} }) => axios.get(path4, opt),
  create: ({ form, opt = {} }) => axios.post(path4, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path4}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path4}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path4}${pk}/`)
}

const path5 = 'productprices/'
const prices = {
  list: ({ opt = {} }) => axios.get(path5, opt),
  create: ({ form, opt = {} }) => axios.post(path5, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path5}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path5}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path5}${pk}/`)
}

const path6 = 'extracost/'
const extracost = {
  list: ({ opt = {} }) => axios.get(path6, opt),
  create: ({ form, opt = {} }) => axios.post(path6, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path6}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path6}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path6}${pk}/`)
}

const path7 = 'production/'
const details = {
  list: ({ opt = {} }) => axios.get(path7, opt),
  create: ({ form, opt = {} }) => axios.post(path7, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path7}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path7}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path7}${pk}/`)
}

const path8 = 'production-subitem/'
const subdetails = {
  list: ({ opt = {} }) => axios.get(path8, opt),
  create: ({ form, opt = {} }) => axios.post(path8, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path8}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path8}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path8}${pk}/`)
}

const path9 = 'process/'
const processitem = {
  list: ({ opt = {} }) => axios.get(path9, opt),
  create: ({ form, opt = {} }) => axios.post(path9, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path9}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path9}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path9}${pk}/`)
}

const path10 = 'process-subitems/'
const processsubitem = {
  list: ({ opt = {} }) => axios.get(path10, opt),
  create: ({ form, opt = {} }) => axios.post(path10, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path10}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path10}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path10}${pk}/`)
}

const path11 = 'processprices/'
const processprices = {
  list: ({ opt = {} }) => axios.get(path11, opt),
  create: ({ form, opt = {} }) => axios.post(path11, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path11}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path11}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path11}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  favorite,
  salecart,
  purchasecart,
  pricescreate,
  template,
  sustrate,
  measure,
  prices,
  extracost,
  details,
  subdetails,
  processitem,
  processsubitem,
  processprices
}

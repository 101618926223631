import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { i18n } from './plugins/locale'
import { interceptorLoad } from './plugins/interceptor'
import './plugins'
import '@fortawesome/fontawesome-free/css/all.css'
import './styles/styles.scss'
// Socket Config
import VueNativeSock from 'vue-native-websocket'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)
Vue.use(
  VueNativeSock,
  process.env.NODE_ENV === 'production'
    ? `${process.env.VUE_APP_SERVER_WEBSOCKET_TYPE}${location.hostname}${process.env.VUE_APP_SERVER_WEBSOCKET_PORT}${process.env.VUE_APP_SERVER_WEBSOCKET_ROUTE}`
    : process.env.VUE_APP_WEBSOCKET,
  { store: store, format: 'json' }
)

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

interceptorLoad(vue)

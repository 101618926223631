var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.permissions.view)?_c('v-card',{staticClass:"mt-4"},[(!_vm.dontHeader)?_c('v-toolbar',{attrs:{"color":"secondary"}},[_vm._t("headerToolbar",function(){return [(!_vm.noTitle)?_c('v-toolbar-title',{staticClass:"text-dark font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-spacer'),(!_vm.dontCreate)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(!_vm.dontSearch)?_c('i-search',{on:{"search":function($event){return _vm.research()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(
          !_vm.dontCreate &&
          _vm.permissions.create &&
          _vm.otherPermission &&
          (_vm.total < _vm.limit || _vm.limit === undefined)
        )?_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-plus","title":_vm.$t('add', { value: _vm.title }),"color":"text-dark"},on:{"click":function($event){return _vm.$emit('click:create')}}}):_vm._e(),_vm._t("extraToolbar")]})],2):_vm._e(),_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"loading":_vm.loadingInternal,"items":_vm.itemsInternal,"fixed-header":"","disable-sort":"","footer-props":{
      'items-per-page-options': [5, 10, 15, 20, 40, 60]
    },"page":_vm.pageIn,"items-per-page":_vm.perPageIn},on:{"update:page":function($event){_vm.pageIn=$event},"update:itemsPerPage":function($event){_vm.perPageIn=$event},"update:items-per-page":function($event){_vm.perPageIn=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}}),_vm._l((_vm.$slots),function(_,slotName){return {key:slotName,fn:function(){return [_vm._t(slotName)]},proxy:true}}),{key:`item.actions`,fn:function({ item, value }){return [_c('v-menu',{staticClass:"mx-0",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',_vm._g({staticStyle:{"margin-top":"-0.1rem"},attrs:{"color":"primary","text":"","small":"","dark":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{staticStyle:{"margin-left":"5px"},attrs:{"size":"20"}},[_vm._v(" fas fa-ellipsis-v ")])],1):_c('v-btn',_vm._g({staticStyle:{"margin-top":"-0.1rem"},attrs:{"color":"primary","small":"","text":"","dark":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"align":"center"}},[(
              !_vm.dontEdit &&
              _vm.permissions.edit &&
              _vm.otherPermission &&
              (_vm.param == 'permissions' ? item.pk > 14 : true)
            )?_c('v-list-item',[_vm._t("editBtn",function(){return [_c('i-btn',{attrs:{"title":_vm.$t('edit'),"classes":"mx-1","outlined":"","color":"info","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('click:edit', item)}}})]},{"item":item,"value":value})],2):_vm._e(),(
              !_vm.dontRemove &&
              _vm.permissions.delete &&
              (item.canDelete !== false ||
                (_vm.param == 'permissions' ? item[_vm.param].length == 0 : false))
            )?_c('v-list-item',[_vm._t("deleteBtn",function(){return [_c('i-btn',{attrs:{"title":_vm.$tc('delete', 1),"outlined":"","classes":"mx-1","icon":"fa-trash","color":"error"},on:{"click":function($event){return _vm.remove(item.pk || item.id)}}})]},{"item":item,"remove":_vm.remove,"value":value})],2):_vm._e(),(_vm.otherBtn)?_c('v-list-item',[_vm._t("otherBtn",null,{"item":item,"value":value})],2):_vm._e(),(_vm.otherBtn2)?_c('v-list-item',[_vm._t("otherBtn2",null,{"item":item,"value":value})],2):_vm._e()],1)],1)]}}],null,true)},'v-data-table',_vm.dinamicTotal,false))],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <v-overlay
      :z-index="$vuetify.breakpoint.smAndDown ? 4 : 5"
      :opacity="1"
      color="primary"
      :value="loading"
    >
      <v-progress-circular color="white" indeterminate :size="100" :width="6">
        <v-icon size="70">icon-letrai32</v-icon>
      </v-progress-circular>
    </v-overlay>
    <navigation v-if="$route.name !== 'auth-login'" />
    <router-view />
    <v-footer v-if="$route.name !== 'auth-login'" class="main-footer">
      <v-col class="text-center py-1 caption" cols="12">
        <strong>
          &copy; 2018 - {{ new Date().getFullYear() }} IPrintPOS.
        </strong>
        {{ $tc('copyright') }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from './components/navigation/Toolbar.vue'

export default {
  name: 'App',
  components: {
    Navigation
  },
  computed: {
    ...mapGetters({
      loading: 'utils/getIsloading'
    })
  }
}
</script>
<style lang="sass">
.v-footer.main-footer
  color: var(--v-auxtext-base)
  background-color: var(--v-auxbg-base)
</style>

<route>
{
  "meta": {
    "permission": [
      "matrix.view_matrix && matrix.view_matrixtemplate"
    ]
  }
}
</route>

<template>
  <v-card class="px-0">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(submit)">
        <v-card-text>
          <!-- Tabs -->
          <v-row class="my-0">
            <v-col cols="9" md="10">
              <v-tabs class="matrix-tabs" fixed-tabs>
                <v-tab
                  v-for="(item, key) in tabs"
                  :key="key"
                  @change="tabValue(item.value)"
                >
                  {{ item.value }}
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col v-if="permission.edit" class="text-center" cols="3" md="2">
              <v-btn
                :small="$vuetify.breakpoint.mdAndUp"
                :x-small="$vuetify.breakpoint.smAndDown"
                color="error"
                @click="RemoveValue"
                :class="$vuetify.breakpoint.smAndUp ? 'mr-1' : ''"
              >
                <v-icon small class>fa-minus</v-icon>
              </v-btn>
              <v-btn
                :small="$vuetify.breakpoint.mdAndUp"
                :x-small="$vuetify.breakpoint.smAndDown"
                color="success"
                @click="openDialogTabs()"
              >
                <v-icon small class>fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Matrix -->
          <v-row class="my-0">
            <!-- Tabla -->
            <v-col class="matrix-table-container pb-3" cols="12">
              <table class="matrix-table">
                <thead>
                  <tr>
                    <!-- Esquina -->
                    <th class="d-flex flex-row g-1 matrix-corner pt-3">
                      <ValidationProvider
                        vid="item"
                        :name="$tc('item', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          class="rounded-lg rounded-b-0 matrix-input"
                          :error-messages="errors[0]"
                          :disabled="!permission.edit"
                          :placeholder="$t('item')"
                          v-model="matrix_item"
                        ></v-text-field>
                      </ValidationProvider>
                      <span class="separator"></span>
                      <ValidationProvider
                        vid="value"
                        :name="$tc('value', 1)"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          class="rounded-lg rounded-b-0 matrix-input"
                          :disabled="!permission.edit"
                          :error-messages="errors[0]"
                          :placeholder="$tc('value', 1)"
                          v-model="matrix_value"
                        ></v-text-field>
                      </ValidationProvider>
                    </th>

                    <!-- Columnas -->
                    <th
                      v-for="(col, key) of columns"
                      :key="`column-${key}`"
                      :class="{ 'd-none': key === 0 }"
                    >
                      <div class="pt-3">
                        <ValidationProvider
                          vid="column"
                          :name="$tc('column', 1)"
                          :rules="{ required: columns[key].label === '' }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="rounded-lg rounded-b-0 matrix-input"
                            dense
                            maxlength="20"
                            :error-messages="errors[0]"
                            :disabled="!permission.edit"
                            :value="col.label"
                            @input="setColumn($event, key)"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                    </th>

                    <!-- Botones -->
                    <th v-if="permission.edit" class="text-center">
                      <v-btn
                        class="mr-1"
                        color="error"
                        small
                        :disabled="current != 0"
                        @click="RemoveColumn"
                      >
                        <v-icon small>fa-minus</v-icon>
                      </v-btn>
                      <v-btn
                        color="success"
                        small
                        :disabled="current != 0"
                        @click="AddColumn"
                      >
                        <v-icon small>fa-plus</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, i) in rows[current]"
                    :key="`rangeRow-${current}-${i}`"
                  >
                    <!-- Filas -->
                    <td class="d-flex flex-row g-1 pt-3 border-0">
                      <ValidationProvider
                        vid="range_start"
                        :name="`initialRange-${current}-${i}`"
                        :rules="{
                          required: true,
                          min_value:
                            i == 0
                              ? 0
                              : titleLength === 1
                              ? Number(rows[current][i - 1][0].value[0]) + 1
                              : 0
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          class="rounded-lg rounded-b-0 matrix-input"
                          dense
                          :disabled="!permission.edit || detailId != null"
                          :error-messages="errors[0]"
                          :value="row[0].value[0]"
                          @input="setRow($event, i, 0, row[0].type, 0)"
                        ></v-text-field>
                      </ValidationProvider>
                      <ValidationProvider
                        v-if="titleLength > 1"
                        vid="range_end"
                        :name="`endRange-${current}-${i}`"
                        :rules="{
                          required: titleLength > 1,
                          min_value: row[0].value[0]
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          class="rounded-lg rounded-b-0 matrix-input"
                          dense
                          :disabled="!permission.edit || detailId != null"
                          :error-messages="errors[0]"
                          :value="row[0].value[1]"
                          @input="setRow($event, i, 0, row[0].type, 1)"
                          style="border-radius: 4px !important"
                        ></v-text-field>
                      </ValidationProvider>
                    </td>

                    <!-- Filas de costos/precios -->
                    <td
                      v-for="(r, j) in row"
                      :key="j"
                      :class="{ 'd-none': j === 0 }"
                    >
                      <div class="d-flex flex-row g-2 px-2 pt-2">
                        <ValidationProvider
                          v-if="j != 0"
                          vid="cost"
                          :name="$tc('cost', 1)"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="input-matrix"
                            :disabled="!permission.edit"
                            :error-messages="errors[0]"
                            :label="$tc('cost', 1)"
                            v-model="r[0].value"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          v-if="j != 0 && r[0].value !== undefined"
                          vid="price"
                          :name="$tc('price', 1)"
                          :rules="{
                            required: true,
                            min_value:
                              matrix.pk > 0 && !new_column
                                ? Number(r[0].value).toFixed(2) * 1 + 0.01
                                : r[0].value
                          }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="input-matrix"
                            :disabled="!permission.edit"
                            :error-messages="errors[0]"
                            :label="$tc('price', 1)"
                            v-model="r[1].value"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                    </td>
                  </tr>
                  <tr v-show="current == 0" class="text-center">
                    <td class="d-flex g-1 justify-content-center py-3">
                      <v-btn
                        small
                        color="error"
                        @click="RemoveRow"
                        :disabled="permission.edit && detailId == null"
                      >
                        <v-icon small>fa-minus</v-icon>
                      </v-btn>
                      <v-btn
                        small
                        color="success"
                        @click="AddRow"
                        :disabled="permission.edit && detailId == null"
                      >
                        <v-icon small>fa-plus</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>

          <!-- Botones Costo/Precio - Copiar -->
          <v-row class="my-0">
            <v-col cols="6" md="4">
              <div class="d-flex g-2">
                <i-money
                  hideDetails
                  outlined
                  :disabled="!permission.edit"
                  :label="$t('add_cost')"
                  v-model="add_cost"
                ></i-money>
                <div
                  v-if="permission.edit"
                  class="d-flex align-self-center g-1"
                >
                  <v-btn color="success" @click="AddCost('value')">
                    <v-icon small>fa-plus</v-icon>
                  </v-btn>
                  <v-btn color="info" @click="AddCost('percent')">
                    <v-icon small>fa-percent</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="6" md="4">
              <div class="d-flex g-2">
                <i-money
                  hideDetails
                  outlined
                  :disabled="!permission.edit"
                  :label="$t('add_price')"
                  v-model="add_price"
                ></i-money>
                <div
                  v-if="permission.edit"
                  class="d-flex align-self-center g-1"
                >
                  <v-btn color="success" @click="AddPrice('value')">
                    <v-icon small>fa-plus</v-icon>
                  </v-btn>
                  <v-btn color="info" @click="AddPrice('percent')">
                    <v-icon small>fa-percent</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
              v-if="tabs.length != 0 && permission.edit"
              class="align-self-center text-center"
              cols="12"
              md="4"
            >
              <v-btn color="primary" @click="openCopy(matrix)">
                <v-icon class="mr-1" small>fa-copy</v-icon>
                {{ $t('clone') }} {{ $tc('price', 1) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Modal: Clonar precios entre tabs -->
        <ClonePrice
          :tabs="tabs"
          :to-edit="toEdit"
          @clone="cloneValues"
          @close="openCopy"
          @done="reload = true"
          v-model="dialog2"
        />

        <!-- Acciones rápidas flotante -->
        <v-speed-dial
          v-if="permission.edit"
          class="d-md-none"
          bottom
          direction="top"
          fixed
          right
          v-model="fab"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="primary" dark fab>
              <v-icon v-show="fab">fa-angle-down</v-icon>
              <v-icon v-show="!fab">fa-sliders-h</v-icon>
            </v-btn>
          </template>

          <!-- Eliminar columna -->
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                color="error"
                :disabled="current != 0"
                @click="RemoveColumn"
                v-on="on"
              >
                <v-icon small>fa-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('matrix_table.remove_cols') }}</span>
          </v-tooltip>

          <!-- Agregar columna -->
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                fab
                :disabled="current != 0"
                @click="AddColumn"
                v-on="on"
              >
                <v-icon small>fa-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('matrix_table.add_cols') }}</span>
          </v-tooltip>

          <!-- Eliminar fila -->
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                dark
                fab
                :disabled="permission.edit && detailId == null"
                @click="RemoveRow"
                v-on="on"
              >
                <v-icon small>fa-arrow-up</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('matrix_table.remove_rows') }}</span>
          </v-tooltip>

          <!-- Agregar fila -->
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                fab
                :disabled="permission.edit && detailId == null"
                @click="AddRow"
                v-on="on"
              >
                <v-icon small>fa-arrow-down</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('matrix_table.add_rows') }}</span>
          </v-tooltip>
        </v-speed-dial>

        <!-- Modal: Tabs -->
        <v-dialog v-model="dialogTab" max-width="390" persistent>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-items>
                <v-btn text dark @click="closeDialogTabs">
                  <v-icon>fa-times</v-icon>
                </v-btn>
              </v-toolbar-items>
              <v-spacer></v-spacer>
              <v-toolbar-title class="font-weight-black">
                {{ $t('create_value') }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  text
                  dark
                  @click="AddValue"
                  :small="$vuetify.breakpoint.smAndDown"
                  :loading="load"
                >
                  <template v-slot:loader>
                    <v-progress-circular color="white" indeterminate width="3">
                      <v-icon
                        size="20"
                        v-text="'$vuetify.icons.loading'"
                      ></v-icon>
                    </v-progress-circular>
                  </template>
                  <v-icon>fa-save</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pt-4">
              <v-text-field outlined v-model="value"></v-text-field>
            </v-card-text>
          </v-card>
        </v-dialog>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import './matrix.sass'
import { mapGetters } from 'vuex'
import ClonePrice from '../../components/matrix/modal.vue'
export default {
  props: {
    productId: {
      type: [String, Number],
      default: null
    },
    detailId: {
      type: [String, Number],
      default: null
    },
    subdetailId: {
      type: [String, Number],
      default: null
    },
    levelId: {
      type: [String, Number],
      default: null
    },
    new_template: {
      type: Number,
      default: null
    }
  },
  components: {
    ClonePrice
  },
  data() {
    return {
      titleLength: 0,
      edit: false,
      one_row: true,
      clone: false,
      add_cost: 0,
      add_price: 0,
      counter: 0,
      current: 0,
      columns: [{ label: 'Item/Value', field: 'init' }],
      tabs: [],
      value: '',
      rows: { 0: [] },
      matrix: {
        pk: '',
        matrix_type: '1D',
        rows_title: [],
        matrix_values: [],
        product: null,
        detail: null,
        subdetail: null
      },
      matrix_item: '',
      matrix_value: '',
      load: false,
      loading: false,
      dialog: false,
      fab: false,
      dialogTab: false,
      level: null,
      reload: false,
      dialog2: false,
      toEdit: null,
      new_column: false,
      current_tabs: [],
      double_tabs: []
    }
  },
  methods: {
    openDialogTabs() {
      this.value = ''
      this.dialogTab = true
    },
    closeDialogTabs() {
      this.dialogTab = false
      this.value = ''
    },
    AddColumn() {
      this.new_column = true
      this.$refs.form.validate().then((success) => {
        if (success || this.one_row) {
          this.counter++
          this.columns.splice(this.columns.length, 0, {
            label: '',
            field: 'added_' + this.counter
          })
          this.AppendRow('added_' + this.counter)
        }
      })
    },
    AddRow() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let tabs = this.tabs.length == 0 ? [0] : this.tabs
          for (let key in tabs) {
            let row = []
            for (let col of this.columns) {
              let item = {}
              if (col.field == 'init') {
                let value =
                  this.rows[key][this.rows[key].length - 1][0].value[1] ===
                  undefined
                    ? this.rows[key][this.rows[key].length - 1][0].value[0]
                    : this.rows[key][this.rows[key].length - 1][0].value[1]
                let lastValue = this.one_row
                  ? this.rows[key].length == 0
                    ? ''
                    : Number(value) + 1
                  : this.rows[key].length == 0
                  ? ''
                  : Number(value) + 1
                item[col.field] = ''
                item['value'] = [lastValue, '']
                item['type'] = 'init'
              } else {
                let cost = {}
                cost[col.field] = ''
                cost['value'] = 0
                cost['label'] = this.$tc('cost', 1)
                cost['type'] = 'cost'
                let price = {}
                price[col.field] = ''
                price['value'] = 0
                price['label'] = this.$tc('price', 1)
                price['type'] = 'price'
                item = []
                item.push(cost, price)
              }
              row.push(item)
            }
            this.rows[key].push(row)
          }
        }
      })
    },
    RemoveColumn() {
      if (this.columns.length > 1) {
        this.columns.splice(this.columns.length - 1, 1)
        let tabs = this.tabs.length == 0 ? [0] : this.tabs
        for (let key in tabs) {
          for (let row of this.rows[key]) {
            row.pop()
          }
        }
      }
    },
    RemoveRow() {
      let tabs = this.tabs.length == 0 ? [0] : this.tabs
      for (let key in tabs) {
        this.rows[key].pop()
      }
    },
    AppendRow(field) {
      let tabs = this.tabs.length == 0 ? [0] : this.tabs
      for (let key in tabs) {
        for (let row of this.rows[key]) {
          let item = {}
          if (field == 'init') {
            item[field] = ''
            item['value'] = ['', '']
            item['type'] = 'init'
          } else {
            let cost = {}
            cost[field] = ''
            cost['value'] = 0
            cost['label'] = this.$tc('cost', 1)
            cost['type'] = 'cost'
            let price = {}
            price[field] = ''
            price['value'] = 0
            price['label'] = this.$tc('price', 1)
            price['type'] = 'price'
            item = []
            item.push(cost, price)
          }
          row.push(item)
        }
      }
    },
    /**
     * tabsValidate
     * Método que evita la duplicación de los tabs de la matriz
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    tabsValidate() {
      this.current_tabs = this.matrix.rows_title
      this.double_tabs = this.matrix.rows_title.concat(this.matrix.rows_title)
      let new_tabs = []
      for (let current in this.rows) {
        if (this.tabs.length > 0 && this.tabs[current] != undefined) {
          this.matrix.matrix_type = '2D'
          new_tabs.push(this.tabs[current].value)
        }
      }
      if (this.new_tabs == this.double_tabs) {
        this.matrix.rows_title = this.current_tabs
      } else {
        this.matrix.rows_title = new_tabs
      }
    },
    /**
     * armMatrix
     * Método que guarda los nombres de las filas, columnas y
     * valores de costo y precio de cada tab de cada matriz
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    armMatrix() {
      this.matrix.matrix_values = []
      this.tabsValidate()
      for (let current in this.rows) {
        var matrix_price
        var matrix_cost
        if (this.edit) {
          matrix_price = {
            pk: 0,
            row_name: this.tabs[current] ? this.tabs[current].value : '',
            rows_title: [],
            col_title: [],
            matrix_type: 'price',
            values: [],
            item_value: { item: this.matrix_item, value: this.matrix_value }
          }
          matrix_cost = {
            pk: 0,
            row_name: this.tabs[current] ? this.tabs[current].value : '',
            rows_title: [],
            col_title: [],
            matrix_type: 'cost',
            values: [],
            item_value: { item: this.matrix_item, value: this.matrix_value }
          }
        } else {
          matrix_price = {
            row_name: this.tabs[current] ? this.tabs[current].value : '',
            rows_title: [],
            col_title: [],
            matrix_type: 'price',
            values: [],
            item_value: { item: this.matrix_item, value: this.matrix_value }
          }
          matrix_cost = {
            row_name: this.tabs[current] ? this.tabs[current].value : '',
            rows_title: [],
            col_title: [],
            matrix_type: 'cost',
            values: [],
            item_value: { item: this.matrix_item, value: this.matrix_value }
          }
        }
        // Iteration for asing columns values
        for (let col of this.columns) {
          if (col.field != 'init') {
            matrix_price.col_title.push(col.label)
            matrix_cost.col_title.push(col.label)
          }
        }
        // Iteration for values
        for (var i = 0; i < this.rows[current].length; i++) {
          let cost = []
          let price = []
          for (var j = 0; j < this.columns.length; j++) {
            // Append rows titles
            if (!this.rows[current][i][j].length) {
              let new_title =
                this.rows[current][i][0].value.length == 1
                  ? this.rows[current][i][0].value[0] + ' - '
                  : this.rows[current][i][0].value[0] +
                    ' - ' +
                    this.rows[current][i][0].value[1]
              matrix_price.rows_title.push(new_title)
              matrix_cost.rows_title.push(new_title)
            } else {
              // Append values
              for (let m_matrix of this.rows[current][i][j]) {
                if (m_matrix.type == 'price') {
                  price.push(parseFloat(m_matrix.value))
                } else if (m_matrix.type == 'cost') {
                  cost.push(parseFloat(m_matrix.value))
                }
              }
            }
          }
          if (this.edit) {
            matrix_cost.pk = this.rows[current][0][1][0].pk
            matrix_price.pk = this.rows[current][0][1][1].pk
          }
          matrix_cost.values.push(cost)
          matrix_price.values.push(price)
        }
        this.matrix.matrix_values.push(matrix_cost, matrix_price)
      }
    },
    setColumn(value, key) {
      this.columns[key].label = value
    },
    setRow(value, i, j, type, pos) {
      if (type) {
        if (type == 'cost') {
          this.rows[this.current][i][j][0].value = value
        } else if (type == 'price') {
          this.rows[this.current][i][j][1].value = value
        } else {
          let qty = 1
          if (this.tabs.length != 0) {
            qty = this.tabs.length
          }
          for (var current = 0; current < qty; current++) {
            if (this.rows[current][i] !== undefined)
              this.rows[current][i][j].value[pos] = value
          }
        }
      }
    },
    validateMatrix() {
      // Check tabs title
      for (let tabs of this.tabs) {
        let count = 0
        for (let i in this.tabs) {
          if (this.tabs[i] == tabs) {
            count++
          }
        }
        if (count > 1) {
          this.$toast.error(`${this.$t('cols_tab_be_unique')}`)
          return false
        }
      }
      // Check rows title
      for (let title of this.matrix.rows_title) {
        let count = 0
        for (let i in this.matrix.rows_title) {
          if (this.matrix.rows_title[i] == title) {
            count++
          }
        }
        if (
          count > 1 &&
          this.current_tabs.length > 0 &&
          this.current_tabs.concat(this.current_tabs) == this.double_tabs
        ) {
          this.$toast.error(`${this.$t('rows_must_be_unique')}`)
          return false
        }
      }
      // Check colum values
      for (let values of this.matrix.matrix_values) {
        for (let title of values.col_title) {
          let count = 0
          for (let i in values.col_title) {
            if (values.col_title[i] == title) {
              count++
            }
          }
          if (count > 1) {
            this.$toast.error(`${this.$t('cols_must_be_unique')}`)
            return false
          }
        }
        // Check if its numeric
        for (let inside_values of values.values) {
          for (let number of inside_values) {
            if (typeof number != 'number' || isNaN(number)) {
              this.$toast.error(
                `${this.$tc('value', 2)} ${this.$t('must_be_numeric')}`
              )
              return false
            }
          }
        }
        // Check rows title
        let last_row_value = null
        for (let number of values.rows_title) {
          let splited = number.split(' - ')
          let first = parseInt(splited[0])
          let second = parseInt(splited[1])
          if (splited[1] != '') {
            if (isNaN(first) || isNaN(second)) {
              this.$toast.error(
                `${this.$t('row_title')} ${this.$t('must_be_numeric')}`
              )
              return false
            }
            if (second < first) {
              this.$toast.error(
                `${this.$t('row_title')} ${this.$t('value_must_be_greater', {
                  value: this.$t('second_value')
                })}`
              )
              return false
            }
            if (!last_row_value) {
              last_row_value = second
            } else {
              if (first <= last_row_value) {
                this.$toast.error(
                  `${this.$t('row_title')} ${this.$t('value_must_be_greater', {
                    value: this.$tc('value', 1)
                  })}`
                )
                return false
              } else {
                last_row_value = second
              }
            }
          }
        }
      }
      return true
    },
    AddValue() {
      if (this.value != '') {
        if (this.tabs.filter((m) => m.value == this.value).length == 0) {
          this.tabs.push({ value: this.value })
          this.rows[this.tabs.length - 1] = JSON.parse(
            JSON.stringify(this.rows[0])
          )
        } else {
          this.$toast.error(`${this.$t('tabs_must_be_unique')}`)
        }
        if (this.tabs.length - 1 != 0) {
          for (var i = 0; i < this.rows[this.tabs.length - 1].length; i++) {
            for (var j = 0; j < this.columns.length; j++) {
              for (
                var k = 0;
                k < this.rows[this.tabs.length - 1][i][j].length;
                k++
              ) {
                if (
                  this.rows[this.tabs.length - 1][i][j][k].type == 'cost' ||
                  this.rows[this.tabs.length - 1][i][j][k].type == 'price'
                ) {
                  this.rows[this.tabs.length - 1][i][j][k].pk = 0
                }
              }
            }
          }
        }
        this.value = ''
        this.closeDialogTabs()
      } else {
        this.$toast.error(`${this.$t('value')} ${this.$t('required')}`)
      }
    },
    RemoveValue() {
      this.tabs.pop()
    },
    tabValue(text) {
      for (let element in this.tabs) {
        if (this.tabs[element].value == text) {
          this.current = element
          break
        }
      }
    },
    async submit(item) {
      this.matrix.detail = this.detailId
      this.matrix.subdetail = this.subdetailId
      let clone = item ? item : false
      this.armMatrix()
      if (this.matrix.pk !== '') {
        this.$toast.success(`${this.$tc('matrix', 1)} ${this.$tc('edit', 2)}`)
      }
      if (this.matrix.product === null || this.matrix.product === '') {
        this.matrix.pk !== ''
          ? this.$api.matrix.matrixprocess.edit({
              pk: this.matrix.pk,
              form: this.matrix
            })
          : ''
        if (this.validateMatrix() || clone) {
          this.matrix.clone = this.new_template
          let create =
            this.matrix.pk === ''
              ? await this.$api.matrix.matrixprocess.create({
                  form: this.matrix
                })
              : ''
          if (create !== '') {
            this.$toast.success(
              `${this.$tc('matrix', 1)} ${this.$tc('created', 2)}`
            )
          }
          this.getMatrix('process')
        }
      } else {
        this.matrix.pk !== ''
          ? this.$api.matrix.edit({
              pk: this.matrix.pk,
              form: this.matrix
            })
          : ''
        if (this.validateMatrix() || clone) {
          let create =
            this.matrix.pk === ''
              ? await this.$api.matrix.create({
                  form: this.matrix
                })
              : ''
          if (create !== '') {
            this.$toast.success(
              `${this.$tc('matrix', 1)} ${this.$tc('created', 2)}`
            )
          }
          this.getMatrix('product')
        }
      }
    },
    async getMatrix(type) {
      const params = {
        product_id: this.productId !== undefined ? this.productId : null,
        level_id: this.levelId,
        detail_id: this.detailId,
        subdetail_id: this.subdetailId !== '' ? this.subdetailId : null
      }
      if (type === 'product') {
        await this.$api.matrix
          .list({
            opt: {
              params: params
            }
          })
          .then((response) => {
            if (response.data.length > 0) this.$emit('hasMatrix', true)
          })
      } else {
        await this.$api.matrix.matrixprocess
          .list({
            opt: {
              params: params
            }
          })
          .then((response) => {
            if (response.data.length > 0) this.$emit('hasMatrix', true)
          })
      }
    },
    async setValues(matrix, edit) {
      this.edit = edit
      this.one_row = false
      //Set defaults
      this.dialog = true
      this.columns = [{ label: 'Item/Value', field: 'init' }]
      this.rows = { 0: [] }
      this.tabs = []
      this.current = 0
      let qty = 1
      if (matrix !== undefined) {
        // Set values
        if (this.edit) {
          this.matrix.pk = matrix.pk
          this.matrix.product =
            matrix.product_details !== undefined
              ? matrix.product_details.id
              : ''
          this.product_name =
            matrix.product_details !== undefined
              ? matrix.product_details.name
              : ''
          this.matrix_item = matrix.matrix_values[0].item_value.item
          this.matrix_value = matrix.matrix_values[0].item_value.value
        }
        this.titleLength =
          matrix.matrix_values[0].rows_title[0].split(' - ').length
        this.level = matrix.level_details.name
        this.matrix.matrix_type = matrix.matrix_type
        if (matrix.matrix_type == '2D') {
          for (let tab of matrix.rows_title) {
            this.tabs.push({ value: tab })
          }
          qty = matrix.rows_title.length * 2
        }
        // Set values on column
        for (let label of matrix.matrix_values[0].col_title) {
          this.counter++
          this.columns.splice(this.columns.length, 0, {
            label: label,
            field: 'added_' + this.counter
          })
        }
        for (var current = 0; current < qty; current += 2) {
          //Set values on rows
          for (
            var i = 0;
            i < matrix.matrix_values[current].rows_title.length;
            i++
          ) {
            let row = []
            let init = {}
            for (
              var j = 0;
              j < matrix.matrix_values[current].col_title.length;
              j++
            ) {
              let item = []
              // Set init
              let title =
                matrix.matrix_values[current].rows_title[i].split(' - ')
              if (title.length == 1) {
                this.one_row = true
                title = [title[0].replace(' -', '')]
              }
              init['init'] = ''
              init['value'] = title
              init['type'] = 'init'
              let cost = {}
              let price = {}
              // Se evalua por si no se guardó en el order esperado
              let val1 = matrix.matrix_values[current].values[i]
              let val2 = matrix.matrix_values[current + 1].values[i]
              if (matrix.matrix_values[current].matrix_type == 'cost') {
                // Set Cost
                // If one row
                if (!this.one_row && this.columns[i] != undefined) {
                  cost[this.columns[i].field] = ''
                  price[this.columns[i].field] = ''
                }
                if (this.edit) {
                  cost['pk'] = matrix.matrix_values[current].pk
                  price['pk'] = matrix.matrix_values[current + 1].pk
                }
                cost['value'] = val1 === undefined ? 0 : val1[j]
                cost['label'] = this.$tc('cost', 1)
                cost['type'] = matrix.matrix_values[current].matrix_type
                // Set Price
                price['value'] = val2 === undefined ? 0 : val2[j]
                price['label'] = this.$tc('price', 1)
                price['type'] = matrix.matrix_values[current + 1].matrix_type
              } else {
                // Set Price
                if (!this.one_row && this.columns[i] != undefined) {
                  cost[this.columns[i].field] = ''
                  price[this.columns[i].field] = ''
                }
                if (this.edit) {
                  price['pk'] = matrix.matrix_values[current].pk
                  cost['pk'] = matrix.matrix_values[current + 1].pk
                }
                price['value'] = val1 === undefined ? 0 : val1[j]
                price['label'] = this.$tc('price', 1)
                price['type'] = matrix.matrix_values[current].matrix_type

                // Set Cost
                cost['value'] = val2 === undefined ? 0 : val2[j]
                cost['label'] = this.$tc('cost', 1)
                cost['type'] = matrix.matrix_values[current + 1].matrix_type
              }

              item.push(cost, price)
              row.push(item)
            }
            // Append row zero titles
            row.splice(0, 0, init)
            this.rows[this.current].push(row)
          }
          if (
            matrix.matrix_type == '2D' &&
            this.tabs.length - 1 > this.current
          ) {
            this.current++
            this.rows[this.current] = []
          }
        }
      }
      this.current = 0
    },
    setTemplate(matrix) {
      //Set defaults
      this.dialog = true
      this.columns = [{ label: 'Item/Value', field: 'init' }]
      this.rows = { 0: [] }
      this.tabs = []
      this.current = 0
      let qty = 1
      // Set values
      this.matrix.product = this.productId
      matrix.matrix_template_values = matrix.matrix_template_values.reverse()
      this.matrix.matrix_type = matrix.matrix_type
      this.matrix_item = matrix.matrix_template_values[0].item_value.item
      this.matrix_value = matrix.matrix_template_values[0].item_value.value
      if (matrix.matrix_type == '2D') {
        for (let tab of matrix.rows_title) {
          this.tabs.push({ value: tab })
        }
        qty = matrix.rows_title.length
      }
      // Set values on column
      for (let label of matrix.matrix_template_values[0].col_title) {
        this.counter++
        this.columns.splice(this.columns.length, 0, {
          label: label,
          field: 'added_' + this.counter
        })
      }
      for (var current = 0; current < qty; current += 1) {
        //Set values on rows
        if (matrix.matrix_template_values[current] !== undefined) {
          for (
            var i = 0;
            i < matrix.matrix_template_values[current].rows_title.length;
            i++
          ) {
            let row = []
            let init = {}
            for (
              var j = 0;
              j < matrix.matrix_template_values[current].col_title.length;
              j++
            ) {
              let item = []
              // Set init
              let title =
                matrix.matrix_template_values[current].rows_title[i].split(
                  ' - '
                )
              this.titleLength =
                title.length === 2 && title[1] === '' ? 1 : title.length
              if (title.length == 1) {
                title = [title[0].replace(' -', '')]
              }
              init['init'] = ''
              init['value'] = title
              init['type'] = 'init'
              // Set Cost
              let cost = {}
              let price = {}
              // If one row
              if (!this.one_row && this.columns[i] != undefined) {
                cost[this.columns[i].field] = ''
                price[this.columns[i].field] = ''
              }
              cost['value'] = 0
              cost['label'] = this.$tc('cost', 1)
              cost['type'] = 'cost'
              // Set Price
              price['value'] = 0
              price['label'] = this.$tc('price', 1)
              price['type'] = 'price'
              item.push(cost, price)
              row.push(item)
            }
            // Append row zero titles
            row.splice(0, 0, init)
            this.rows[this.current].push(row)
          }
          if (
            matrix.matrix_type == '2D' &&
            this.tabs.length - 1 > this.current
          ) {
            this.current++
            this.rows[this.current] = []
          }
        }
      }
      this.current = 0
    },
    AddCost(value) {
      if (this.add_cost != 0) {
        this.addCostOrPercent('cost', value, this.add_cost)
        this.add_cost = 0
      }
    },
    AddPrice(value) {
      if (this.add_price != 0) {
        this.addCostOrPercent('price', value, this.add_price)
        this.add_price = 0
      }
    },
    addCostOrPercent(matrix_type, percent_or_value, value) {
      for (var i = 0; i < this.rows[this.current].length; i++) {
        for (var j = 0; j < this.columns.length; j++) {
          for (var k = 0; k < this.rows[this.current][i][j].length; k++) {
            if (
              this.rows[this.current][i][j][k].type == matrix_type &&
              percent_or_value == 'value'
            ) {
              this.rows[this.current][i][j][k].value =
                parseFloat(this.rows[this.current][i][j][k].value) +
                parseFloat(value)
            } else if (
              this.rows[this.current][i][j][k].type == matrix_type &&
              percent_or_value == 'percent'
            ) {
              this.rows[this.current][i][j][k].value =
                parseFloat(this.rows[this.current][i][j][k].value) +
                parseFloat(this.rows[this.current][i][j][k].value) *
                  (parseFloat(value) / 100)
            }
            this.rows[this.current][i][j][k].value = Number(
              this.rows[this.current][i][j][k].value
            ).toFixed(2)
          }
        }
      }
    },
    openCopy(item) {
      this.clone = !this.clone
      this.toEdit = item === undefined ? null : item
      this.dialog2 = true
    },
    cloneValues(values) {
      this.rows[values.to] = JSON.parse(JSON.stringify(this.rows[values.from]))
      this.clone = !this.clone
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    permission() {
      return {
        edit: this.getPermissions([`matrix.change_matrix`]) || this.isAdmin
      }
    }
  },
  watch: {
    productId(val) {
      if (val != null) {
        this.matrix.product = val
      }
    }
  }
}
</script>

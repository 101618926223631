<template>
  <v-menu open-on-click bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        offset-x="30"
        offset-y="10"
        color="secondary"
        bottom
        :content="
          notifications.length == ''
            ? '0'
            : notifications.length > 99
            ? '+99'
            : notifications.length
        "
      >
        <v-btn dark text v-bind="attrs" v-on="on">
          <v-img
            @click="getEventsNotification"
            :src="
              mode == 'notifications'
                ? require('@/assets/color-icons/settings/notifications.png')
                : require('@/assets/color-icons/toolbar/notification.png')
            "
            height="32"
            width="32"
          ></v-img>
        </v-btn>
      </v-badge>
    </template>
    <v-list v-if="notifications.length > 0">
      <v-list-item
        @click="open(item)"
        v-for="(item, index) in notifications"
        :key="index"
        text-color="primary"
        :style="{
          'background-color': item.color + ' !important',
          'font-weight': 700,
          width: '100%',
          color: item.statusDark || item.paid ? 'white' : 'black'
        }"
        class="mb-2 ma-0"
      >
        <v-list
          class="py-0"
          dense
          v-if="mode === 'events'"
          :style="{
            'background-color': item.color + ' !important',
            'font-weight': 700,
            width: '100%',
            color: item.statusDark || item.paid ? 'white' : 'black'
          }"
        >
          <v-col>
            {{ $tc('suborder', 1) }}:
            <span
              class="item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"
            >
              {{ item.title }}
            </span>
            {{ $t('next_sub_state') }}:
            <span
              class="item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"
            >
              {{ $tc(item.statusName?.split(' - ')[0], 1) }} -
              {{ $tc(item.statusName?.split(' - ')[1], 1) }}
            </span>
            <span
              class="item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"
            >
              {{ item.updated_date }}
            </span>
          </v-col>
          <v-list-group @click.stop="">
            <v-icon
              slot="appendIcon"
              size="12"
              :color="item.statusDark || item.paid ? 'white' : 'black'"
              >fas fa-arrow-down
            </v-icon>
            <template v-slot:activator>
              <v-list-item-content text-color="primary" class="mb-2 ma-0">
                <v-list-item-title></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-col
              cols="4"
              :style="{
                'background-color': item.color + ' !important',
                'font-weight': 700,
                width: '100%',
                color: item.statusDark || item.paid ? 'white' : 'black'
              }"
            >
              <v-col v-for="date in item.type_dates" :key="`date-${date.name}`">
                <span>{{ date.name }}:</span>
                <span
                  class="item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"
                >
                  {{ dateFormat(date.date) }}
                </span>
              </v-col>
              <v-col>
                {{ $t('promise_date') }}:
                <span
                  class="item.statusDark || item.paid ? 'white' : 'black'--text subtitle-2"
                >
                  {{ dateFormat(item.promise_date) }}
                </span>
              </v-col>
              <suborder
                class="primary--text subtitle-2 front-weight-primary"
                :order="item.order"
                :subOrder.sync="item.subOrder"
                :calendar="true"
                :miniSubOrder.sync="subOrder"
              ></suborder>
            </v-col>
          </v-list-group>
        </v-list>
        <v-list-item-title v-if="mode === 'notifications'">
          <div v-if="item.content_type === 'message'">
            {{ $t(item.title) }} {{ item.details }}
          </div>
          <div v-else>
            {{ item.content_type }}
            : {{ item.title }} {{ item.date }}
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-title>{{ $t('no_notifications') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex'
import suborder from '../sale/MiniSuborder.vue'

export default {
  components: {
    suborder
  },
  props: {
    mode: {
      type: String
    }
  },
  data() {
    return {
      notifications: [],
      current: 0,
      subOrder: {}
    }
  },
  computed: {
    ...mapGetters({
      getTotalNotifications: 'notifications/getTotalNotifications',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  },
  methods: {
    open(item) {
      this.current = item.pk
      this.$router.push({
        name:
          item.content_type === 'expense'
            ? 'expensesRecurring-pk'
            : item.content_type === 'maintenance'
            ? 'equipment-maintenance'
            : item.content_type === 'message'
            ? 'sale'
            : '',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    /**
     * getEventsNotification
     * Método para consultar los eventos de:
     *  Gastos recurrentes, mantenimiento de equipo recurrente y subOrdenes de venta
     * Incluye los filtros:
     *  - Date: se muestran las notificaciones que van desde
     *    Las notificaciones de gastos pagados se muestran por 15 días
     *    Las notificaciones de gastos no pagados se muestran hasta que se paguen
     *    O por un año en caso contrario
     *  - Mode: Notificación para la campana de gastos y mantenimiento de equipo
     *  y evento para la campana de sobOrdenes
     *  - User: para mostrar al usuario no administrador sólo las notificaciones que
     *  le corresponden, mientras que el administrador las puede visualizar todas
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getEventsNotification() {
      const back_date = this.$moment()
        .subtract(365, 'days')
        .format('YYYY-MM-DD')
      const start_date = this.$moment()
        .subtract(15, 'days')
        .format('YYYY-MM-DD')
      const end_date = this.$moment().add(1, 'days').format('YYYY-MM-DD')
      await this.$api.event
        .list({
          opt: {
            params: {
              date__back: back_date,
              date__gte: start_date,
              date__lte: end_date,
              mode: this.mode,
              user: this.isAdmin ? null : this.me.id
            }
          }
        })
        .then((response) => {
          let res_events = response.data
          let notifications = res_events.map((event) => {
            return {
              pk: event.identifier,
              title: event.title,
              details: event.details,
              date: event.date,
              color:
                event.subOrder != undefined
                  ? event.statusColor
                  : event.background,
              app_label: event.app_label,
              content_type: event.model,
              statusName: event.statusName,
              statusDark: event.statusDark,
              paid: event.paid,
              order: event.order,
              subOrder: event.subOrder,
              promise_date: event.promise_date,
              type_dates: event.type_dates
            }
          })
          this.notifications = notifications
          for (const notification of this.notifications) {
            this.$store.dispatch('notifications/addNotification', notification)
          }
        })
    }
  },
  mounted() {
    this.getEventsNotification()
  }
}
</script>

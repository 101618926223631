<route>
{
  "meta": {
    "permission": [
      "sales.view_suborderdate"
    ]
  }
}
</route>

<template>
  <div>
    <dialdate
      v-model="dialog"
      :to-edit="toEdit"
      ref="dialdate"
      :template="template"
      :order="order"
      @done="reload = true"
    />
    <i-card-list
      class="mt-2 px-2"
      :title="$t('dates')"
      dontHeader
      app="sales.suborderdate"
      api="suborder.date"
      :method="'show'"
      :inactive="'changeDate'"
      :requestOpts="{ pk: order }"
      :opt="{ order: true }"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
      <template v-slot:[`item`]="{ item }">
        <v-chip
          outlined
          color="error"
          v-if="item.changeDate"
          small
          pill
          class="font-weight-bold mr-2"
        >
          {{ $t('previus_date') }}
        </v-chip>
        <span class="title font-weight-black primary--text">
          {{ dateFormat(item.date) }}
        </span>
        <v-spacer />
        <span class="grey--text font-weight-black">
          {{ `${$t('typedate')}: ` }}
        </span>
        <span class="grey--text">
          {{ `${item.typeName}` }}
        </span>
        <br />
        <v-list v-show="item.observation !== ''" dense class="py-0">
          <v-list-group @click.stop="">
            <v-icon slot="appendIcon" size="12">fas fa-arrow-down</v-icon>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="primary--text subtitle-2 font-weight-black">
                    {{ $tc('observation', 2) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span> {{ item.observation }} </span>
          </v-list-group>
        </v-list>
      </template>
    </i-card-list>
  </div>
</template>
<script>
import dialdate from './dialDate.vue'
export default {
  components: {
    dialdate
  },
  props: {
    order: {
      required: true,
      type: Number
    },
    template: {
      type: Number
    }
  },
  data: () => ({
    dates: [],
    loading: false,
    reload: false,
    dialog: false,
    toEdit: null
  }),
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('v-row',{class:{
        infocard: _vm.readonly,
        listcard: !_vm.readonly,
        'mx-0': true
      },style:({
        'border-left': '10px solid #254B61 !important',
        width: '100%'
      }),on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}},[_c('v-row',[_c('v-col',{class:{
            'pt-1': true,
            'pb-0': true,
            'my-0': true,
            'text-center': true,
            'primary--text': true,
            'text-uppercase': true,
            'font-weight-black': true,
            'subtitle-1': !_vm.$vuetify.breakpoint.xsOnly,
            title: _vm.$vuetify.breakpoint.xsOnly
          },attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(`${_vm.name != undefined ? _vm.name : ''} ${parseInt(_vm.name) > 0 ? _vm.$t('days') : ''}`)+" ")]),_c('v-col',{class:{
            'pt-1': true,
            'pb-0': true,
            'my-0': true
          },attrs:{"cols":"2","align":"end"}},[(_vm.remove)?_c('i-btn',{attrs:{"outlined":"","title":_vm.$tc('delete', 1),"icon":"fa-trash","classes":"mx-1","color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}}):_vm._e()],1),(_vm.detail != null && _vm.remove)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-switch',{key:"hidden-input",attrs:{"disabled":"","label":_vm.$tc('hidden'),"name":"hidden","color":"primary"},model:{value:(_vm.detail.hidden),callback:function ($$v) {_vm.$set(_vm.detail, "hidden", $$v)},expression:"detail.hidden"}})],1):_vm._e(),(_vm.detail != null && _vm.remove)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{key:"have_dimensions-input",attrs:{"disabled":"","label":_vm.$tc('dimension'),"name":"have_dimensions","color":"primary"},model:{value:(_vm.detail.have_dimensions),callback:function ($$v) {_vm.$set(_vm.detail, "have_dimensions", $$v)},expression:"detail.have_dimensions"}})],1):_vm._e(),(_vm.detail != null && _vm.remove)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-switch',{key:"multi_choice-input",attrs:{"disabled":"","label":_vm.$tc('multi_choice'),"name":"multi_choice","color":"primary"},model:{value:(_vm.detail.multi_choice),callback:function ($$v) {_vm.$set(_vm.detail, "multi_choice", $$v)},expression:"detail.multi_choice"}})],1):_vm._e(),_c('v-col',{class:{
            'primary--text': true,
            'font-weight-bold': true,
            'mx-3': true
          },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.detail != undefined ? _vm.detail.calc : '')+" ")]),_vm._l((_vm.value),function(attr,keydetail){return _c('v-col',{key:`${'dimension'}-${keydetail}`,staticClass:"pt-1",attrs:{"cols":"6","sm":"6"}},[_c('div',{class:{
              'primary--text': true,
              'font-weight-bold': true,
              'mx-3': true
            }},[_vm._v(" "+_vm._s(`${attr['name']}`)+" ")]),_c('div',{staticClass:"caption px-3"},[_vm._v(" Decimal: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(attr['value'])+" "+_vm._s(attr['measure'])+" ")])]),(attr['measure'] === 'in')?_c('div',{staticClass:"caption px-3"},[_c('span',[_vm._v(_vm._s(_vm.$t('fraction')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(attr['main'])+" "+_vm._s(attr['decimalText'])+" "+_vm._s(attr['measure'])+" ")])]):_vm._e(),(attr['measure'] === 'ft')?_c('div',{staticClass:"caption px-3"},[_c('span',[_vm._v(_vm._s(_vm.$t('fraction')))]),_vm._v(": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(attr['main'])+" ft "+_vm._s(attr['second'])+" "+_vm._s(attr['decimalText'])+" in ")])]):_vm._e(),(
              attr['rounded'] !== undefined &&
              attr['value'] !== undefined &&
              attr['value'] !== ''
            )?_c('div',{staticClass:"caption px-3"},[_c('span',[_vm._v(_vm._s(_vm.$t('rounded2')))]),_vm._v(": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(attr.rounded)+" "+_vm._s(attr['defaultMeasure'] !== undefined ? attr['defaultMeasure'] : attr['measure'])+" ")])]):_vm._e()])})],2),_c('br'),(
          (_vm.price !== '' && _vm.price !== null && _vm.price !== undefined) ||
          (_vm.qty !== '' && _vm.qty !== null && _vm.qty !== undefined)
        )?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('br'),_c('v-divider'),(
            (_vm.detail != null &&
              _vm.detail.priceString === '' &&
              _vm.detail.price_type === 'matrix') ||
            (_vm.detail.price_type === 'formula' &&
              _vm.detail.priceString === '' &&
              JSON.parse(JSON.stringify(_vm.detail.dimensions) === '{}'))
          )?_c('div',[_c('v-alert',{attrs:{"type":"info","outlined":"","color":"error"}},[_c('span',{class:{
                'primary--text': true,
                'text-uppercase': true,
                'font-weight-black': true
              }},[_vm._v(" "+_vm._s(_vm.$tc('edit', 2))+" "+_vm._s(_vm.$tc('value', 2))+" ")])])],1):_vm._e(),(
            (_vm.detail != null && _vm.detail.price_type === 'base') ||
            _vm.detail.priceString !== '' ||
            (_vm.detail.priceString === '' &&
              JSON.parse(JSON.stringify(_vm.detail.dimensions) !== '{}'))
          )?_c('div',{staticClass:"px-4 justify-space-between d-flex"},[(_vm.price !== '' && _vm.price !== null && _vm.price !== undefined)?_c('i-money',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true
            },attrs:{"value":_vm.price,"label":`${_vm.$tc('price', 1)}:`,"mode":"text"}}):_vm._e(),(_vm.qty !== '' && _vm.qty !== null && _vm.qty !== undefined)?_c('span',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true
            }},[_vm._v(" "+_vm._s(_vm.$tc('amount', 2))+" : "+_vm._s(_vm.qty)+" ")]):_vm._e(),(
              _vm.price !== '' &&
              _vm.price !== null &&
              _vm.price !== undefined &&
              _vm.qty !== '' &&
              _vm.qty !== null &&
              _vm.qty !== undefined
            )?_c('i-money',{class:{
              'primary--text': true,
              'text-uppercase': true,
              'font-weight-black': true
            },attrs:{"value":Number(_vm.price) * Number(_vm.qty),"label":`${_vm.$tc('subtotal', 1)}:`,"mode":"text"}}):_vm._e()],1):_vm._e()],1):_vm._e()],1),_c('br'),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
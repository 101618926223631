<template>
  <v-text-field
    v-if="mode == 'input'"
    :ref="name"
    :value="formattedValue"
    @input="changeValue"
    @focus="focusVar"
    :name="name"
    :label="label"
    type="tel"
    :readonly="readonly"
    :disabled="disabled"
    @keydown="restrictInputs"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    outlined
  ></v-text-field>
  <span v-else>
    <span v-text="label != undefined ? label : name != undefined ? name : ''">
    </span>
    <slot></slot>
    {{ formattedValue }}
  </span>
</template>
<script>
const between = (min, n, max) => Math.max(min, Math.min(n, max))
const fixed = (precision) => between(0, precision, 20)
const toStr = (value) => (value ? value.toString() : '')
const onlyNumbers = (input) => toStr(input).replace(/\D+/g, '') || '0'
const numbersToCurrency = (numbers, precision) => {
  const exp = Math.pow(10, precision)
  const float = parseFloat(numbers) / exp
  return float.toFixed(fixed(precision))
}
const addThousandSeparator = (integer, separator) => {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
}
const currencyToIntegerAndDecimal = (float) => {
  return toStr(float).split('.')
}
const joinIntegerAndDecimal = (integer, decimal, separator) => {
  return decimal ? integer + separator + decimal : integer
}
const setCursor = (el, position) => {
  var setSelectionRange = function () {
    el.setSelectionRange(position, position)
  }
  if (el === document.activeElement) {
    setSelectionRange()
    setTimeout(setSelectionRange, 1) // Android Fix
  }
}

const format = (input, opt) => {
  if (typeof input === 'number') {
    input = input.toFixed(fixed(opt.precision))
  }
  var negative = input.indexOf('-') >= 0 ? '-' : ''
  var numbers = onlyNumbers(input)
  var currency = numbersToCurrency(numbers, opt.precision)
  var parts = toStr(currency).split('.')
  var integer = parts[0]
  var decimal = parts[1]
  integer = addThousandSeparator(integer, opt.thousands)
  let union = joinIntegerAndDecimal(integer, decimal, opt.decimal)

  return opt.prefix + negative + union + opt.suffix
}

const unformat = (value, precision) => {
  if (typeof value === 'number') {
    value = Number(value).toFixed(precision)
  }
  const negative = value.indexOf('-') >= 0 ? -1 : 1
  const numbers = onlyNumbers(value)
  const currency = numbersToCurrency(numbers, precision)
  return parseFloat(currency) * negative
}

// Custom rule for validate numbers
import { extend } from 'vee-validate'
import { mapGetters } from 'vuex'

extend('dontallownegative', {
  validate: (value) => unformat(Number(value).toFixed(2), 2) >= 0
})
extend('dontallowzero', {
  validate: (value) => unformat(value, 2) > 0
})
extend('maxmoney', {
  validate: (value, max) =>
    unformat(Number(max[0]).toFixed(2), 2) >= unformat(value, 2)
})

export default {
  props: {
    mode: {
      default: 'input',
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true,
      type: [Number, String],
      default: 0
    },
    allownegative: {
      default: false,
      type: Boolean
    },
    allowzero: {
      default: false,
      type: Boolean
    },
    precision: {
      default: 2,
      type: Number
    },
    currency: {
      type: Object
    },
    errorMessages: {
      default: ''
    },
    hideDetails: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      validationDefault: {
        dontallownegative: !this.allownegative,
        dontallowzero: !this.allownegative
      },
      formattedValue: ''
    }
  },
  methods: {
    restrictInputs(event) {
      var key = event.which
      if (
        !(key == 8 && this.formattedValue != format(0, this.money)) &&
        !(
          key == 189 &&
          this.formattedValue.indexOf('-') == -1 &&
          key == 189 &&
          this.formattedValue != format(0, this.money)
        ) &&
        key != 9 &&
        key != 0 &&
        key != 110 &&
        key != 96 &&
        key != 105 &&
        key != 104 &&
        key != 103 &&
        key != 102 &&
        key != 101 &&
        key != 100 &&
        key != 99 &&
        key != 98 &&
        key != 97 &&
        key != 48 &&
        key != 49 &&
        key != 50 &&
        key != 51 &&
        key != 52 &&
        key != 53 &&
        key != 54 &&
        key != 55 &&
        key != 56 &&
        key != 57
      ) {
        event.preventDefault()
      }
    },
    changeValue(val) {
      var el = this.$refs[this.name].$refs['input']
      this.$emit('input', unformat(val, this.money.precision))
      this.$nextTick(() => {
        setCursor(el, el.value.length - this.money.suffix.length)
      })
    },
    focusVar() {
      var el = this.$refs[this.name].$refs['input']
      setCursor(el, el.value.length - this.money.suffix.length)
    }
  },
  computed: {
    ...mapGetters({
      currencyDefault: 'company/getDefaultCurrency'
    }),
    msgError() {
      let err = this.errors.items.find((m) => m.field == this.name)
      return err != undefined ? err.msg : ''
    },
    money() {
      let currencyDefault =
        this.currency != undefined ? this.currency : this.currencyDefault
      currencyDefault = new Intl.NumberFormat(currencyDefault.locale, {
        style: currencyDefault.style,
        currency: currencyDefault.currency
      }).formatToParts(80000)
      let decimal = currencyDefault.find((obj) => obj.type == 'decimal')
      decimal = decimal != undefined ? decimal.value : ''
      let thousands = currencyDefault.find((obj) => obj.type == 'group')
      thousands = thousands != undefined ? thousands.value : ''
      let currency = currencyDefault.find((o) => o.type == 'currency')
      currency = currency == undefined ? '' : currency.value
      let literal = currencyDefault.find((o) => o.type == 'literal')
      literal = literal == undefined ? '' : literal.value
      let pos = currencyDefault[0].type == 'currency' ? true : false
      let prefix = pos ? `${currency}${literal}` : ''
      let suffix = !pos ? `${literal}${currency}` : ''

      return {
        decimal: decimal,
        thousands: thousands,
        prefix: prefix,
        suffix: suffix,
        precision: this.precision,
        masked: false
      }
    },
    valueRules() {
      return [
        (v) => {
          if (!this.allownegative)
            return v > /0[.,]/ || this.$t('validation.positive_number')
          return true
        },
        (v) => {
          if (!this.allowzero)
            return (
              unformat(v, this.money.precision) > 0 ||
              this.$t('validation.zero')
            )
          return true
        }
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        var formatted = format(newValue, this.money)
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted
        }
      }
    },
    currency: {
      immediate: true,
      handler() {
        var formatted = format(this.value, this.money)
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted
        }
      }
    }
  }
}
</script>

<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="detailsForm">
    <form class="pt-3" @submit.prevent="handleSubmit(submit)">
      <v-row
        v-for="(item, keydetail) in detail"
        class="details-by-order--item"
        :key="`${'fields'}-${keydetail}`"
      >
        <!-- Título y oculto -->
        <v-col cols="12" md="4" lg="3">
          <!-- Título -->
          <p class="details-by-order--title pt-1">
            {{ item.name }}
            {{
              item.name * val == item.name && item.name !== undefined
                ? $t('days')
                : ''
            }}
          </p>
          <p
            v-if="item.is_multipliable"
            class="font-weight-bold text-muted pt-1"
          >
            {{ $tc('is_multipliable') }}
          </p>

          <!-- Oculto -->
          <v-switch
            class="v-switch mt-0"
            color="primary"
            key="hidden-input"
            name="hidden"
            :label="$tc('hidden')"
            v-model="item.hidden"
          ></v-switch>
        </v-col>

        <!-- Precio del item -->
        <v-col
          v-if="item.price_type === 'base' && item.detail"
          cols="12"
          md="8"
          lg="9"
        >
          <i-money v-model="item.price" :label="$tc('price', 0)" />
        </v-col>

        <!-- Fórmula del item -->
        <v-col v-if="item.price_type === 'formula'" cols="12" md="8" lg="9">
          <!-- showResult para mostrar el subtotal -->
          <i-formula
            v-if="typeof item.formula !== Number"
            :readonly="readonly"
            :formula="item.formula"
            :dimensions="item.have_dimensions ? item.dimensions : {}"
            :price="
              selectedDetail !== null && selectedDetail !== undefined
                ? selectedDetail.cost
                : item.price
            "
            :cost="
              selectedDetail !== null && selectedDetail !== undefined
                ? selectedDetail.cost
                : 0
            "
            :measure="item.have_dimensions ? measure : ''"
            :defaultMeasure="defaultMeasure"
            :rounded="rounded"
            :rootDimensions="
              item.pk !== '' && item.pk !== undefined ? {} : item.dimensions
            "
            :qty="qty"
            @change="item.price = $event"
            @changedimensions="item.dimensions = $event"
            v-model="item.priceString"
          />
        </v-col>

        <v-col v-if="item.price_type === 'matrix'" cols="12" md="8" lg="9">
          <i-matrix
            :type_customer="subOrder.level"
            :productId="subOrder.product"
            :priceString="item.priceString"
            :price="item.price"
            :qty="qty"
            :subOrder="subOrder"
            :detail.sync="detail"
            :showMatrix="false"
            :matrixUse="'details'"
            @change="item.price = $event"
          >
          </i-matrix>
        </v-col>

        <!-- Subtotal del item -->
        <v-col
          v-if="item.detail"
          cols="12"
          md="4"
          lg="3"
          offset-md="8"
          offset-lg="9"
        >
          <v-alert text type="info" color="primary">
            <i-money mode="text" :label="$t('subtotal')" :value="item.price" />
          </v-alert>
        </v-col>

        <!-- Botones -->
        <v-col class="d-flex justify-content-between pb-3" cols="12">
          <v-btn color="error" outlined @click="remove(item)">
            <v-icon class="px-5">fa-trash</v-icon>
          </v-btn>
          <v-btn color="primary" @click="submit(item)">
            <v-icon class="px-5">fa-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    details: {
      type: Array,
      default() {
        return []
      },
      required: false
    },
    order: Number,
    readonly: {
      type: Boolean,
      default: false
    },
    measure: String,
    defaultMeasure: String,
    rounded: Boolean,
    qty: [Number, String],
    creatingDetail: {
      default: 1,
      type: Number
    },
    reload: {
      type: Boolean,
      default: false
    },
    subOrder: {
      type: Object
    },
    type_customer: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: null,
      loading: false,
      load: false,
      title: '',
      val: 1,
      detail: [],
      selectedDetail: null
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    orderDetails() {
      return {
        add: this.getPermissions([`sales.add_orderdetails`]) || this.isAdmin,
        edit:
          this.getPermissions([`sales.change_orderdetails`]) || this.isAdmin,
        delete:
          this.getPermissions([`sales.delete_orderdetails`]) || this.isAdmin
      }
    }
  },
  watch: {
    reload(val) {
      if (val) {
        this.getDetails()
        this.$emit('update:reload', false)
      }
    },
    order: {
      immediate: true,
      handler() {
        this.getDetails()
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    getNameSelect(item) {
      return `${item.name} ${
        this.item.typeDetail === 'deliverytime' ? this.$t('days') : ''
      }`
    },
    async submit(item) {
      try {
        this.load = true
        await this.$api.suborder.production.edit({
          pk: item.pk,
          form: item
        })
        this.$toast.success(
          `${this.$tc('details_production', 1)} ${this.$tc('edited', 2)}`
        )
        this.getDetails()
      } catch (error) {
        console.error('Error en el submit del detailsByOrder')
        console.error(error)
      } finally {
        this.load = false
      }
    },
    async remove(item) {
      this.title = item.name
      this.showConfirmation({
        show: true,
        model: this.$tc(this.title, 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc(this.title, 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => {
          this.$api.suborder.production.remove({ pk: item.pk })
          this.getDetails()
          this.$emit('update:reload', true)
        }
      })
    },
    async getDetails() {
      let subtotal = []
      let response = await this.$api.suborder.production.show({
        pk: this.subOrder.pk,
        opt: {
          params: {
            subOrder: true
          }
        }
      })
      this.detail = response.data
      for (let item of this.detail) {
        if (
          item.price > 0 &&
          (item.priceString != '' ||
            item.have_dimensions ||
            item.price_type === 'base')
        ) {
          subtotal = subtotal.concat(item)
        }
      }
      this.$emit('input', subtotal)
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.title || !val) {
          this.getDetails()
          this.deleting = false
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  mounted() {
    this.getDetails()
  },
  beforeDestroy() {
    this.unwatch()
  }
}
</script>
<style lang="sass">
.details-by-order--title
  font-size: 1rem
  line-height: 1.75rem
  font-weight: bolder
  @media (width >= 600px)
    font-size: 1.25rem
    line-height: 2rem
.details-by-order--item
  padding-bottom: 12px
  border-radius: 8px
  margin-bottom: 12px

.theme--light
  .dialog-product--modal-second-step
    .details-by-order--item
      background-color: rgb(0 0 0 / 5%)
      .details-by-order--title
        color: #616161

.theme--dark
  .dialog-product--modal-second-step
    .details-by-order--item
      background-color: rgb(255 255 255 / 5%)
      .details-by-order--title
        color: #e0e0e0
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i-modal',{attrs:{"title":_vm.$t(_vm.productprice.discount ? 'title_discount' : 'title_extra'),"value":_vm.value,"load":_vm.loading},on:{"submit":_vm.submit,"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.load)?_c('v-skeleton-loader',{attrs:{"type":"button","tile":""}}):_vm._e(),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load),expression:"!load"}],key:"autoprice-input",staticClass:"v-switch mt-0",attrs:{"color":"secondary","name":"autoprice","disabled":_vm.readonly,"label":_vm.$t('bypercent')},model:{value:(_vm.productprice.autoprice),callback:function ($$v) {_vm.$set(_vm.productprice, "autoprice", $$v)},expression:"productprice.autoprice"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('i-money',{class:{
          'font-weight-black': true,
          'grey--text': true,
          title: !_vm.$vuetify.breakpoint.xsOnly
        },attrs:{"mode":"text","value":_vm.price,"label":_vm.$tc('price', 1)}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.load)?_c('v-skeleton-loader',{attrs:{"type":"text","tile":""}}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"percent","name":_vm.$tc('percent', 1),"rules":{
          required: _vm.productprice.percent !== '',
          double: 2
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load),expression:"!load"}],key:"percent-input",attrs:{"outlined":"","error-messages":errors[0],"label":_vm.$t('percent'),"disabled":!_vm.productprice.autoprice || _vm.readonly},model:{value:(_vm.productprice.percent),callback:function ($$v) {_vm.$set(_vm.productprice, "percent", $$v)},expression:"productprice.percent"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.load)?_c('v-skeleton-loader',{attrs:{"type":"text","tile":""}}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"price","name":_vm.$tc('price', 1),"rules":{
          required: _vm.productprice.percent === '',
          max_value: _vm.productprice.discount
            ? Number(this.price).toFixed(2)
            : Number.MAX_VALUE
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('i-money',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load),expression:"!load"}],key:"price-input",attrs:{"error-messages":errors[0],"label":_vm.$tc('amount', 1),"disabled":_vm.productprice.autoprice || _vm.readonly},model:{value:(_vm.productprice.price),callback:function ($$v) {_vm.$set(_vm.productprice, "price", $$v)},expression:"productprice.price"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"observation","name":_vm.$tc('observation', 1),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"name":"observation","disabled":_vm.readonly,"error-messages":errors[0],"color":"secondary","label":_vm.$tc('observation', 1),"outlined":""},model:{value:(_vm.productprice.observation),callback:function ($$v) {_vm.$set(_vm.productprice, "observation", $$v)},expression:"productprice.observation"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.price <= 0 && _vm.productprice.discount),expression:"price <= 0 && productprice.discount"}],attrs:{"type":"warning","outlined":"","value":true}},[_c('span',{staticClass:"font-weight-black title"},[_vm._v(" "+_vm._s(`${_vm.$tc('pricenegative')}`)+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="emailDialog.active" persistent :width="'600px'">
    <v-card>
      <i-toolbar :title="$t('edit')" :loading="loading" dontSave>
        <template v-slot:submit>
          <v-divider class="mx-4" inset vertical></v-divider>
          <i-btn
            :title="$t('edit')"
            v-if="!preview"
            dark
            text
            :small="$vuetify.breakpoint.smAndDown"
            color="text-dark"
            :loading="loading"
            @click="activeEdit"
            icon="fa-edit"
          >
          </i-btn>
        </template>
        <template v-slot:close>
          <i-btn
            :title="$tc('close', 1)"
            dark
            text
            :small="$vuetify.breakpoint.smAndDown"
            color="text-dark"
            @click="close"
            icon="fa-times"
          >
          </i-btn>
        </template>
      </i-toolbar>
      <br />
      <v-row class="mx-1">
        <v-alert v-if="empty" type="info" outlined color="primary">
          <span>{{ $tc('dont_templates_email') }}</span>
        </v-alert>
        <v-alert v-if="!empty" type="info" outlined color="primary">
          <span>{{ $tc('email_edit') }}</span>
        </v-alert>
        <br /><br />
        <v-col cols="12" md="12" lg="6" class="pa-0">
          <sendmail
            container="modal"
            v-model="edit"
            :dataEmail="emailDialog"
            :orderId="orderId"
            @done="close()"
          ></sendmail>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import sendmail from '../../pages/sendEmail/_pk.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    sendmail
  },
  props: {
    orderId: {
      type: Number
    }
  },
  data() {
    return {
      edit: false,
      dialog: false,
      loading: false,
      preview: false,
      bodyText: '',
      emailDialog: {
        active: false,
        stateMain: '',
        stateID: '',
        recipient_list: '',
        subject: '',
        body: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      getDataEmailNotification: 'utils/getDataEmailNotification',
      company: 'company/getCompanyData'
    })
  },
  methods: {
    close() {
      this.dialog = false
      this.preview = false
      this.$store.dispatch('utils/openDialogEmailNotification', {
        active: false
      })
    },
    activeEdit() {
      this.preview = true
      this.edit = true
      this.emailDialog['user'] = this.me.id
      this.emailDialog['company_id'] = this.company.pk
      this.emailDialog['body'] = ''
    }
  },
  watch: {
    getDataEmailNotification: {
      immediate: true,
      deep: true,
      handler(val) {
        this.emailDialog = val
        this.empty = this.emailDialog.body === ''
      }
    }
  }
}
</script>

<template>
  <i-btn
    v-if="!searching && !permanent"
    :title="$t('search')"
    dark
    text
    :class="classBtn"
    :color="color"
    @click="changeMode(true)"
    icon="fa-search"
  />
  <v-text-field
    :class="classes"
    v-else
    autofocus
    :color="color"
    single-line
    clearable
    :value="value"
    outlined
    dense
    @input="$emit('input', $event)"
    :label="$t('search')"
    :prepend-icon="permanent ? '' : 'fa-chevron-right'"
    append-outer-icon="fa-search"
    @keydown.esc.prevent="changeMode(false)"
    @keydown.enter.prevent="$emit('search')"
    @click:clear="$emit('input', '')"
    @click:append-outer="$emit('search')"
    @click:prepend="changeMode(false)"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-dark'
    },
    classes: {
      type: String,
      default: 'mt-5 table-search'
    },
    classBtn: {
      type: String,
      default: ''
    },
    permanent: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      searching: false
    }
  },
  methods: {
    changeMode(val) {
      this.searching = val
      this.$emit('searching', val)
      if (!val) {
        this.$emit('input', '')
        this.$emit('search')
      }
    }
  }
}
</script>
<style lang="scss">
.table-search input {
  color: var(--text-color) !important;
}
</style>

const path = 'customers/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.patch(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const history = ({ pk, opt = {} }) =>
  axios.get(`getHistoryCustomer/${pk}/`, opt)
const excel = ({ pk, params, opt = {} }) =>
  axios.get(`getCustomersExcel/${pk}/${params}`, {
    ...opt,
    responseType: 'blob'
  })

// Extra values
const path2 = 'levels/'
const level = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'contacts/'
const contact = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  history,
  level,
  contact,
  excel
}

<template>
  <v-card>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="9">
        <v-switch v-model="have_role" :label="$t('role_select')"></v-switch>
      </v-col>
      <v-col cols="12" v-if="have_role">
        <v-autocomplete
          autocomplete="off"
          outlined
          v-model="selectedRol"
          :items="roles"
          class="secondary--text role"
          item-text="name"
          item-value="pk"
          :label="$tc('roles', 2)"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="user"
          :name="$tc('user', 2)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            autocomplete="off"
            outlined
            v-model="toEdit.users"
            :error-messages="errors[0]"
            :items="users"
            chips
            deletable-chips
            :item-text="getName"
            item-value="id"
            key="role-input"
            :label="$tc('user', 2)"
            multiple
          >
          </v-autocomplete>
        </ValidationProvider>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true,
      type: [Array],
      default: null
    },
    toEdit: {
      required: true,
      type: [Object]
    },
    api: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedRol: [],
      roles: [],
      users: [],
      have_role: false
    }
  },
  watch: {
    selectedRol: {
      handler(val) {
        if (val !== undefined) {
          this.getUsers(val)
        }
      }
    }
  },
  methods: {
    getName: (item) =>
      `${item.groups[0] != undefined ? item.groups[0].name : ''} ${
        item.groups[0] != undefined ? ': ' : ''
      } ${item.first_name} ${item.last_name}`,
    async getRoles() {
      const role = await this.$api.user.group({})
      this.roles = role.data
    },
    async getUsers(rol) {
      let selected = []
      const user = await this.$api.user.list({
        opt: {
          params: {
            role: rol
          }
        }
      })
      let users = user.data.results
      if (!this.have_role) {
        this.users = users
      } else {
        for (let user of users) {
          selected.push(user.id)
        }
        this.toEdit.users = selected
      }
    }
  },
  mounted() {
    this.getRoles()
    if (!this.have_role) {
      this.getUsers()
    }
  }
}
</script>

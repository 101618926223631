import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'
import store from '@/store'
var tinycolor = require('tinycolor2')

Vue.use(Vuetify)

let lang =
  localStorage.getItem('locale') != undefined
    ? localStorage.getItem('locale')
    : navigator.language.split('-')[0]

let colors = store.getters['company/getCompanyData']
if (colors === undefined) {
  colors = {}
}
if (colors.primary_color === undefined) {
  colors.primary_color = '#34495e'
}

if (colors.secondary_color === undefined) {
  colors.secondary_color = '#345d48'
}

let darkMode = store.getters['session/darkMode']

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: darkMode,
    themes: {
      light: {
        primary: colors.primary_color,
        secondary: colors.secondary_color,
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        muted: '#DBDBDB',
        main: colors.primary_color,
        auxbg: '#ffffff',
        auxtext: '#000000'
      },
      dark: {
        primary: tinycolor(colors.primary_color).lighten(25).toString(),
        secondary: colors.secondary_color,
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        muted: '#878984',
        main: '#ffffff',
        auxbg: '#1e1e1e',
        auxtext: '#ffffff'
      }
    }
  },
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: { es, en },
    current: lang
  }
})

const darkText = tinycolor(colors.primary_color).isDark()
const root = document.documentElement
//  Set new colors
root.style.setProperty('--primary', colors.primary_color)
root.style.setProperty('--secundary', colors.secondary_color)
root.style.setProperty('--text-color', darkText ? '#fff' : '#000')

export default vuetify

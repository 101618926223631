export const es = {
  abbr: 'Abreviatura',
  approvalStatus: 'Estado de aprobación',
  account: 'Cuenta',
  active: 'Activo',
  activate: 'Activar',
  add: 'Ingresar {value}',
  add2: 'Añadir a {value}',
  email_subdomain: 'Aprobación de subdominio de {value}',
  added: 'Se agregó',
  add_cost: 'Sumar costo',
  add_discount: 'Agregar descuento',
  add_extra: 'Agregar extra',
  add_fields: 'Se Agregaron los siguientes campos: ',
  add_price: 'Sumar precio',
  add_quantity: 'Agregar cantidad',
  add_user_group: 'Agregar usuarios a grupos',
  add_user_permissions: 'Agregar permisos a usuarios',
  address: 'Dirección',
  address2: 'Dirección 2',
  addressee: 'Destinatario',
  additional_data: 'Datos adicionales',
  additional_side: 'Información adicional',
  administrator: 'Administrador de empresas',
  actor: 'Autor',
  action: 'Acciones',
  all: 'Todos',
  amount: 'Cantidad|Cant',
  and: 'y',
  approve: 'Aprobar',
  approved: 'Aprobado',
  aproved_quote: 'Aprobar cotización',
  assign: 'Asignar',
  assignament_date: 'Fecha de asignación',
  assignDesigner: 'Asignar diseñador',
  assignProduction: 'Miembros de producción encargados',
  assignProduction_no:
    'Al finalizar el diseño, podrá asignar los miembros del equipo de producción para esta Suborden',
  assignedDesign: 'Asignado a diseñador',
  assignedDesigns: 'Diseños asignados',
  assignDriver: 'Asignar chofer',
  assignSubOrder: 'Asignar Suborden',
  assignedProducts: 'Productos asignados',
  assignDesigners: 'Debe asignar diseñadores al proyecto',
  assignedDesigners: 'Diseñadores asignados en el proyecto',
  assignedDrivers: 'Choferes asignados en el proyecto',
  assignedOneDriver: 'Sólo puede asignar un chofer',
  assignProductions: 'Debe asignar productores al proyecto',
  assignedProductions: 'Productores asignados en el proyecto',
  assignedSubOrders: 'Sub-órdenes asignadas al chofer',
  areSure: '¿Está seguro que deseas {action} {model}?',
  are_sure: '¿Está seguro?',
  attachments: 'Archivos adjuntos',
  attach_images: 'Clic aquí para adjuntar imágenes manualmente',
  attributes: 'Atributos',
  available: 'Disponible',
  avalaibleDesigners: 'Diseñadores dispobles para el proyecto',
  audit: 'Auditoria',
  audit_system: 'Realizado por el sistema',
  back: 'Volver',
  background: 'Fondo',
  background_color: 'Color de fondo',
  background_url: 'Url',
  background_image: 'Imagen de fondo',
  backup_schemas: 'Respaldar esquemas de base de datos',
  balance: 'Balance',
  base: 'Base',
  base_salary: 'Salario base',
  basic: 'Información básica',
  before: 'Antes',
  billing_address: 'Dirección de Cobro',
  payment_address: 'Dirección de Pago',
  company_address: 'Dirección de la empresa',
  body: 'Cuerpo',
  boolean: 'Booleano',
  both: 'Ambas',
  bought_date: 'Fecha de compra',
  bought_date_and_rent_date: 'Fecha de Compra o Alquiler',
  rent_date: 'Fecha de alquiler',
  bussiness_info: 'Información de empresa',
  buy: 'Compra',
  blocked: 'Bloqueado',
  brand: 'Marca',
  bypercent: 'Por porcentaje',
  by_range: 'Por rangos',
  calendar: 'Calendario',
  cancel: 'Cancelar',
  canceled: 'Cancelada',
  cancel_specific: 'Cancelar {value}',
  cancelorder: '¿Quieres cancelar la orden {order}?',
  can_be: 'Puede ser',
  cash: 'Efectivo',
  product_catalog: 'Catálogo de productos',
  carrouselTips: 'Carrusel de consejos',
  category: 'Categoria | Categorias',
  cellphone: 'Celular',
  cc: 'Tarjeta de credito',
  change: 'Cambiado | Cambiada',
  change_status: {
    mail_delivery_notification: 'Verificación de envío de correo ',
    shipment_preview: 'Previsualización de envío',
    ask: '¿Desea enviar la notificación por correo directamente al cliente?',
    notification: 'Cambio de estado de una suborden'
  },
  changeprice: 'Cambio de precio|Cambio de precios',
  change_to: 'Cambia a',
  city: 'Ciudad',
  chat: 'Chat',
  check: 'Cheque',
  click_repository: 'Clic en el recuadro para enviar al repositorio',
  code: 'Código',
  color: 'Color|Colores',
  order_code: 'Código de orden',
  order_name: 'Nombre de orden',
  order_status: 'Estatus de orden',
  color_nav: 'Color de barra de navegación',
  color_toolbar: 'Color de barra lateral',
  cols_tab_be_unique: 'La tabulación de las columnas debe ser única',
  column: 'Columna',
  column_compare:
    'La columna de cantidades de la matriz de detalle debe ser igual que el de la matriz del producto',
  row_compare:
    'La cantidad de columnas de la matriz de detalle debe ser igual que el de la matriz del producto',
  correction: 'Corrección',
  commission: 'Comisión|Comisiones',
  commission_assigned: 'Comisión Asignada',
  commissionPermission:
    'La condición es el número de ventas a realizar, el valor siempre será mayor o igual que {val}',
  companies: 'Empresas',
  company: 'Empresa',
  company_settings: 'Configuración de empresa',
  company_info: 'Información de la empresa',
  company_name: 'Nombre de la empresa',
  connected: 'Conectado | Conectados',
  confirm: 'Confirmar',
  confirm_delete:
    '¿Deseas eliminar este registro? Esta acción no se puede deshacer',
  contact: 'Contacto | Contactos',
  copyright: 'Todos los derechos reservados.',
  country: 'País',
  cost: 'Costo | Costos',
  costalert: 'El costo de su producto debe ser mayor que 0',
  cost_by_dimensions: 'Costo calculado por las dimensiones',
  cost_prom: 'Costo de Promoción',
  cost_lat: 'Costo (Latinoamérica)',
  cost_lat_prom: 'Costo Promoción (Latinoamérica)',
  cost_suggest: 'Costo total',
  close: 'Cerrar',
  clone: 'Clonar',
  cloned: 'clonado',
  create: 'Crear {model}',
  created: 'Creado|Creada',
  create_email: 'Crear email',
  create_matrix: 'Crear matriz',
  create_matrix_template: 'Crear plantilla de matriz',
  create_value: 'Crear Valor',
  credit_limit: 'Límite de Crédito',
  condition: 'Condición',
  currency: 'Moneda|Monedas',
  currency_info: 'Información de Monedas',
  customer_manager: 'Administrador de clientes',
  customer: 'Cliente|Clientes',
  customerAddress: 'Dirección cliente',
  supplierAddress: 'Dirección proveedor',
  customerCompany: 'Nombre del cliente o empresa',
  supplierCompany: 'Nombre del proveedor o empresa',
  supplier_manager: 'Administrador de proveedores',
  supplier_type: 'Tipo de proveedor',
  dashboard: 'Tablero',
  decimal: 'Decimal',
  default: 'Por defecto',
  default_field: '¡Por favor seleccione una fórmula de precio por defecto!',
  deploy: 'Desplegar',
  dark: 'Color oscuro',
  dark_mode: 'Modo Oscuro',
  date: 'Fecha',
  dates: 'Fechas',
  dateOrder: 'Fecha de la orden',
  delivery: 'Entrega',
  delivery_date: 'Fecha de entrega',
  delivery_status: 'En entrega | Rechazado | Entregado',
  dateDelivery: 'Fecha de entrega',
  dateDesign: 'Fecha de diseño',
  dateDone: 'Fecha de cierre del pedido',
  dateProduction: 'Fecha de producción',
  date_from: 'Desde',
  date_pay: 'Fecha de Pago',
  day_terms: 'Días para pagar crédito',
  date_to: 'Hasta',
  days: 'Días',
  days_list: {
    mon: 'Lunes',
    tue: 'Martes',
    wed: 'Miércoles',
    thu: 'Jueves',
    fri: 'Viernes',
    sat: 'Sábado',
    sun: 'Domingo'
  },
  daily: 'Diario',
  drag_and_drop_the_image_to_this_box:
    'Arrastre y suelte la imagen a este recuadro',
  delete: 'Borrar|Borrado',
  deleted: 'Se eliminó',
  delete_matrix: 'Borrar Matriz',
  delete_no:
    'Este estado no se puede borrar porque contiene unas subórdenes. Primero, cambie las subórdenes a un estado vigente',
  delete_record_question: '¿Desea eliminar este registro?',
  deliverytime: 'Tiempo de entrega',
  department: 'Departamento de producción',
  deselect_image: 'Deseleccionar imagen',
  description: 'Descripción',
  descriptions: 'Descripciones',
  description_lat: 'Descripción (Latinoamérica)',
  design: 'Diseño | Diseños',
  design_status: 'Estado de diseño',
  designer: 'Diseñador',
  designers: 'Diseñadores',
  detail: 'Detalle',
  details_production: 'Detalles de Producción',
  dinamic_cost: 'Costo Dinámico',
  dimension: 'Dimensiones',
  discard: 'Descartar',
  discount: 'Descuento',
  done: 'Listo',
  dontAvalaible: 'No disponible',
  download: 'Descargar',
  draft: 'Borrador',
  driver: 'Chofer | Choferes',
  edit: 'Editar',
  editing: 'Editar {value}',
  editable: 'Editable',
  edited: 'Editado | Editada',
  editable_active:
    'Para activar este botón necesita credencial de administrador',
  email: 'Correo electrónico | Correos electrónicos',
  email_edit:
    'Si desea confirmar o editar la información del correo, presione el botón de editar',
  emails_list: 'Lista de correos',
  email_sent: 'Correo electrónico enviado',
  email_setting: {
    default_iprint_email_service: 'Servicio de correo de Iprint',
    default_setting: 'Configurar por defecto',
    email_SMTP_setting: 'Configuración SMTP de correo',
    gmail: {
      google: 'Google',
      gmail: 'Gmail',
      setting_gmail: 'Configuración de Gmail',
      msg1: 'Prerrequisito: estar logueado en el navegador con su cuenta de Google.',
      msg2: 'Ingresar al siguiente link:',
      msg3: 'Para generar la contraseña, registre la aplicación: ',
      msg4: 'Por favor ingrese las credenciales de su cuenta (Correo y contraseña).'
    },
    host: 'Servidor',
    host_port: 'Puerto del servidor',
    hour: 'Hora',
    smtp_type: 'Tipo de SMTP',
    outlook: {
      outlook: 'Outlook',
      setting_outlook: 'Configuración de Outlook',
      msg1: 'Prerrequisito: estar logueado en  el navegador con su cuenta de Outlook.',
      msg2: 'Ingresar al siguiente link:',
      msg3: 'Permitir que los dispositivos y aplicaciones usen la configuración POP: Si',
      msg4: 'Por favor ingrese las credenciales de su cuenta (Correo y contraseña).'
    },
    use_tls: 'Use TLS',
    other: {
      setting_other: 'Configurar otro',
      msg1: 'Prerrequisito: Contar con los accesos para la configuración SMTP del servicio de correo de su preferencia.',
      msg2: 'Llenar todos los datos que se solicitan en el formulario de esta sección.'
    }
  },
  empty_data: 'No se encontraron registros',
  empty_message: 'Debe especificar el contenido del mensaje',
  end_date: 'Fecha fin',
  equipment: 'Equipo',
  equipments: 'Equipos',
  equipments_manage: 'Gestión de equipos',
  equipment_maintenance: 'Mantenimiento de equipo',
  error_send_email: 'Error al enviar el correo',
  example: 'Ejemplo',
  expense: 'Gasto|Gastos',
  expenses_regular: 'Gastos regulares',
  expenses_recurring: 'Gastos recurrentes',
  expense_validation:
    'La fecha de inicio no puede ser mayor o igual que la fecha final | No hay meses en ese rango de fechas | No hay semanas en ese rango de fechas',
  expenseCategory: 'Categoria de gasto',
  expenseAccount: 'Cuentas de gasto',
  expiration_days: 'Días de expiración',
  expired: 'Sesión expirada',
  extra: 'Extra',
  extra_cost: 'Costos extras',
  extra_discount: 'Extras/Descuentos',
  f2key: 'Presione la tecla f2 para mostrar el precio de costo',
  favorite: 'Favorito',
  fax: 'Fax',
  fields_changed: 'Los siguientes campos fueron modificados: ',
  file: 'Archivo',
  file_recomended:
    'Tamaño: 300px Alto x 300px Ancho, Resolucion: 120Dpi, Tipos de archivos: PNG-24 Alta Resolucion',
  file_type: 'Tipo de archivo',
  filter: 'Filtros',
  finalize: 'Finalizar',
  finished: 'Finalizada',
  finishedAndRemove:
    'Al finalizar la orden, sus imágenes serán borradas del sistema',
  first_name: 'Nombres',
  forgot: '¿Olvido su contraseña?',
  form: 'Formulario',
  format: 'Formato|Formatos',
  format_date: 'Formato de fecha',
  formula: 'Fórmula de precios',
  formula_simple: 'Fórmula',
  formulattributes: 'Atributos de fórmula',
  formulaDivisionZero: 'División entre 0 inválida',
  formulaNoValid: 'Fórmula inválida',
  fraction: 'Fracción',
  from: 'Desde',
  globaltax: 'Impuesto|Impuestos',
  general: 'General|Lista General de Productos',
  go_home: 'Ir al inicio',
  hasCondition: 'Con condición',
  has_credit: 'Crédito',
  main_email: 'Correo principal',
  main_matrix: 'Matriz Principal',
  height: 'Altura',
  max_height: 'Altura máxima',
  given_to: 'Entregado a',
  hide: 'Ocultar',
  hidden: 'Oculto',
  historical: 'Histórico',
  history: 'Histórico de {model}|Histórico del {model}',
  history_customer: 'Histórico del cliente',
  history_supplier: 'Histórico del proveedor',
  history_product: 'Histórico de productos',
  hours: 'Horas',
  image_design_and_approval: 'Diseño y aprobación de imagen',
  inactive: 'Inactivo',
  increase: 'Incrementar',
  identification: 'Identificación',
  in_process: 'En proceso',
  in_wait: 'En espera',
  in_design: 'En diseño',
  in_production: 'En producción',
  invalid_type_and_file_please_select_one_allowed:
    'Tipo e archivo invalido, por favor seleccione uno permitido.',
  invoice: 'Factura',
  invoices: 'Facturas',
  invoiceSetting: 'Configuración de factura',
  is_multipliable: 'Multiplicable por la cantidad',
  ip_addr: 'Direccion IP',
  matrix_prices: 'Matriz de precios',
  no_matrix: 'Debe agregar una matriz a la plantilla',
  item: 'Ítem',
  keep_off: 'Alejar',
  label: 'Etiqueta | Etiquetas',
  last_name: 'Apellidos',
  leave: 'Olvidar|olvidado',
  level: 'Tipo de cliente | Tipos de cliente',
  length: 'Longitud',
  registerLevel: 'Sólo se pueden registrar cuatro tipos de cliente',
  lineal_meter: 'Metro lineal',
  list: 'Lista',
  supplierlevel: 'Tipo de proveedor | Tipo de proveedores',
  levelRequired: 'El tipo de cliente es requerido para los precios',
  list_schemas: 'Listado de los schemas almacenados',
  login: 'Iniciar sesión',
  loginlogs: 'Registros de ingreso',
  login_failed: 'Fallo al iniciar sesión',
  logout: 'Cerrar sesión',
  matrix: 'Matriz',
  matrix_table: {
    add_cols: 'Agregar columna',
    add_rows: 'Agregar fila',
    remove_cols: 'Eliminar columna',
    remove_rows: 'Eliminar fila',
    initial_range: 'Rango inicial',
    final_range: 'Rango final'
  },
  main_status: 'Estado principal',
  member: 'Miembro | Miembros',
  message: 'Mensaje',
  image: 'Imagen',
  measure: 'Unidad de medida | Und. medida',
  measureRequired:
    'La fórmula de venta es requerida, los productos sin este valor estarán inactivos hasta que se les asigne.',
  minutes: 'Minutos',
  mode: 'Modo',
  model: 'Modelo',
  models: 'Modelos',
  model_product: 'Producto Modelo',
  month: 'Mes',
  month_list: {
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre'
  },
  monthly: 'Mensual',
  more_info: 'Más Información',
  multi_choice: 'Selección múltiple',
  msg_color: 'Color del mensaje',
  msg: 'Mensaje',
  must_be_numeric: 'deben ser númericos',
  my_profile: 'Mi perfil',
  name: 'Nombre',
  product_name: 'Nombre del Producto',
  new_chat: 'Nuevo Chat',
  new_package: 'Nuevo Paquete',
  new_password: 'Nueva contraseña',
  new: 'Nuevo',
  newMessage: 'Tienes un nuevo mensaje, verifica la orden: ',
  next: 'Siguiente',
  next_date: 'Siguiente fecha',
  next_number: 'Siguiente número',
  next_order_status: 'Nuevo estado de orden',
  next_sub_state: 'Nuevo subestado',
  nextStatus: 'Siguiente estado {state}',
  no_changes: 'No hay cambios',
  no_addi_data: 'No hay datos adicionales',
  no_notifications: 'No hay nuevas notificaciones',
  no_producers: 'No hay productores registrados',
  notifications: 'Notificaciones',
  notifications_events: 'Notificaciones y eventos',
  number: 'Número',
  number_control: 'Número de control',
  number_increment: 'Número de incremento',
  object_repr: 'Representación de objetos',
  observation: 'Observación',
  of: 'de',
  old_password: 'Contraseña anterior',
  one_row: 'Una fila',
  on_bytes: 'En Bytes',
  on_pixels: 'En píxeles',
  operator: 'Operador',
  operators: 'Operadores',
  optional: '{value} (opcional)',
  order: 'Orden | Órdenes',
  ordercreated: 'Orden {order} creada',
  ordercanceled: 'Orden {order} cancelada',
  orderdelayed: 'Orden retrasada',
  orderend: 'Orden {order} finalizada',
  salesorderlist: 'Listado de órdenes de venta',
  purchasesorderlist: 'Listado de órdenes de compra',
  push: 'Push',
  orderquote: 'Orden {order} en proceso de Cotización',
  orderstatus: 'Estado de las órdenes',
  orderupdate: 'Cambio de estado del producto {product}, de {old} a {new}',
  other: 'Otro',
  other_settings: 'Otros ajustes',
  outsource: 'Tercerización',
  overview: 'Visión General',
  order_name: 'Nombre del proyecto',
  purchase_order_name: 'Nombre de orden de compra',
  package: 'Paquete',
  padding: 'Relleno',
  page: 'Página',
  pagenotfound: 'Error 404: Página no encontrada',
  partial: 'Entrega parcial',
  participant: 'Participantes',
  password: 'Contraseña | Confirmar contraseña',
  password_recovery: 'Recuperación de contraseña',
  pay: 'Pago',
  paid: 'Pagado|Pagada',
  payment: 'Pago | Pagos',
  payment_number: 'Número de Pago',
  payment_ref: 'Pago referencial',
  payment_type: 'Tipo de Pago',
  paymenttype: 'Método de pago',
  percent: 'Porcentaje',
  perpage: 'Por página',
  permissions: 'Permisos',
  permissions_select: 'Seleccione los permisos',
  permissionnotfound:
    'Error 403: No tiene permisos suficientes para entrar aquí',
  personal_info: 'Información Personal',
  supplier_personal_info: 'Información Personal del Proveedor',
  phone: 'Teléfono | Teléfonos',
  phone_number: 'Número de Teléfono',
  photo: 'Foto',
  prefix: 'Prefijo',
  prev: 'Previo',
  previus_date: 'Fecha anterior',
  price: 'Precio | Precios | Tipo de precio',
  pricespecial: 'Precio especial',
  pricenegative: 'El precio no puede ser negativo',
  pricepurchase: 'Precio de compra',
  pricepurchaseplus: 'Precio de compra + extra',
  pricesale: 'Precios de venta',
  pricesuggest: 'Precio sugerido',
  priceZero:
    'Los precios que sean 0 se obviarán y se asumirá el monto del precio sugerido',
  primary_color: 'Color primario',
  print: 'Imprimir {value}',
  printquality: 'Calidad de impresión',
  prodcategory: 'Categoría de producto',
  prodlist: 'Listado de Productos',
  prodstatus: 'Estado de los productos',
  product: 'Producto | Productos',
  product_frequency: 'Cantidad vendida',
  product_manage: 'Gestión de productos',
  product_code: 'Código de Producto',
  product_sold: 'Productos vendidos',
  preprocess: 'Pre-Proceso',
  preproduction: 'Pre-Producción',
  production: 'Producción',
  production_ready: 'Producción lista',
  production_status: 'Estado de producción',
  postproduction: 'Post-Producción',
  productions: 'Productor | Productores',
  production_process: 'Proceso de producción',
  production_process_start: 'Iniciar proceso de producción',
  productive_process: 'Proceso productivo',
  profile: 'Perfil | perfil',
  profit: 'Ganancia',
  profit_percent: 'Porcentaje de ganancia',
  promise_date: 'Fecha de compromiso',
  expected_date: 'Fecha esperada',
  purchase: 'Compra | Compras',
  quote: 'Cotización',
  sale_order: 'Orden de venta',
  purchase_order: 'Orden de compra',
  range_start: 'Rango inicial',
  range_end: 'Rango final',
  ranges: 'Rangos',
  copy: 'Copiar',
  copy_ranges: 'Copiar rangos | Rangos copiados | Rangos editados',
  rejected: 'Rechazado',
  recent_chats: 'Chats Recientes',
  receivable: 'Cuentas por cobrar',
  received: 'Recibidas',
  recurrent: 'Recurrencia | Recurrente',
  register: 'Registrar',
  register_subdomain: 'Registrar Subdominio',
  register_user: 'Registrar Usuario',
  registered: '¿No tienes cuenta?',
  registered2: 'Registrado',
  reference: 'Referencia',
  reports: 'Reportes',
  report_type: 'Tipo de reporte',
  repository: 'Repositorio',
  customersReport: 'Reporte de clientes',
  expensesReport: 'Reporte de gastos',
  suppliersReport: 'Reporte de proveedores',
  salesReport: 'Reporte de ventas',
  purchasesReport: 'Reporte de compras',
  invoicesReport: 'Reporte de facturas',
  equipmentReport: 'Reporte de equipo',
  movementsReport: 'Reporte de movimientos',
  productReport: 'Reporte de productos',
  removed: 'Eliminado|Eliminada',
  rent: 'Alquiler',
  rent_start_date: 'Fecha inicial de alquiler',
  rent_end_date: 'Fecha final de alquiler',
  required: 'Error guardando, hay campos requeridos vacíos aún',
  restart: 'Restaurar | Restaurado',
  roles: 'Rol | Roles',
  roles_areas: 'Área',
  role_select: 'Seleccione el rol',
  rotate_left: 'Rotar a la izquierda',
  rotate_right: 'Rotar a la derecha',
  rounded: 'Redondear | Habilitar redondeo',
  rounded2: 'Redondeado',
  row_title: 'Título de la fila',
  row_per_page: 'Filas por página',
  cols_must_be_unique: 'El título de las columnas debe ser único',
  rows_must_be_unique: 'El título de las filas debe ser único',
  tabs_must_be_unique: 'El título de las pestañas debe ser único',
  rush: 'Rush',
  salary: 'Salario | Salarios',
  sale: 'Venta | Ventas',
  salesman: 'Vendedor | Vendedores',
  purchasesReceiver: 'Receptor de compras',
  sales_percent: 'Porcentaje de ventas',
  sales_prices: 'Precios de venta',
  same_billing: 'Igual que para cobros',
  same_payments: 'Igual que para pagos',
  same_company_address: 'Igual que la Dirección de la empresa',
  fill_company_address:
    'Complete los campos de dirección de la empresa primero',
  save: 'Guardar | Guardadas',
  save_send_backup: 'Se descargó el esquema con éxito',
  save_backup: 'Se guardó el respaldo de esquema con éxito',
  save_template:
    'Debe guardar el nombre de la plantilla antes de crear un detalle de producción',
  schema: 'Esquema',
  search: 'Buscar',
  section: 'Sección de {value}',
  section_only: 'Sección',
  section_sub: 'Subsección',
  second_value: 'El Segundo Valor',
  secondary_color: 'Color secundario',
  send: 'Enviar | Enviado | Enviadas',
  sended_quote: 'Cotización enviada',
  sended_document: 'Orden enviada',
  send_purchase_order: 'Enviar orden de compra',
  aproved_purchase_order: 'Orden de compra aprobada',
  send_notification:
    'Quieres enviar una notificación al cliente | Puedes notificar al cliente que su pedido ha sido modificado, para que el cliente pueda tener una idea de cómo va su pedido. Sí',
  send_quote: 'Enviar Cotización',
  sendonly: 'Enviar',
  send_email_list: 'Lista de correos enviados',
  send_email_schema: 'Se envió un email con éxito indicando la ruta',
  see: 'Ver',
  see_matrix: 'Ver Matriz',
  see_more: 'Ver más...',
  select: 'Seleccione',
  selectClone: 'Seleccione categoría(s)',
  selectClone2: 'Seleccione categoría(s) a las que desea clonar',
  select_schema: 'Selecciona el esquema ',
  selectDetail: 'Seleccionar un detalle de producción',
  selection: 'Selección de {value}',
  select_prod: 'No hay productos seleccionados',
  sequence: 'Secuencia | Secuencias',
  settings: 'Configuraciones',
  settingTemplate: {
    dark_icons: 'Enlaces modo oscuro',
    setting: 'Configuración',
    settings_template: 'Configuraciones de plantilla de correo electrónico',
    image: 'Imagen | Imágenes',
    icons: 'Enlaces de redes sociales',
    text: 'Texto',
    test_text: 'Texto de prueba',
    align: {
      align: 'Alinear',
      center: 'Centrar',
      left: 'Izquierda',
      right: 'Derecha',
      justify: 'Justificar'
    },
    size: {
      size: 'Tamaño',
      small: 'Pequeño',
      medium: 'Medio',
      large: 'Grande'
    },
    text_color: 'Color del texto',
    font_size: 'Tamaño del texto',
    font_style: {
      both: 'Ambas',
      font_style: 'Estilo de fuente',
      italic: 'Cursiva',
      bold: 'Negrita',
      normal: 'Normal'
    },
    header: 'Encabezado',
    background_color: 'Color de fondo',
    body: 'Cuerpo',
    footer: 'Pie de página'
  },
  serial: 'Serial',
  service: 'Servicio',
  services: 'Servicios',
  settings_for_image_upload: 'Configuración para subida de imagen',
  settingImage: 'Configuración de imagen',
  max_size: 'Tamaño máximo',
  sku_code: 'Código de Barras',
  sms: 'Sms',
  soldby: 'Fórmula de venta',
  shippingAddress: 'Dirección de envío',
  dispatchAddress: 'Dirección de despacho',
  shop_list: 'Lista de Compra',
  side: 'Lado',
  specific: 'Información específica',
  subdomain: 'Subdominio | Subdominios',
  subname: 'Nombre del subproyecto',
  subitem: 'Subdetalle | Subdetalles',
  subitems: 'Subdetalles {value}',
  subitems_not: 'Crear sin sub-detalles',
  suborderstatus: 'Estado de la Suborden',
  subordersubstatus: 'Subestado de la Suborden',
  suborder: 'Suborden | Subórdenes',
  suborder_action: {
    created: 'creada',
    updated: 'actualizada',
    deleted: 'eliminada',
    access: 'accedida'
  },
  subtotal: 'Subtotal',
  subject: 'Sujeto',
  subcategory: 'Sub-Categoría',
  success: '{model} {action} correctamente',
  suffix: 'Sufijo',
  supplier: 'Proveedor|Proveedores',
  supplier_type: 'Tipo de proveedor',
  sustrate: 'Sustratos',
  shipping_address: 'Dirección de Envío',
  state: 'Estado',
  status: 'Estado',
  start_user_chat: 'Comenzar chat con el usuario',
  start_date: 'Fecha de inicio',
  show: 'Mostrar',
  tax: 'Impuesto',
  tax_id: 'Número de Pago de Impuesto|Número de Pago de Impuesto (Opcional)',
  taxes_info: 'Información sobre impuestos',
  telegram: 'Telegram',
  templates_email: 'Plantillas de correo',
  test_formula: 'Probar esta fórmula',
  dont_templates_email:
    'Este estado no posee plantilla de correo, por favor editar correo manualmente',
  template: 'Plantilla',
  terms: 'Condición de pago|Condiciones de pago',
  text: 'Texto',
  time: 'Tiempo',
  title: 'Título',
  title_color: 'Color del título',
  title_discount: 'Descuento',
  title_extra: 'Extra',
  the_file_you_supply_exceeds_the_weight_limit_please_enter_a_lighter_one:
    'El archivo que suministro excede el peso límite, por favor ingrese uno más liviano.',
  there_are_no_images_yet: 'No existen imágenes aún',
  total: 'Total',
  to: 'Hasta',
  turnaround: 'Días de entrega',
  unitprice: 'Precio unitario',
  update: 'Actualizar | Actualizado | actualizada | Actualizadas',
  updated: 'Actualizado',
  upload_logo: 'Subir logotipo',
  tip: 'Consejo|Consejos',
  type: 'Tipo',
  typedate: 'Tipo de fecha',
  typecontact: 'Tipo de contacto|Tipos de contacto',
  typedatealert:
    'Si no selecciona una categoría, este tipo de fecha será general',
  write_a_message: 'Escribe un mensaje',
  type_recurrent: 'Tipo de recurrencia',
  upload_image: 'Subir imagen',
  user: 'Usuario | Usuarios | usuario | usuarios',
  user_panel: 'Panel de Usuarios',
  users_select: 'Seleccione los usuarios',
  users_set_groups: 'Usuarios asignados a grupos',
  users_set_permissions: 'Se asignaron permisos a los Usuarios',
  username: 'Nombre de usuario | Nombre de usuario / Correo',
  value: 'Valor | Valores',
  value_must_be_greater: '{value} debe ser mayor',
  what_groups: '¿Cuáles son los grupos para agregar?',
  what_permissions: '¿Cuáles son los permisos para agregar?',
  what_users: '¿Cuáles son los usuarios para agregar?',
  width: 'Ancho',
  max_width: 'Ancho máximo',
  web_url: 'Dirección Web',
  welcome: 'Bienvenido',
  welcome_company: 'Bienvenido a {value}',
  week: 'Semana',
  weekly: 'Semanal',
  were_sorry_but_something_went_wrong_in_the_process_please_try_again_or_contact_us:
    'Lo lamentamos, pero algo salio mal en el proceso. Por favor, inténtelo nuevamente o contactenos.',
  with_autoprice:
    'Los precios por porcentaje al guardar serán ajustados los montos correspondientes acordes al costo',
  whatsapp: 'Whatsapp',
  yearly: 'Anual',
  yes: 'Si',
  zip_code: 'Código Postal',
  zoom_in: 'Acercar',
  discrepancynote: 'Nota de discrepancia',
  discrepancy: 'Discrepancia | Discrepancias',
  distance: 'Distancia',
  incorporation: 'Incorporación',
  deincorporation: 'Desincorporación',
  inventory: 'Inventario',
  stock: 'Inventario disponible',
  minReorder: 'Reorden Mínimo | Reporte de Inventario Mínimo',
  minStock: 'Existencia Mín.',
  increaseStock: 'Incrementar existencia',
  created_date: 'Fecha de creación',
  creation_user: 'Usuario creador',
  updated_date: 'Fecha de carga',
  updated_user: 'Usuario que lo cargó',
  purchaseproduct: 'Producto de compra',
  movement_type: 'Tipo de movimiento',
  quantity: 'Cantidad',
  quantity_0: 'La cantidad debe ser mayor a cero',
  quick_sale: 'Venta rápida',
  movement: 'Movimiento | Movimientos',
  alert: 'Alerta',
  purchased_product: 'Producto comprado',
  pdocument: 'Documento de compra',
  reception: 'Recepción',
  reception_date: 'Fecha de recepción',
  partialReception: 'Recepción parcial',
  order_quantity: 'Cantidad ordenada',
  pending_quantity: 'Cantidad faltante',
  received_quantity: 'Cantidad recibida',
  received_purchase_order: 'Recepción de productos',
  received_date: 'Fecha de recepción',
  received_greater: 'La cantidad recibida no puede ser mayor que la pendiente',
  special_customer: 'Precios especiales para clientes',
  accumulated_quantity: 'Cantidad acum.',
  drag_drop_image: 'Arrastra y suelta una imagen',
  wrong_file_type: 'Tipo de archivo incorrecto',
  pick_a_image: 'Elige una imagen',
  clean_image: 'Limpiar la imagen',
  loading_image: 'Cargando...',
  banners: 'Pancartas',
  send_artwork: 'Enviar arte',
  notes_chat: 'Notas/Chat',
  dashboard_default: 'Elegir opción por defecto',
  add_item_value: 'Agregar Valores',
  less_price: 'El precio es menor al costo',
  edit_btn: 'Editar',
  create_btn: 'Crear',
  add_product: 'Agregar producto',
  add_product_partial:
    'El producto se guardará como inactivo hasta que la data requerida sea llenada totalmente',
  edit_product_partial:
    'El producto permanecerá inactivo hasta que la data requerida sea llenada totalmente',
  edit_product: 'Editar producto',
  cant_active:
    'Debe rellenar la información de todos los pasos del formulario antes para poder modificar esta opción',
  matrix_template: 'Matriz plantilla',
  price_calculation: 'Cálculo de precios',
  inventoriable: 'Inventariable',
  concurrence: 'Concurrencia del cliente',
  permanent: 'Permanente',
  sporadic: 'Esporádico',
  required_field: 'Campo requerido',
  optional_field: 'Opcional',
  rating: 'Calificación',
  review: 'Reseña',
  fill_fields: 'Complete todos los campos requeridos primero',
  with_subitems: 'Con subdetalles',
  without_price: 'Sin precio',
  without_subitems: 'Sin subdetalles'
}

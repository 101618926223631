<template>
  <i-modal
    :title="`${this.$tc('clone', 1)} ${this.$tc('price', 1)}`"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="6">
        <ValidationProvider
          vid="from"
          :name="$t('from')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            name="from"
            key="from"
            :items="tabs"
            v-model="from"
            item-text="value"
            item-value="value"
            color="secondary"
            :error-messages="errors[0]"
            :label="$t('from')"
            @change="setTo"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="6">
        <ValidationProvider
          vid="to"
          :name="$t('to')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            name="to"
            key="to"
            :items="short_tabs"
            v-model="to"
            item-text="value"
            item-value="value"
            color="secondary"
            :label="$t('to')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { VueEditor } from 'vue2-editor'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    tabs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dialog: false,
      load: false,
      edit: false,
      valid: false,
      short_tabs: [],
      from: '',
      to: ''
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          this.$emit('clone', {
            from: this.tabs.findIndex((v) => v.value == this.from),
            to: this.tabs.findIndex((v) => v.value == this.to)
          })
          this.short_tabs = []
          this.from = ''
          this.to = ''
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(`${this.$tc('price', 1)} ${this.$t('cloned')}`)
        } finally {
          this.loading = false
        }
      }
    },
    close() {
      this.$emit('close')
    },
    setTo() {
      this.short_tabs = this.tabs.filter((f) => f.value != this.from)
    }
  }
}
</script>

const path = 'movement/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const excel = ({ pk, params, opt = {} }) =>
  axios.get(`getMovementsExcel/${pk}/${params}`, {
    ...opt,
    responseType: 'blob'
  })

export default {
  list,
  create,
  edit,
  show,
  remove,
  excel
}

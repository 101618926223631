var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"image","title":""}}):_c('div',{staticClass:"image-print drop",class:_vm.getClasses,style:(_vm.styleDinamic),attrs:{"alt":"avatar","id":_vm.name !== undefined ? _vm.name : 'image'},on:{"dragover":function($event){$event.preventDefault();return _vm.dragOver.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.dragLeave.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.drop($event)}}},[(!_vm.repository)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preview),expression:"!preview"}],staticClass:"mb-10",style:({
      position: 'absolute',
      right: 0,
      border: this.readonly ? '' : '2px solid var(--secundary)'
    }),attrs:{"color":"primary","dark":"","fab":"","icon":"","small":"","disabled":_vm.readonly,"title":_vm.$t('pick_a_image')},nativeOn:{"click":function($event){return _vm.pickFile.apply(null, arguments)}}},[_c('v-icon',[_vm._v("fa-camera")])],1):_vm._e(),(!_vm.repository)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preview),expression:"!preview"}],staticClass:"mt-10",style:({
      position: 'absolute',
      right: 0,
      border: this.readonly ? '' : '2px solid var(--secundary)'
    }),attrs:{"color":"primary","dark":"","fab":"","icon":"","small":"","disabled":_vm.readonly,"title":_vm.$t('clean_image')},nativeOn:{"click":function($event){return _vm.resetImage.apply(null, arguments)}}},[_c('v-icon',[_vm._v("fa-trash")])],1):_vm._e(),_c('input',{ref:"image",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onFilePicked}}),(_vm.loadImage)?_c('h3',{class:_vm.$store.getters.darkMode ? 'dark loadImage' : 'light loadImage'},[_vm._v(" "+_vm._s(_vm.$t('loading_image'))+" ")]):_vm._e(),(_vm.wrongFile)?_c('h3',{class:_vm.$store.getters.darkMode ? 'dark' : 'light'},[_vm._v(" "+_vm._s(_vm.$t('wrong_file_type'))+" ")]):_vm._e(),(!_vm.file && !_vm.isDragging && !_vm.wrongFile && !_vm.readonly && !_vm.loadImage)?_c('h5',{staticClass:"user-select-none",class:_vm.$store.getters.darkMode ? 'dark' : 'light'},[_vm._v(" "+_vm._s(_vm.$t('drag_drop_image'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
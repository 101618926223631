import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { localize } from 'vee-validate'
import { localeValue } from '@/locale/locale.js'

Vue.use(VueI18n)
let lang =
  localStorage.getItem('locale') != undefined
    ? localStorage.getItem('locale')
    : navigator.language.split('-')[0]

localize(lang)
// definition for Locale instance
export const i18n = new VueI18n({
  locale: lang, // set locale
  messages: localeValue, // set locale messages
  silentTranslationWarn: true
})

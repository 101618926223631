<template>
  <v-dialog
    :value="value"
    :fullscreen="fullscreen"
    :persistent="persistent"
    :max-width="maxWidth"
    @input="$emit('input', $event)"
  >
    <ValidationObserver ref="validation" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <v-card>
          <slot
            name="title"
            :title="title"
            :close="close"
            :dontClose="dontClose"
          >
            <v-card-title
              class="primary text-dark text-center justify-space-between"
            >
              <i-btn
                v-if="!dontClose"
                :title="$t('close')"
                text
                icon="fa-times"
                color="text-dark"
                @click="close()"
              ></i-btn>
              <v-spacer v-if="dontClose || dontSave"></v-spacer>
              <span> {{ title }} </span>
              <v-spacer v-if="dontClose || dontSave"></v-spacer>
              <slot name="arrow"></slot>
              <slot v-if="!readonly && !dontSave" name="save">
                <i-btn
                  :title="$tc('save', 1)"
                  text
                  icon="fa-save"
                  :small="$vuetify.breakpoint.smAndDown"
                  :loading="load"
                  @click="submit"
                  color="text-dark"
                ></i-btn>
              </slot>
            </v-card-title>
          </slot>
          <v-card-text class="py-3">
            <slot>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    :vid="fieldName"
                    :name="$tc(fieldName, 1)"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autofocus
                      :name="fieldName"
                      :error-messages="errors[0]"
                      :value="fieldValue"
                      @input="$emit('update:fieldValue', $event)"
                      outlined
                      color="secondary"
                      :label="$tc(fieldName, 1)"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </slot>
          </v-card-text>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: [String],
      default: ''
    },
    value: {
      type: [Boolean],
      required: true
    },
    maxWidth: {
      type: [String, Number],
      default: '600px',
      required: false
    },
    fullscreen: {
      type: Boolean,
      default: false,
      required: false
    },
    dontClose: {
      type: Boolean,
      default: false,
      required: false
    },
    closed: {
      type: [Boolean],
      required: false
    },
    dontSave: {
      type: Boolean,
      default: false,
      required: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    load: {
      type: Boolean,
      default: false
    },
    fieldName: {
      type: String,
      default: 'name'
    },
    fieldValue: {
      type: [String, Boolean, Object, Array, Number],
      default: ''
    }
  },
  watch: {
    value(val) {
      if (val && this.$refs.validation !== undefined) {
        this.$refs.validation.reset()
      }
    }
  },
  methods: {
    submit() {
      this.$emit(`submit`)
      if (this.closed) this.$emit('input', false)
    },
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('typedate', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12" :sm="template_id === undefined ? 6 : 12">
        <ValidationProvider
          vid="extra-name"
          :name="$tc('name', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors[0]"
            v-model="typedate.name"
            outlined
            color="secondary"
            :label="$tc('name', 1)"
            key="extra-name-input"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" v-if="template_id === undefined">
        <dialtemplate
          v-model="dialog2"
          :to-edit="toEdit2"
          @done="getTemplateProduct"
          ref="dialtemplate"
        />
        <ValidationProvider
          vid="template"
          :name="$tc('template', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-autocomplete
            outlined
            v-model="typedate.template"
            autocomplete="off"
            clearable
            :error-messages="errors[0]"
            :items="templates"
            :loading="isLoading"
            class="secondary--text"
            item-text="name"
            item-value="pk"
            key="template-input"
            append-icon="fa-search"
            prepend-inner-icon="fa-plus"
            :label="$tc('template', 1)"
            @click:prepend-inner="open()"
          ></v-autocomplete>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" v-if="template_id === undefined">
        <v-alert
          outlined
          type="info"
          dismissible
          :class="{
            'font-weight-black': true,
            title: !$vuetify.breakpoint.xsOnly,
            'subtitle-1': $vuetify.breakpoint.xsOnly
          }"
        >
          {{ $t('typedatealert') }}
        </v-alert>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import dialtemplate from '../templatesProduct/modal.vue'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    template_id: Number
  },
  components: {
    dialtemplate
  },
  data() {
    return {
      load: false,
      dialog: false,
      templates: [],
      template: null,
      isLoading: false,
      loading: false,
      typedate: {
        template: null,
        name: ''
      },
      edit: false,
      dialog2: false,
      toEdit2: null
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.typedate =
            this.toEdit !== null
              ? { ...this.toEdit }
              : { template: null, name: '' }
        }
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit2 = item === undefined ? null : item
      this.dialog2 = true
    },
    addTemplate(evt) {
      this.templates.push(evt)
      this.typedate.template = evt.pk
    },
    async getTemplateProduct() {
      try {
        this.isLoading = true
        let response = await this.$api.product.template.list({
          opt: {
            params: {
              order: true,
              company: this.company.pk
            }
          }
        })
        this.templates = response.data
      } finally {
        this.isLoading = false
      }
    },
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const type = !this.edit
            ? await this.$api.dashboard.typedate.create({ form: this.typedate })
            : await this.$api.dashboard.typedate.edit({
                pk: this.typedate.pk,
                form: this.typedate
              })
          this.$emit('done', type.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('typedate', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  },
  mounted() {
    this.getTemplateProduct()
  }
}
</script>

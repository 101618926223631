export default {
  namespaced: true,
  state: {
    customerToSale: localStorage.getItem('customerToSale') || null
  },
  getters: {
    getCustomerToSale: (state) => state.customerToSale
  },
  mutations: {
    customerToSale(state, customer) {
      state.customerToSale = customer
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"my-0"},[_vm._l((_vm.suborders),function(item){return _c('v-col',{key:`suborder-${_vm.order}-${item.pk}`,staticClass:"pt-2",attrs:{"cols":"12"},on:{"click":function($event){$event.stopPropagation();item.quantity > 0 &&
      (_vm.getPermissions([`sales.view_orderproduct`]) || _vm.isAdmin)
        ? _vm.$refs.dialprod.open(item.pk)
        : ''}}},[(_vm.subOrder !== undefined ? item.pk === _vm.subOrder : true)?_c('v-card',{staticClass:"elevation-0 suborderStatus"},[_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":2,"sm":3,"align-self":"center"}},[_c('i-image',{staticClass:"product-image",style:({
              width: '5em !important',
              height: '4em !important'
            }),attrs:{"value":item.thumbnail,"preview":true,"readonly":true,"icon":"false"}}),(!_vm.calendar)?_c('span',{class:{
              caption: true,
              'font-weight-black': true,
              'secondary--text': true
            }},[_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(item.code !== '')?_c('br'):_vm._e()],1),(_vm.calendar)?_c('v-col',{attrs:{"cols":"1"}}):_vm._e(),(!_vm.calendar)?_c('v-col',{attrs:{"cols":"3"}},[_c('span',{class:{
              'body-1': true,
              'font-weight-black': true,
              'secondary--text': true
            }},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('br'),_c('span',{class:{
              caption: true,
              'font-weight-black': true,
              'secondary--text': true
            }},[_vm._v(" "+_vm._s(item.own_prod)+" ")]),_c('br'),_c('span',{class:{
              caption: true,
              'font-weight-black': true,
              'secondary--text': true
            }})]):_vm._e(),_c('v-col',{staticClass:"d-flex pr-2",attrs:{"cols":!_vm.calendar ? 5 : 8,"align":'end'}},[_c('div',{staticStyle:{"width":"70vw !important","overflow-x":"auto","overflow-y":"hidden","display":"flex"}},_vm._l((_vm.status[item.pk]),function(progress,key){return _c('div',{key:`progress-${item.pk}-${key}`,staticClass:"mx-1",staticStyle:{"width":"210px","display":"flex","align-items":"center","flex-direction":"column"}},[_c('div',{},[_c('v-progress-circular',{attrs:{"size":'42',"rotate":-90,"value":progress.value,"width":'3',"color":progress.color === '#FFFFFF' || progress.color === '#FFF'
                      ? 'black'
                      : progress.color}},[_vm._v(" "+_vm._s(`${progress.value}%`)+" ")])],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"caption",style:({
                    color:
                      progress.color === '#FFFFFF' ||
                      progress.color === '#FFF'
                        ? 'black'
                        : progress.color
                  })},[_vm._v(" "+_vm._s(_vm.$tc(progress.name, 1))+" ")])])])}),0)]),_c('v-col',{attrs:{"cols":"1"}},[_c('span',{class:{
              'body-1': true,
              'font-weight-black': true,
              'secondary--text': true
            }},[_vm._v(" "+_vm._s(_vm.$t(item.leader_status['delivery']))+" ")])])],1)],1):_vm._e()],1)}),_c('dialprod',{ref:"dialprod",on:{"prod":function($event){return _vm.getSuborder($event)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-menu bottom left :offset-x="true" :offset-y="true">
      <template v-slot:activator="{ on }">
        <v-btn dark text v-on="on">
          <v-avatar size="34px" style="border-radius: 0px">
            <!-- <img v-if="me.profile" alt="Avatar" :src="imageUserProfile" class="mr-4" /> -->
            <!-- <v-icon class="pr-2">fa fa-user</v-icon> -->
            <v-img
              class="mx-1"
              max-width="34"
              :lazy-src="require('@/assets/color-icons/toolbar/user.png')"
              :src="require('@/assets/color-icons/toolbar/user.png')"
            >
            </v-img>
          </v-avatar>
          <span>@{{ me.username }}</span>
        </v-btn>
      </template>
      <v-card>
        <v-list class="secondary" dark>
          <v-list-item>
            <v-list-item-avatar size="60">
              <img v-if="me.profile" alt="Avatar" :src="imageUrl" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ me.first_name + ' ' + me.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-weight-bold">
                {{ me.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <!-- Mi perfil -->
          <v-list-item v-if="userInfo.profile" @click="changeRoute(me)">
            <v-list-item-icon>
              <v-icon color="primary" v-text="'fa-user'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text font-weight-bold"
                v-text="$t('my_profile')"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Lista de correos -->
          <!--
          <v-list-item v-if="userInfo.email" to="/sendEmail">
            <v-list-item-icon>
              <v-icon color="primary" v-text="'fa-envelope-open-text'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text font-weight-bold"
                v-text="$t('emails_list')"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          -->

          <!-- Cerrar sesión -->
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon color="primary" v-text="'fa-sign-out-alt'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="primary--text font-weight-bold"
                v-text="$tc('logout', 1)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      load: false,
      loading: false,
      imageUrl: null
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    userInfo() {
      return {
        profile:
          (this.getPermissions([`user_profile.view_salary`]) &&
            this.getPermissions([`user_profile.view_commission`]) &&
            this.getPermissions([`auth.view_group`])) ||
          this.isAdmin,
        email: this.getPermissions([`emails.view_maillist`]) || this.isAdmin
      }
    }
  },
  methods: {
    updateImg(evt) {
      this.$store.commit('session/setMe', evt)
      this.getImage()
    },
    logout() {
      const refresh = localStorage.getItem('refresh_token')
      this.$api.auth.logout({ refresh_token: refresh })
      this.$store.dispatch('session/logout')
      this.$vuetify.theme.dark = false
      this.$router.push({ name: 'auth-login' })
    },
    async getImage() {
      this.imageUrl = await this.showImage(
        this.me.profile && this.me.profile.photo
      )
    },
    changeRoute(item) {
      this.$router.push({
        name: 'users-pk',
        params: { pk: this.codification({ code: item.id }) }
      })
    }
  },
  mounted() {
    this.getImage()
  }
}
</script>

<style scoped></style>

export default {
  namespaced: true,
  state: {
    isLoading: false,
    createSuborder: false,
    activeEmailNotification: {
      active: false
    }
  },
  getters: {
    getCreateSuborder: (state) => {
      return state.createSuborder
    },
    getIsloading: (state) => {
      return state.isLoading
    },
    getDataEmailNotification: (state) => {
      return state.activeEmailNotification
    }
  },
  mutations: {
    actionLoading(state) {
      state.isLoading = !state.isLoading
    },
    createdsuborder(state, val) {
      state.createSuborder = val
    },
    openDialogEmailNotification(state, val) {
      state.activeEmailNotification = { ...val }
    }
  },
  actions: {
    createdsuborder({ commit }, val) {
      commit('createdsuborder', val)
    },
    actionLoading({ commit }) {
      commit('actionLoading')
    },
    openDialogEmailNotification({ commit }, value) {
      commit('openDialogEmailNotification', value)
    }
  }
}

<template>
  <v-row class="american-system--row my-0">
    <!-- Campo de la fórmula -->
    <v-col class="py-0 pl-0 pr-2" :cols="12">
      <ValidationProvider
        vid="label"
        :name="'main-' + String(inx)"
        :rules="{
          required: true,
          double: measureConvert !== 'ft' && measureConvert !== 'in' ? 6 : 0,
          min_value: 0
        }"
        v-slot="{ errors }"
      >
        <v-text-field
          color="secondary"
          outlined
          type="tel"
          :disabled="readonly"
          :error-messages="errors[0]"
          :label="label"
          :suffix="
            measureConvert === 'ft'
              ? 'ft'
              : measureConvert === 'in'
              ? ''
              : measureConvert
          "
          @keyup="unformat"
          v-model="main"
        >
          <div slot="prepend">
            <v-icon
              color="info"
              small
              @click="show = !show"
              v-text="'fa-info-circle'"
            />
          </div>
        </v-text-field>
      </ValidationProvider>
    </v-col>
    <!-- Popover de información de la fórmula -->
    <v-col cols="12" class="american-system--col">
      <v-alert
        class="american-system--alert"
        border="top"
        colored-border
        type="info"
        dense
        elevation="2"
        v-model="show"
        dismissible
      >
        <!-- Decimal -->
        <span
          v-if="
            dimensions !== undefined &&
            dimensions[name] !== undefined &&
            dimensions[name]['value'] !== undefined &&
            dimensions[name]['value'] !== ''
          "
        >
          Decimal: {{ dimensions[name]['value'] }} {{ measureConvert }}
        </span>
        <span v-else> Decimal: {{ value }} {{ measureConvert }} </span>

        <span v-if="measureConvert === 'in'">
          {{ $t('fraction') }} {{ main }} {{ decimalText }} {{ measureConvert }}
        </span>
        <span v-if="measureConvert === 'ft'">
          {{ $t('fraction') }}: {{ main }} ft {{ second }} {{ decimalText }} in
        </span>
        <span
          v-if="
            rounded !== undefined &&
            rounded &&
            dimensions !== undefined &&
            dimensions[name] !== undefined &&
            dimensions[name]['value'] !== undefined &&
            dimensions[name]['value'] !== ''
          "
        >
          {{ $t('rounded2') }}:
          {{ dimensions[name].rounded }}
          {{ defaultMeasure !== undefined ? defaultMeasure : measureConvert }}
        </span>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true,
      type: [Number, String],
      default: 0
    },
    measure: {
      type: [String]
    },
    defaultMeasure: {
      type: [String]
    },
    inx: {
      type: [String, Number]
    },
    dimensions: Object,
    rounded: Boolean
  },
  data() {
    return {
      formatted: false,
      main: 0,
      second: 0,
      decimal: 0,
      numerator: 0,
      denominator: 1,
      show: this.readonly,
      decimalText: '0',
      decimals: [
        '0',
        '1/16',
        '1/8',
        '3/16',
        '1/4',
        '5/16',
        '3/8',
        '7/16',
        '1/2',
        '9/16',
        '5/8',
        '11/16',
        '3/4',
        '13/16',
        '7/8',
        '15/16'
      ],
      transform: {
        in: {
          ft: {
            value: 0.083333,
            method: '*'
          },
          m: {
            value: 39.37,
            method: '/'
          },
          mm: {
            value: 0.03937,
            method: '/'
          },
          cm: {
            value: 0.3937,
            method: '/'
          }
        },
        ft: {
          in: {
            value: 0.083333,
            method: '/'
          },
          m: {
            value: 3.2808,
            method: '/'
          },
          mm: {
            value: 0.0032808,
            method: '/'
          },
          cm: {
            value: 0.032808,
            method: '/'
          }
        },
        m: {
          in: {
            value: 39.37,
            method: '*'
          },
          ft: {
            value: 3.2808,
            method: '*'
          },
          mm: {
            value: 1000,
            method: '*'
          },
          cm: {
            value: 100,
            method: '*'
          }
        },
        mm: {
          in: {
            value: 0.03937,
            method: '*'
          },
          ft: {
            value: 0.0032808,
            method: '*'
          },
          m: {
            value: 1000,
            method: '/'
          },
          cm: {
            value: 10,
            method: '/'
          }
        },
        cm: {
          in: {
            value: 0.3937,
            method: '*'
          },
          ft: {
            value: 0.032808,
            method: '*'
          },
          m: {
            value: 100,
            method: '/'
          },
          mm: {
            value: 10,
            method: '*'
          }
        }
      }
    }
  },
  methods: {
    changeDecimal() {
      this.decimal = eval(this.decimalText)
      this.unformat()
    },
    getFraction(fraction) {
      var gcd = function (a, b) {
        if (b < 0.0000001) return a // Since there is a limited precision we need to limit the value.
        return gcd(b, Math.floor(a % b)) // Discard any fractions due to limitations in precision.
      }
      var len
      if (typeof fraction === 'number') {
        len = fraction.toString().length - 2
      } else {
        len = fraction.length - 2
        fraction = Number(fraction)
      }

      var denominator = Math.pow(10, len)
      var numerator = fraction * denominator

      var divisor = gcd(numerator, denominator) // Should be 5

      numerator /= divisor // Should be 687
      denominator /= divisor
      return `${Math.floor(numerator)}/${Math.floor(denominator)}`
    },
    format() {
      let separator = []
      switch (this.measureConvert) {
        case 'in':
          separator =
            this.dimensions !== undefined &&
            this.dimensions[this.name] !== undefined &&
            this.dimensions[this.name]['value'] !== undefined &&
            this.dimensions[this.name]['value'] !== ''
              ? (this.dimensions[this.name]['value'] + '').split('.')
              : (this.value + '').split('.')
          this.main = Number(separator[0])
          this.decimalText =
            this.dimensions !== undefined &&
            this.dimensions[this.name] !== undefined &&
            this.dimensions[this.name]['decimalText'] !== undefined &&
            this.dimensions[this.name]['decimalText'] !== ''
              ? this.dimensions[this.name]['decimalText']
              : '0'
          this.decimal = eval(this.decimalText)
          break
        case 'ft':
          separator =
            this.dimensions !== undefined &&
            this.dimensions[this.name] !== undefined &&
            this.dimensions[this.name]['value'] !== undefined &&
            this.dimensions[this.name]['value'] !== ''
              ? (this.dimensions[this.name]['value'] + '').split('.')
              : (this.value + '').split('.')
          this.main = Number(separator[0])
          this.decimalText =
            this.dimensions !== undefined &&
            this.dimensions[this.name] !== undefined &&
            this.dimensions[this.name]['decimalText'] !== undefined &&
            this.dimensions[this.name]['decimalText'] !== ''
              ? this.dimensions[this.name]['decimalText']
              : '0'
          this.decimal = eval(this.decimalText)
          separator =
            Number(`0.${separator[1] === undefined ? 0 : separator[1]}`) /
            0.083333
          separator = (separator + '').split('.')
          this.second = Number(separator[0])
          break
        default:
          this.main = this.value
          break
      }
      if (this.formatted) {
        this.formatted = false
        let dimension = {
          ...this.dimensions[this.name],
          measure: this.measureConvert,
          decimalText: this.decimalText,
          main: this.main,
          second: this.second,
          name: this.label
        }
        this.$emit('changedimensions', {
          ...this.dimensions,
          [this.name]: dimension
        })
      }
    },
    unformat() {
      let val = 0
      switch (this.measureConvert) {
        case 'in':
          val = Number(this.main) + Number(this.decimal)
          val = val.toFixed(6)
          break
        case 'ft':
          val = Number(this.main)
          val += (Number(this.second) + Number(this.decimal)) * 0.083333
          val = val.toFixed(6)
          break
        default:
          val = this.main
          break
      }
      let dimension = {
        value: val,
        measure: this.measureConvert,
        decimalText: this.decimalText,
        main: this.main,
        second: this.second,
        name: this.label
      }
      if (
        this.measure !== undefined &&
        this.defaultMeasure !== undefined &&
        this.measure.toLowerCase() !== this.defaultMeasure.toLowerCase() &&
        this.transform[this.measureConvert] !== undefined &&
        this.transform[this.measureConvert][
          this.defaultMeasure.toLowerCase()
        ] !== undefined
      ) {
        dimension['defaultMeasure'] = this.defaultMeasure.toLowerCase()
        val = this.conversor(
          this.measure.toLowerCase(),
          this.defaultMeasure.toLowerCase(),
          val
        )
        dimension['defaultValue'] = val
      }
      if (this.rounded !== undefined && this.rounded) {
        if (this.measureConvert === 'in' || this.measureConvert === 'ft') {
          val = Math.ceil(val)
        } else {
          val = Math.round(val)
        }
        dimension = { ...dimension, rounded: val }
      }
      this.$emit('input', val)
      this.$emit('changedimensions', {
        ...this.dimensions,
        [this.name]: dimension
      })
    },
    conversor(old, newVal, valre) {
      let dimension =
        this.dimensions[this.name] === undefined
          ? {}
          : this.dimensions[this.name]
      dimension['measure'] = newVal
      if (
        this.transform[old] !== undefined &&
        this.transform[old][newVal] !== undefined
      ) {
        let val =
          this.dimensions !== undefined &&
          this.dimensions[this.name] !== undefined &&
          this.dimensions[this.name]['value'] !== undefined &&
          this.dimensions[this.name]['value'] !== ''
            ? this.dimensions[this.name]['value']
            : this.value
        if (valre !== undefined) {
          val = valre
        }
        let calc = eval(
          `${val}${this.transform[old][newVal]['method']}${this.transform[old][newVal]['value']}`
        ).toFixed(6)
        if (valre === undefined) {
          dimension['value'] = calc
          if (
            this.measure !== undefined &&
            this.defaultMeasure !== undefined &&
            this.measure.toLowerCase() !== this.defaultMeasure.toLowerCase() &&
            this.transform[this.measureConvert] !== undefined &&
            this.transform[this.measureConvert][
              this.defaultMeasure.toLowerCase()
            ] !== undefined
          ) {
            dimension['defaultMeasure'] = this.defaultMeasure.toLowerCase()
            calc = this.conversor(
              this.measure.toLowerCase(),
              this.defaultMeasure.toLowerCase(),
              calc
            )
            dimension['defaultValue'] = calc
          }
          this.$emit('changedimensions', {
            ...this.dimensions,
            [this.name]: dimension
          })
          this.$emit('input', calc)
          this.formatted = true
        } else {
          return calc
        }
      } else if (valre !== undefined) {
        return valre
      } else {
        this.$emit('changedimensions', {
          ...this.dimensions,
          [this.name]: dimension
        })
      }
    }
  },
  computed: {
    measureConvert() {
      return this.measure !== null && this.measure !== undefined
        ? this.measure.toLowerCase()
        : ''
    }
  },
  watch: {
    formatted: {
      immediate: true,
      handler(val) {
        if (val) {
          this.format()
        }
      }
    },
    rounded: {
      immediate: true,
      handler(val) {
        if (
          (val !== undefined && val) ||
          (!val &&
            this.dimensions !== undefined &&
            this.dimensions[this.name] !== undefined &&
            this.dimensions[this.name]['rounded'] !== undefined)
        ) {
          this.unformat()
        }
      }
    },
    measure: {
      immediate: true,
      handler(val, oldval) {
        if (oldval !== '' && oldval !== undefined && oldval !== null) {
          this.conversor(oldval.toLowerCase(), val.toLowerCase())
        } else if (oldval !== val) {
          this.format()
        }
      }
    }
  }
}
</script>
<style lang="sass">
.american-system
  &--alert
    padding: 0 16px
    margin-bottom: 0
    color: var(--v-primary-base)
    font-weight: 900
    font-size: 14px
    .v-alert__wrapper
      padding-top: 13px
      padding-bottom: 10px
      .v-icon
        align-self: center
  &--row
    position: relative
  &--col
    padding-top: 0
    margin-top: 0
    position: absolute
    top: 100%
    z-index: 1000
</style>
